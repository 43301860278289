import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';

import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { PublishOutlined } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { Course } from 'http/courses/ts/course.interface';
import Button from '../../../shared/components/Button';
import { NotificationProps } from '../ts/notification.interface';
import Notification from '../api/ts/interface/Notification';
import NotificationSchema from '../validation/NotificationSchema';
import useNotification from '../hooks/useNotification';

interface NotificationFormProps {
  initialValues?: Notification;
  callback: (values: NotificationProps) => void;
}

function NotificationForm({ initialValues, callback }: NotificationFormProps) {
  const { getCourses } = useNotification();
  const [courses, setCourses] = useState<Course[]>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const selectFile = () => {
    fileInputRef?.current?.click();
  };

  const handleGetCourses = async () => {
    setCourses(await getCourses());
  };

  useEffect(() => {
    handleGetCourses();
  }, []);

  return (
    <NotificationFormContainer>
      <Formik
        initialValues={{
          title: initialValues?.title || '',
          startAt: initialValues?.startAt || new Date(),
          endAt: initialValues?.endAt,
          message: initialValues?.message || '',
          file: initialValues?.image,
          repeatType: initialValues?.repeatType || '',
          receiversType: initialValues?.receiversType || '',
          courseId: initialValues?.receiversType || ''
        }}
        onSubmit={(values) => callback(values)}
        validationSchema={NotificationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form>
            <Grid
              container
              spacing={4}
              style={{ paddingTop: '30px', paddingBottom: '30px' }}
            >
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="course-filter-label"
                    error={!!touched.repeatType && !!errors.repeatType}
                  >
                    Programação
                  </InputLabel>
                  <Select
                    labelId="course-filter-label"
                    id="course-filter"
                    name="repeatType"
                    label="Programação"
                    value={values.repeatType}
                    error={!!touched.repeatType && !!errors.repeatType}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>selecione</em>
                    </MenuItem>
                    <MenuItem value="NOW">Agora</MenuItem>
                    <MenuItem value="NOT_REPEAT">Programado</MenuItem>
                    <MenuItem value="EVERY_DAY">Diariamente</MenuItem>
                    <MenuItem value="PER_ONE_WEEK">Semanalmente</MenuItem>
                    <MenuItem value="MONTHLY">Mensalmente</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {values.repeatType !== 'NOW' && (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy HH:mm"
                        name="startAt"
                        value={values.startAt}
                        onChange={(date) => setFieldValue('startAt', date)}
                        helperText={touched.startAt && errors.startAt}
                        error={!!touched.startAt && !!errors.startAt}
                        id="date-picker"
                        label="Data de início"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Título"
                    placeholder="Ex: A meditação aplicada na segunda guerra mundial"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    helperText={touched.title && errors.title}
                    error={!!touched.title && !!errors.title}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    aria-readonly
                    onClick={selectFile}
                    label="Imagem"
                    variant="outlined"
                    value={
                      values.file
                        ? typeof values.file === 'string'
                          ? values.file
                          : 'Arquivo carregado'
                        : ''
                    }
                    helperText={touched.file && errors.file}
                    error={!!touched.file && !!errors.file}
                    InputProps={{
                      endAdornment: values.file ? (
                        <img
                          className="inputThumbIcon"
                          src={
                            typeof values.file === 'object'
                              ? URL.createObjectURL(values.file)
                              : values.file
                          }
                          alt="thumb"
                          height="25px"
                          width="40px"
                        />
                      ) : (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    accept=".png,.jpeg"
                    ref={fileInputRef}
                    onChange={(value) => {
                      if (value.target.files?.length) {
                        setFieldValue('file', value.target.files[0]);
                      }
                    }}
                    name="file"
                    hidden
                  />
                </FormControl>
              </Grid>

              {(values.repeatType === 'EVERY_DAY' ||
                values.repeatType === 'PER_ONE_WEEK') && (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        name="endAt"
                        value={values.endAt}
                        onChange={(date) => setFieldValue('endAt', date)}
                        helperText={touched.endAt && errors.endAt}
                        error={!!touched.endAt && !!errors.endAt}
                        id="date-picker"
                        label="Data final"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="recipient-filter-label"
                    error={!!touched.receiversType && !!errors.receiversType}
                  >
                    Quem irá receber?
                  </InputLabel>
                  <Select
                    labelId="recipient-filter-label"
                    id="recipient-filter"
                    name="receiversType"
                    label="Quem irá receber?"
                    value={values.receiversType}
                    error={!!touched.receiversType && !!errors.receiversType}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    <MenuItem value="EVERYONE">Publico geral</MenuItem>
                    <MenuItem value="FREE_USERS">
                      Usuários não pagantes
                    </MenuItem>
                    <MenuItem value="SUBSCRIBER_USERS">
                      Usuários com assinatura
                    </MenuItem>
                    <MenuItem value="HAVE_COURSE_PURCHASED_USERS">
                      Usuários que possuem cursos comprados
                    </MenuItem>
                    <MenuItem value="YEARLY_SUBSCRIBER_USERS">
                      Usuários que possuem uma assinatura anual
                    </MenuItem>
                    <MenuItem value="MONTHY_SUBSCRIBER_USERS">
                      Usuários que possuem uma assinatura mensal
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {values.receiversType === 'HAVE_COURSE_PURCHASED_USERS' && (
                <Grid item xs={12} md={4}>
                  <FormControl>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      options={courses || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) =>
                        setFieldValue('courseId', value?.id)
                      }
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Curso"
                          name="courseId"
                          variant="outlined"
                          placeholder="Pesquise pelo curso desejado"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Mensagem"
                    multiline
                    variant="outlined"
                    minRows={3}
                    value={values.message}
                    onChange={handleChange}
                    helperText={touched.message && errors.message}
                    error={!!touched.message && !!errors.message}
                    name="message"
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <Button size="lg" type="submit">
                  {isSubmitting ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    'Salvar'
                  )}
                </Button>
                <Button size="lg" variant="outline" type="reset">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </NotificationFormContainer>
  );
}

export default NotificationForm;

const NotificationFormContainer = styled.div``;
