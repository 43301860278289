import * as yup from 'yup';

const NotificationSchema = yup.object({
  title: yup.string().required('Insira um título para a notificação'),
  startAt: yup.date().required('Escolha uma data'),
  endAt: yup.date(),
  message: yup.string().required('Insira a mensagem de sua notificação'),
  file: yup.mixed().notRequired(),
  repeatType: yup.string().required('Escolha a programação de repetição'),
  receiversType: yup
    .string()
    .required('Escolha quem irá receber a notificação'),
  courseID: yup.string().notRequired()
});

export default NotificationSchema;
