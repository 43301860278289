import React, { useContext } from 'react';

import { CircularProgress, Snackbar } from '@material-ui/core';
import { VideoUploadContext } from '../../contexts/UploadContext';

function UploadIndicator() {
  const { setIsIndicatorOpen, isIndicatorOpen } =
    useContext(VideoUploadContext);

  return (
    <Snackbar
      open={isIndicatorOpen}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={() => setIsIndicatorOpen(false)}
      draggable
      message="Fazendo upload do arquivo"
      action={
        <>
          <CircularProgress />
        </>
      }
    />
  );
}

export default UploadIndicator;
