import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from 'shared/components/Button';
import Section from 'shared/components/Section';
import IconButton from 'shared/components/IconButton';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Box, Fade } from '@material-ui/core';
import { TuneOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import useCourse from '../../hooks/useCourse';
import { Course } from '../../ts/course.interface';

import CourseItem from '../../components/CourseItem';
import CourseFilterDrawer from '../../components/CourseFilterDrawer';
import { CoursesListContainer, CoursesListSection } from './styles';

function CoursesList() {
  const { getCourses } = useCourse();
  const [filteredCourses, setFilteredCourses] = useState<
    Course[] | undefined
  >();
  const [allCourses, setAllCourses] = useState<Course[] | undefined>();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleGetAllCourses = async () => {
    const data = await getCourses();
    setAllCourses(data);
    setFilteredCourses(data);
  };

  const handleFilterCourses = (
    category: string,
    name: string,
    date: MaterialUiPickersDate
  ) => {
    const filter = allCourses?.filter((course) => {
      return (
        course.name.includes(name) &&
        course.type === category &&
        date?.toLocaleDateString() ===
          new Date(course.created_at).toLocaleDateString()
      );
    });

    setFilteredCourses(filter);
  };

  const handleCleanFilter = () => {
    setFilteredCourses(allCourses);
  };

  useEffect(() => {
    handleGetAllCourses();
  }, []);

  return (
    <Fade in>
      <CoursesListContainer>
        <Section
          title="Cursos e Mentorias"
          options={[
            <Link to="/courses/new">
              <Button size="sm">Novo Curso</Button>
            </Link>,
            <IconButton onClick={() => setIsFilterOpen(true)}>
              <TuneOutlined />
            </IconButton>
          ]}
        >
          <CoursesListSection>
            {filteredCourses
              ? filteredCourses?.map((item) => {
                  return (
                    <CourseItem
                      id={item.id}
                      title={item.name}
                      description={item.description}
                    />
                  );
                })
              : Array.from(new Array(4)).map(() => {
                  return (
                    <Box width={250}>
                      <Skeleton variant="rect" width={300} height={180} />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  );
                })}
          </CoursesListSection>
          {filteredCourses?.length === 0 && (
            <div className="not-found-message">
              <h3>Nenhum curso encontrado</h3>
              <span>
                Tente procurar utilizando outros filtros ou crie um curso agora
                mesmo
              </span>
            </div>
          )}
        </Section>

        <CourseFilterDrawer
          open={isFilterOpen}
          toggleOpen={() => setIsFilterOpen((state) => !state)}
          filterCourses={handleFilterCourses}
          cleanFilters={handleCleanFilter}
        />
      </CoursesListContainer>
    </Fade>
  );
}

export default CoursesList;
