import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import {
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import ibge from '../../../services/ibge';
import Button from '../../../shared/components/Button';
import Filters from '../ts/filter.interface';

type DrawerProps = {
  open: boolean;
  toggleOpen: () => void;
  callback: (values: Filters) => void;
  clean: () => void;
};

interface UF {
  id: number;
  title: string;
  value: string;
}

function FilterDrawer({ open, toggleOpen, callback, clean }: DrawerProps) {
  const [ufs, setUfs] = useState<UF[]>();
  const [cities, setCities] = useState<{ id: string; name: string }[]>();
  const [selectedUf, setSelectedUf] = useState<string>('');

  useEffect(() => {
    (async () => {
      setUfs(await ibge.getUfs());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setCities(await ibge.getCities(selectedUf));
    })();
  }, [selectedUf]);

  return (
    <Drawer open={open} anchor="right" onClose={toggleOpen}>
      <FilterContainer>
        <div className="title">
          <h1>Filtros</h1>
          <span onClick={toggleOpen}>X</span>
        </div>
        <p>
          Procure pelo usuário escolhendo os filtros adequados. Você pode limpar
          os filtros clicando{' '}
          <a href="#" style={{ color: 'rebeccapurple' }} onClick={clean}>
            aqui
          </a>
        </p>

        <Formik initialValues={{}} onSubmit={callback}>
          {({ values, handleChange }) => (
            <Form>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="subscriptionType-label">
                  Tipo de assinatura
                </InputLabel>
                <Select
                  labelId="subscriptionType-label"
                  id="demo-simple-select-outlined"
                  label="Tipo de assinatura"
                  defaultValue=""
                  value={values.subscriptionType}
                  onChange={handleChange}
                  name="subscriptionType"
                >
                  <MenuItem value="YEARLY">Assinatura Anual</MenuItem>
                  <MenuItem value="MONTHLY">Assinatura Mensal</MenuItem>
                  <MenuItem value="NOT_PAYD">Não pagantes</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  label="Nome"
                  placeholder="Ex: João Felício..."
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="uf-label">Estado</InputLabel>
                <Select
                  labelId="uf-label"
                  id="uf-select-outlined"
                  label="Estado"
                  value={values.uf}
                  defaultValue=""
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedUf(e.target.value as string);
                  }}
                  name="uf"
                >
                  {ufs?.map((uf) => {
                    return <MenuItem value={uf.value}>{uf.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="city-label">Cidade</InputLabel>
                <Select
                  labelId="city-label"
                  id="demo-simple-select-outlined"
                  label="Cidade"
                  defaultValue=""
                  value={values.city}
                  onChange={handleChange}
                  name="city"
                >
                  {cities?.map((city) => {
                    return <MenuItem value={city.name}>{city.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <Button text="Filtrar" size="full" />
            </Form>
          )}
        </Formik>
      </FilterContainer>
    </Drawer>
  );
}

export default FilterDrawer;

export const FilterContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 40px 20px;

  .title {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    font: 600 16px Inter;
    padding-bottom: 20px;

    span {
      cursor: pointer;
    }
  }

  p {
    font: 400 14px Roboto;
    padding-bottom: 30px;
  }

  form {
    width: 100%;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;
