import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../shared/components/Button';

interface CourseItemProps {
  id: string;
  title: string;
  description: string;
}

function CourseItem({ title, description, id }: CourseItemProps) {
  return (
    <CourseItemContainer>
      <h3>{title}</h3>
      <p>{description}</p>
      <Link to={`/espiritualidade/${id}`}>
        <Button size="full">Entrar</Button>
      </Link>
    </CourseItemContainer>
  );
}

export default CourseItem;

const CourseItemContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 15px;
  padding: 20px 30px;

  h3 {
    font: 700 16px Roboto;
    padding-bottom: 10px;
  }

  p {
    font: 400 14px Roboto;
    line-height: 20px;
    height: 120px;
    width: 100%;
    color: #666;
    overflow: hidden;
    text-overflow: '...';
  }

  button {
    margin-top: 30px;
  }
`;
