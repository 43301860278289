import { createContext, useContext, useState } from 'react';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import CalendarReminder from '../api/ts/interface/CalendarReminder';

interface ScheduleProps {
  schedules: CalendarReminder[];
  setSchedules: (schedules: CalendarReminder[]) => void;
  appointments: AppointmentModel[];
  setAppointments: (appointments: AppointmentModel[]) => void;
}

const scheduleContext = createContext({} as ScheduleProps);

const ScheduleProvider = ({ children }: { children: JSX.Element }) => {
  const [schedules, setSchedules] = useState<CalendarReminder[]>([]);
  const [appointments, setAppointments] = useState<AppointmentModel[]>([]);
  const { Provider } = scheduleContext;
  return (
    <Provider
      value={{ schedules, setSchedules, appointments, setAppointments }}
    >
      {children}
    </Provider>
  );
};

const useScheduleContext = () => {
  const context = useContext(scheduleContext);
  return context;
};

export { ScheduleProvider, useScheduleContext };
