import React from 'react';
import styled from 'styled-components';

import { Dialog, IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import EditContentForm from './EditContentForm';
import { ContentData } from '../ts/course.interface';
import useCourse from '../hooks/useCourse';

interface UploadContentProps {
  open: boolean;
  toggleOpen: () => void;
  refresh: () => void;
  contentType: string;
  courseId: string;
}

function UploadContentModal({
  open,
  toggleOpen,
  contentType,
  courseId,
  refresh
}: UploadContentProps) {
  const { uploadCourseContent } = useCourse();

  const handleUploadContent = async (values: ContentData) => {
    await uploadCourseContent(values);
    refresh();
    toggleOpen();
  };

  return (
    <Dialog open={open} onClose={toggleOpen} maxWidth="md" fullWidth>
      <UploadContentModalContainer>
        <header>
          <h3>
            Novo {contentType === 'Video' && 'vídeo'}
            {contentType === 'Audio' && 'áudio'}
          </h3>
          <IconButton onClick={toggleOpen}>
            <CloseOutlined />
          </IconButton>
        </header>

        <EditContentForm
          format={contentType?.toUpperCase()}
          callback={handleUploadContent}
          courseId={courseId}
        />
      </UploadContentModalContainer>
    </Dialog>
  );
}

export default UploadContentModal;

const UploadContentModalContainer = styled.div`
  padding: 20px 20px 10px 20px;

  header {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: 700 15px Inter;
      color: #555;
    }
  }
`;
