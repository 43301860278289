import styled from 'styled-components';

export const LiveStreamContainer = styled.div``;

export const LiveStreamSection = styled.div`
  display: -webkit-flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const StreamViewContainer = styled.div`
  width: 50vw;

  .publisher {
    width: 50vw;
    height: 60vh;
    overflow: hidden;
    position: relative;
    border-radius: 20px;

    .cover-options {
      display: -webkit-flex;
      position: absolute;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      top: 0;
      padding: 5px 20px;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.4)
      );
      z-index: 20;

      .base-indicators {
        display: -webkit-flex;
      }

      .options {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        color: white;
        fill: white;

        .mobile {
          display: none;
        }

        @media (max-width: 599px) {
          .mobile {
            display: block;
          }
        }
      }

      .icon {
        display: -webkit-flex;
        gap: 4px;
        color: white;
        fill: white;
        font-family: Inter;
      }

      .is-live {
        height: max-content;
        border-radius: 5px;
        padding: 5px 8px;
        background: red;
        color: white;
        font: 600 14px Inter;
        margin-right: 10px;
      }
    }

    @media (max-width: 699px) {
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }

  .stream-data {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    h3 {
      font: 700 25px Roboto;
      color: #555;
    }

    div {
      display: -webkit-flex;
      gap: 8px;
    }

    @media (max-width: 699px) {
      h3 {
        font: 700 16px Roboto;
      }

      button {
        font-size: 10px;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 599px) {
      display: none;
    }
  }

  @media (max-width: 699px) {
    width: 100%;
  }
`;

export const LiveFeedback = styled.div`
  width: 50vw;
  height: 60vh;
  overflow: hidden;
  border-radius: 20px;
  background: black;
  display: grid;
  place-items: center;

  h1 {
    color: #666;
    font: 700 20px Inter;
  }

  @media (max-width: 699px) {
    width: 95vw;
    height: 30vh;
  }

  @media (max-width: 599px) {
    display: none;
  }
`;
