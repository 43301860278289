import React, { useEffect, useState } from 'react';

import {
  CircularProgress,
  Collapse,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { CloseOutlined, RefreshOutlined } from '@material-ui/icons';
import { Plan } from 'http/plans/ts/plan.interface';
import IconButton from 'shared/components/IconButton';

import useSort from 'shared/hooks/useSort';
import usePlans from '../../hooks/usePlans';

import TableHead from '../../../../shared/components/TableHead';
import Section from '../../../../shared/components/Section';
import Button from '../../../../shared/components/Button';
import Table from '../../../../shared/components/Table';

import { PlansContainer, PlansSection } from './styles';

import PlansForm from '../../components/PlansForm';
import CreatePlanModal from '../../components/CreatePlanModal';

function Plans() {
  const { getAllPlans } = usePlans();
  const [plans, setPlans] = useState<Plan[]>();
  const [expanded, setExpanded] = useState<number | null>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const getPlans = async () => {
    setPlans(await getAllPlans());
  };

  useEffect(() => {
    getPlans();
  }, []);

  const { sortedItems, requestSort, sortConfig } = useSort<Plan>(
    plans as Plan[],
    {
      key: 'title' as keyof Plan,
      direction: 'desc'
    }
  );

  const headCells = [
    { name: 'ID', align: 'left', id: 'id', sorting: true },
    { name: 'Título', align: 'left', id: 'title', sorting: true },
    { name: 'Descrição', align: 'left', id: 'description', sorting: true },
    { name: 'Preço', align: 'center', id: 'amount', sorting: true },
    { name: 'Data', align: 'center', id: 'created_at', sorting: true },
    { name: 'Ações', align: 'center', id: 'actions', sorting: false }
  ];

  return (
    <PlansContainer>
      <Section
        title="Planos"
        options={[
          <Button
            size="sm"
            onClick={() => setIsCreateModalOpen((state) => !state)}
          >
            Novo plano
          </Button>,
          <IconButton onClick={() => getPlans()}>
            <RefreshOutlined />
          </IconButton>
        ]}
      >
        <PlansSection>
          <Table>
            <TableHead
              headCells={headCells}
              sortConfig={sortConfig}
              sort={(id) => requestSort(id as keyof Plan)}
            />
            <TableBody>
              {plans ? (
                sortedItems.map((item, idx) => {
                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          {item.id.replace(/-/g, '_')}
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          {item.title}
                        </TableCell>
                        <TableCell>
                          <p className="description">{item.description}</p>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          R$ {item.amount}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(item.created_at).toLocaleDateString(
                            'pt-BR'
                          )}
                        </TableCell>
                        <TableCell className="actions">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Button
                              text="Editar"
                              size="sm"
                              onClick={() => handleOpenEditForm(idx)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse in={expanded === idx} unmountOnExit>
                            <div className="editHeader">
                              <h2>Editar</h2>
                              <CloseOutlined
                                onClick={() => handleOpenEditForm(idx)}
                              />
                            </div>
                            <PlansForm
                              initialValues={item}
                              edit
                              refresh={() => getPlans()}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}

              {plans?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <h1>Nenhum plano encontrado</h1>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </PlansSection>
        <CreatePlanModal
          open={isCreateModalOpen}
          toggleOpen={() => setIsCreateModalOpen((state) => !state)}
        />
      </Section>
    </PlansContainer>
  );
}

export default Plans;
