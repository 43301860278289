import isAxiosError from 'helpers/isAxiosError';
import { getAll } from 'http/courses/api';
import { toast } from 'react-toastify';
import {
  createNotification,
  deleteNotification,
  getAllnotifications,
  getByStatus,
  updateNotification
} from '../api';
import Filter from '../ts/filter.interface';
import { NotificationProps } from '../ts/notification.interface';

const useNotification = () => {
  const fetchNotifications = async () => {
    try {
      const data = await getAllnotifications();
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const newNotification = async ({
    title,
    file,
    startAt,
    endAt,
    repeatType,
    receiversType,
    message,
    courseId
  }: NotificationProps) => {
    try {
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      data.append('title', title);
      data.append('startAt', startAt.toISOString());
      data.append('endAt', endAt ? endAt.toISOString() : startAt.toISOString());
      data.append('repeatType', repeatType);
      data.append('receiversType', receiversType);
      if (receiversType === 'HAVE_COURSE_PURCHASED_USERS' && courseId) {
        data.append('courseID', courseId);
      }
      data.append('message', message);
      await createNotification(data);
      toast.success('criado com sucesso');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const editNotification = async (
    notifyId: string,
    {
      title,
      startAt,
      endAt,
      file,
      repeatType,
      receiversType,
      message,
      courseId
    }: NotificationProps
  ) => {
    try {
      const data = new FormData();
      data.append('title', title);
      data.append('startAt', new Date(startAt).toISOString());
      data.append('endAt', endAt ? new Date(endAt).toISOString() : '');
      data.append('repeatType', repeatType);
      data.append('receiversType', receiversType);
      data.append('message', message);
      if (receiversType === 'HAVE_COURSE_PURCHASED_USERS' && courseId) {
        data.append('courseID', courseId);
      }

      if (file) {
        data.append('image', file);
      }

      await updateNotification(notifyId, data);
      toast.success('autalizado com sucesso!');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getNotificationByStatus = async (
    status: string,
    filters: Filter | undefined
  ) => {
    try {
      const data = await getByStatus(status, filters);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const removeNotification = async (notifyId: string) => {
    try {
      await deleteNotification(notifyId);
      toast.success('Notificação deletada');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getCourses = async () => {
    try {
      // external module requisition (courses);
      const data = getAll();

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    newNotification,
    editNotification,
    removeNotification,
    fetchNotifications,
    getNotificationByStatus,
    getCourses
  };
};

export default useNotification;
