import styled from 'styled-components';

export const PlansContainer = styled.div``;

export const PlansSection = styled.div`
  background: white;

  .description {
    @media (max-width: 599px) {
      width: 350px;
    }
  }
`;
