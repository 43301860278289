import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import useCourse from 'http/courses/hooks/useCourse';
import styled from 'styled-components';

import {
  Collapse,
  List,
  Fade,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import {
  CloseOutlined,
  DeleteOutlined,
  DescriptionOutlined,
  MicOutlined,
  VideocamOutlined
} from '@material-ui/icons';

import useSort from 'shared/hooks/useSort';
import { Content, ContentData } from 'http/courses/ts/course.interface';
import Button from 'shared/components/Button';
import TableHead from 'shared/components/TableHead';
import IconButton from 'shared/components/IconButton';

import Table from '../../../../shared/components/Table';
import Section from '../../../../shared/components/Section';

import EditContentForm from '../../components/EditContentForm';
import UploadContentModal from '../../components/UploadContentModal';

import { CourseContentContainer, CourseContentSection } from './styles';

function CourseContent() {
  const { id } = useParams() as { id: string };
  const { getCourseContent, updateCourseContent, deleteCourseContent } =
    useCourse();
  const [content, setContent] = useState<Content[] | undefined>();
  const [contentType, setContentType] = useState<
    'Audio' | 'Video' | 'Material' | ''
  >('Video');
  const [expanded, setExpanded] = useState<number | null>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

  const [newContentType, setNewContentType] = useState<string>('');

  const popoverOpen = Boolean(anchorEl);

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const handleGetCourseContent = async () => {
    const data = await getCourseContent(contentType.toUpperCase(), id);
    setContent(data);
  };

  const handleUpdateContent = async (id: string, values: ContentData) => {
    await updateCourseContent(id, values);
    handleGetCourseContent();
  };

  const handleDeleteCourseContent = async (id: string) => {
    await deleteCourseContent(id);
    handleGetCourseContent();
  };

  useEffect(() => {
    handleGetCourseContent();
  }, [contentType]);

  const contentTypeOptions = [
    <ButtonsList>
      <Button size="sm" onClick={() => setContentType('Audio')}>
        Áudio
      </Button>
      <Button size="sm" onClick={() => setContentType('Video')}>
        Vídeo
      </Button>
      <Button size="sm" onClick={() => setContentType('Material')}>
        Materiais
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Adicionar
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
      >
        <List>
          <ListItem button onClick={() => setNewContentType('Audio')}>
            <ListItemIcon>
              <MicOutlined />
            </ListItemIcon>
            <ListItemText primary="Áudio" />
          </ListItem>
          <ListItem button onClick={() => setNewContentType('Video')}>
            <ListItemIcon>
              <VideocamOutlined />
            </ListItemIcon>
            <ListItemText primary="Vídeo" />
          </ListItem>
          <ListItem button onClick={() => setNewContentType('Material')}>
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            <ListItemText primary="Material" />
          </ListItem>
        </List>
      </Popover>
    </ButtonsList>
  ];

  const headCells = [
    { name: 'Aula', align: 'left', id: 'title', sorting: true },
    {
      name: 'Descrição breve',
      align: 'left',
      id: 'description',
      sorting: true
    },
    { name: 'Tipo', align: 'left', id: 'format', sorting: true },
    {
      name: 'Data de inclusão',
      align: 'center',
      id: 'created_at',
      sorting: true
    },
    {
      name: 'Ações',
      align: 'center',
      id: 'actions',
      sorting: false
    }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<Content>(
    content as Content[],
    {
      key: 'name' as keyof Content,
      direction: 'desc'
    }
  );

  return (
    <Fade in>
      <CourseContentContainer>
        <Section
          title="Conteúdo"
          options={contentTypeOptions}
          rollbackPath="/courses"
        >
          <CourseContentSection>
            <Table>
              <TableHead
                headCells={headCells}
                sortConfig={sortConfig}
                sort={(id) => requestSort(id as keyof Content)}
              />

              <TableBody>
                {content ? (
                  sortedItems.map((item, idx) => {
                    return (
                      <>
                        <TableRow key={item.id}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.title}
                          </TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell align="left">
                            {item.format === 'VIDEO' && 'Vídeo'}
                            {item.format === 'AUDIO' && 'Áudio'}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(item.created_at).toLocaleDateString(
                              'pt-BR'
                            )}
                          </TableCell>
                          <TableCell className="actions">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                text="Editar"
                                size="sm"
                                onClick={() => handleOpenEditForm(idx)}
                              />
                              <IconButton
                                className="iconButton"
                                onClick={() =>
                                  handleDeleteCourseContent(item.id)
                                }
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse in={expanded === idx} unmountOnExit>
                              <div className="editHeader">
                                <h2>Editar</h2>
                                <CloseOutlined
                                  onClick={() => handleOpenEditForm(idx)}
                                />
                              </div>
                              <EditContentForm
                                courseId={id}
                                initialValues={item}
                                callback={(values) =>
                                  handleUpdateContent(item.id, values)
                                }
                                format={item.format}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {content?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <h1>Nenhum curso encontrado</h1>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CourseContentSection>
        </Section>
        <UploadContentModal
          courseId={id}
          refresh={handleGetCourseContent}
          open={!!newContentType}
          toggleOpen={() => setNewContentType('')}
          contentType={newContentType}
        />
      </CourseContentContainer>
    </Fade>
  );
}

export default CourseContent;

const ButtonsList = styled.div`
  display: flex;
  gap: 5px;

  @media (max-width: 599px) {
    display: grid;
    align-items: flex-end;

    grid-template-columns: 1fr 1fr;
  }
`;
