import React from 'react';

import { SwipeableDrawer } from '@material-ui/core';
import SidebarLink from './components/SidebarLink';
import {
  SidebarContainer,
  SidebarHeader,
  LinkList,
  DrawerContent
} from './styled';

import MeditaAquiLogo from '../../../../assets/images/logo-name.svg';
import HomeIcon from '../../../../assets/icons/sideMenu/home.svg';
import LiveIcon from '../../../../assets/icons/sideMenu/live.svg';
import EditCategoryIcon from '../../../../assets/icons/sideMenu/category.svg';
import NotificationIcon from '../../../../assets/icons/sideMenu/notification.svg';
import ScheduleIcon from '../../../../assets/icons/sideMenu/schedule.svg';
import CourseIcon from '../../../../assets/icons/sideMenu/course.svg';
import MeditationIcon from '../../../../assets/icons/sideMenu/meditation.svg';
import PlaylistIcon from '../../../../assets/icons/sideMenu/playlist.svg';
import SpiritualityIcon from '../../../../assets/icons/sideMenu/spirituality.svg';
import PlanIcon from '../../../../assets/icons/sideMenu/plan.svg';
import UserIcon from '../../../../assets/icons/sideMenu/user.svg';
import RevenuesIcon from '../../../../assets/icons/sideMenu/revenues.svg';

function Sidebar({
  open,
  toggleDrawer
}: {
  open: boolean;
  toggleDrawer: () => void;
}) {
  return (
    <>
      <SidebarContainer>
        <SidebarHeader>
          <img src={MeditaAquiLogo} alt="Logo Medita" />
        </SidebarHeader>
        <LinkList>
          <SidebarLink
            title="Gerenciamento"
            icon={HomeIcon}
            path="/management"
          />
          <SidebarLink title="Lives" icon={LiveIcon} path="/lives" />
          <SidebarLink
            title="Editar Categorias"
            icon={EditCategoryIcon}
            path="/categories"
          />
          <SidebarLink
            title="Notificações"
            icon={NotificationIcon}
            path="/notification"
          />
          <SidebarLink title="Agenda" icon={ScheduleIcon} path="/schedule" />
          <SidebarLink
            title="Cursos"
            icon={CourseIcon}
            path="/courses"
            subLinks={[{ title: 'Editar cursos', path: '/courses/manage' }]}
          />
          <SidebarLink
            title="Meditações"
            icon={MeditationIcon}
            path="/meditations"
          />
          <SidebarLink
            title="Playlist do Tadashi"
            icon={PlaylistIcon}
            path="/playlist"
          />
          <SidebarLink
            title="Espiritualidade no Sucesso Profissional"
            icon={SpiritualityIcon}
            path="/espiritualidade"
            subLinks={[
              {
                title: 'Editar Espiritualidade',
                path: '/espiritualidade/manage'
              }
            ]}
          />
          <SidebarLink title="Planos" icon={PlanIcon} path="/plans" />
          <SidebarLink title="Usuários" icon={UserIcon} path="/users" />
          <SidebarLink
            title="Faturamento"
            icon={RevenuesIcon}
            path="/revenues"
          />
        </LinkList>
      </SidebarContainer>

      <SwipeableDrawer
        open={open}
        anchor="left"
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <DrawerContent>
          <LinkList>
            <SidebarLink
              title="Gerenciamento"
              icon={HomeIcon}
              path="/management"
            />
            <SidebarLink title="Lives" icon={LiveIcon} path="/lives" />
            <SidebarLink
              title="Editar Categorias"
              icon={EditCategoryIcon}
              path="/categories"
            />
            <SidebarLink
              title="Notificações"
              icon={NotificationIcon}
              path="/notification"
            />
            <SidebarLink title="Agenda" icon={ScheduleIcon} path="/schedule" />
            <SidebarLink
              title="Cursos"
              icon={CourseIcon}
              path="/courses"
              subLinks={[{ title: 'Editar cursos', path: '/courses/manage' }]}
            />
            <SidebarLink
              title="Meditações"
              icon={MeditationIcon}
              path="/meditations"
            />
            <SidebarLink
              title="Playlist do Tadashi"
              icon={PlaylistIcon}
              path="/playlist"
            />
            <SidebarLink
              title="Espiritualidade no Sucesso Profissional"
              icon={SpiritualityIcon}
              path="/espiritualidade"
              subLinks={[
                {
                  title: 'Editar espiritualidade',
                  path: '/espiritualidade/manage'
                }
              ]}
            />
            <SidebarLink title="Planos" icon={PlanIcon} path="/plans" />
            <SidebarLink title="Usuários" icon={UserIcon} path="/users" />
            <SidebarLink
              title="Faturamento"
              icon={RevenuesIcon}
              path="/revenues"
            />
          </LinkList>
        </DrawerContent>
      </SwipeableDrawer>
    </>
  );
}

export default Sidebar;
