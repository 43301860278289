import useLoginHook from 'http/authentication/hooks/useLoginHook';
import { Form, Formik, FormikValues } from 'formik';
import {
  CircularProgress,
  Fade,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { LoginContainer, LoginBox, SubmitButton } from './styles';
import ImageLogin from '../../../../assets/images/logo-login.svg';

const Login = () => {
  const { login } = useLoginHook();

  const handleSubmit = async (values: FormikValues) => {
    await login(values.email, values.password);
  };

  return (
    <Fade in>
      <LoginContainer>
        <LoginBox>
          <img src={ImageLogin} alt="logo medita aqui" />

          <h1>Login</h1>
          <Formik
            initialValues={{ email: '', password: '', showPassword: false }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <FormControl fullWidth className="formControl">
                  <TextField
                    label="Login"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth className="formControl">
                  <InputLabel htmlFor="password">Senha</InputLabel>
                  <Input
                    id="password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setFieldValue('showPassword', !values.showPassword)
                          }
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <SubmitButton type="submit">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Entrar'
                  )}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </LoginBox>
      </LoginContainer>
    </Fade>
  );
};
export default Login;
