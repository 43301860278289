import { Avatar } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface MessageProps {
  userImg: string;
  message: string;
  userName: string;
}

function ChatMessage({ userImg, message, userName }: MessageProps) {
  return (
    <MessageContainer>
      <Avatar src={userImg} />
      <div>
        <h3>{userName}</h3>
        <p>{message}</p>
      </div>
    </MessageContainer>
  );
}

export default ChatMessage;

const MessageContainer = styled.div`
  width: 100%;
  display: -webkit-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;

  h3 {
    font: 700 12px Raleway;
    margin-bottom: 6px;
  }

  p {
    font: 300 11px Roboto;
    color: whitesmoke;

    @media (max-width: 599px) {
      padding: 5px;
      border-radius: 3px;
    }
  }
`;
