import { toast } from 'react-toastify';
import {
  create,
  deleteContent,
  getAll,
  getContent,
  getCourse,
  remove,
  update,
  updateContent,
  uploadContent
} from '../api';
import isAxiosError from '../../../helpers/isAxiosError';
import { ContentData, CourseData } from '../ts/course.interface';

const useCourse = () => {
  const getCourses = async () => {
    try {
      const courses = await getAll();

      return courses;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const createCourse = async (values: CourseData) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('file', values.file);
    formData.append('amount', values.amount.toString());
    formData.append('type', values.type);
    formData.append('download', values.download ? 'yes' : '');
    formData.append('certificate', values.certificate ? 'yes' : '');
    formData.append('tags', values.tags.toString());
    formData.append('formats', values.formats.toString());
    formData.append('category', '[]');
    formData.append('subCategory', '[]');

    try {
      const res = await create(formData);

      return res;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };
  const updateCourse = async (id: string, values: CourseData) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('file', values.file);
    formData.append('amount', values.amount.toString());
    formData.append('type', values.type);
    formData.append('download', values.download ? 'yes' : '');
    formData.append('certificate', values.certificate ? 'yes' : '');
    formData.append('tags', values.tags.toString());
    formData.append('formats', values.formats.toString());
    formData.append('category', '[]');
    formData.append('subCategory', '[]');

    try {
      const data = await update(id, formData);

      toast.success('Curso alterado com sucesso');
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const deleteCourse = async (courseId: string) => {
    try {
      await remove(courseId);

      toast.success('Curso deletado com sucesso');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getCourseDetails = async (courseId: string) => {
    try {
      const data = await getCourse(courseId);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getCourseContent = async (type: string, courseId: string) => {
    try {
      const data = await getContent(type, courseId);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const uploadCourseContent = async (values: ContentData) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('file', values.file);
    if (values.thumb) {
      formData.append('thumb', values.thumb);
    }
    formData.append('format', values.format);
    formData.append('course_id', values.course_id);
    formData.append(
      'duration_millis',
      Math.round(values.duration_millis).toString()
    );
    formData.append('time_stop', values.time_stop.toString());
    formData.append('position', values.position.toString());

    try {
      await uploadContent(formData);
      toast.success('Nova aula adicionada');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const updateCourseContent = async (
    contentId: string,
    values: ContentData
  ) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('file', values.file);
    if (values.thumb) {
      formData.append('thumb', values.thumb);
    }
    formData.append(
      'duration_millis',
      Math.round(values.duration_millis).toString()
    );

    try {
      await updateContent(contentId, formData);
      toast.success('Aula atualizada');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const deleteCourseContent = async (id: string) => {
    try {
      await deleteContent(id);

      toast.success('Conteúdo deletado');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    getCourses,
    createCourse,
    deleteCourse,
    updateCourse,
    getCourseDetails,
    getCourseContent,
    uploadCourseContent,
    updateCourseContent,
    deleteCourseContent
  };
};

export default useCourse;
