import { ButtonHTMLAttributes, ReactElement } from 'react';
import { IconButtonContainer } from './styles';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactElement;
}

function IconButton({ children, ...rest }: IconButtonProps) {
  return <IconButtonContainer {...rest}>{children}</IconButtonContainer>;
}

export default IconButton;
