import React, { useState } from 'react';
import Button from 'shared/components/Button';
import useSort from 'shared/hooks/useSort';
import TableHead from 'shared/components/TableHead';
import {
  Fade,
  TableRow,
  TableCell,
  TableBody,
  Collapse
} from '@material-ui/core';
import moment from 'moment';
import { CloseOutlined } from '@material-ui/icons';
import { CategoriesContainer, EditPagesSection } from './styles';

import Section from '../../../../shared/components/Section';
import Table from '../../../../shared/components/Table';

import CategoryForm from '../../components/CategoryForm';

import useCategory from '../../hooks/useCategory';

interface Row {
  id: string;
  name: string;
  date: string;
  image: string;
}

function Categories() {
  const { categories } = useCategory();

  const [expanded, setExpanded] = useState<number | null>();

  function createData(id: string, name: string, date: string, image: string) {
    return { id, name, date, image };
  }

  const rows = categories.map((item) => {
    return createData(
      item.id,
      item.name,
      moment(item.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      item.background_url
    );
  });

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const headCells = [
    { name: 'Tema', align: 'left', id: 'name', sorting: true },
    { name: 'Data', align: 'left', id: 'date', sorting: true },
    { name: 'Capa', align: 'left', id: 'image', sorting: false },
    {
      name: 'Ações',
      align: 'center',
      id: 'actions',
      sorting: false
    }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<Row>(rows as Row[], {
    key: 'name' as keyof Row,
    direction: 'desc'
  });

  return (
    <Fade in>
      <CategoriesContainer>
        <Section title="Editar páginas">
          <EditPagesSection>
            <h2>Lives e Vídeos</h2>

            <CategoryForm />

            <br />
            <h2>Editar temas existentes</h2>
            <br />

            <Table>
              <TableHead
                headCells={headCells}
                sortConfig={sortConfig}
                sort={(id) => requestSort(id as keyof Row)}
              />
              <TableBody>
                {sortedItems.map((row, idx) => (
                  <>
                    <TableRow key={row.name}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        <img
                          alt={row.name}
                          src={row.image}
                          height="60px"
                          width="60px"
                        />
                      </TableCell>
                      <TableCell align="center" className="actions">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Button
                            text="Editar"
                            size="sm"
                            onClick={() => handleOpenEditForm(idx)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={4}
                      >
                        <Collapse in={expanded === idx} unmountOnExit>
                          <div className="editHeader">
                            <h2>Editar tema</h2>
                            <CloseOutlined
                              onClick={() => handleOpenEditForm(idx)}
                            />
                          </div>
                          <CategoryForm
                            edit
                            id={row.id}
                            name={row.name}
                            date={row.date}
                            file={row.image}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </EditPagesSection>
        </Section>
      </CategoriesContainer>
    </Fade>
  );
}

export default Categories;
