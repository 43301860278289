import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  Collapse,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

import useSort from 'shared/hooks/useSort';
import useCourse from 'http/courses/hooks/useCourse';
import { Course, CourseData } from 'http/courses/ts/course.interface';

import Button from 'shared/components/Button';
import TableHead from 'shared/components/TableHead';

import Section from '../../../../shared/components/Section';
import Table from '../../../../shared/components/Table';

import CourseForm from '../../components/CourseForm';

import { ManageCoursesContainer, ManageCoursesSection } from './styles';

function ManageCourses() {
  const { getCourses, updateCourse } = useCourse();
  const [courses, setCourses] = useState<Course[] | undefined>();
  const [expanded, setExpanded] = useState<number | null>();

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const handleGetCourses = async () => {
    const data = await getCourses();
    setCourses(data);
  };

  const handleUpdateCourse = async (id: string, values: CourseData) => {
    await updateCourse(id, values);
    handleGetCourses();
  };

  useEffect(() => {
    handleGetCourses();
  }, []);

  const headCells = [
    { name: 'ID', align: 'left', id: 'id', sorting: true },
    { name: 'Categoria', align: 'left', id: 'type', sorting: true },
    { name: 'Imagem', align: 'center', id: 'thumb', sorting: false },
    { name: 'Nome', align: 'left', id: 'name', sorting: true },
    { name: 'Valor', align: 'center', id: 'amount', sorting: true },
    { name: 'Certificado', align: 'center', id: 'certificate', sorting: false },
    { name: 'Download', align: 'center', id: 'download', sorting: false },
    {
      name: 'Ações',
      align: 'center',
      id: 'actions',
      sorting: false
    }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<Course>(
    courses as Course[],
    {
      key: 'name' as keyof Course,
      direction: 'desc'
    }
  );

  return (
    <ManageCoursesContainer>
      <div>
        <Section title="Gerenciar cursos">
          <ManageCoursesSection>
            <Table>
              <TableHead
                headCells={headCells}
                sortConfig={sortConfig}
                sort={(id) => requestSort(id as keyof Course)}
              />
              <TableBody>
                {courses ? (
                  sortedItems.map((item, idx) => {
                    return (
                      <>
                        <TableRow key={item.id}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.id.replace(/-/g, '_')}
                          </TableCell>
                          <TableCell>{item.type.toLowerCase()}</TableCell>
                          <TableCell className="center-cell">
                            <img alt={item.name} src={item.thumb} />
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            R$ {item.amount}
                          </TableCell>
                          <TableCell padding="checkbox" align="center">
                            <Checkbox
                              color="primary"
                              checked={item.certificate}
                            />
                          </TableCell>
                          <TableCell padding="checkbox" align="center">
                            <Checkbox color="primary" checked={item.download} />
                          </TableCell>
                          <TableCell className="actions">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                text="Editar"
                                size="sm"
                                onClick={() => handleOpenEditForm(idx)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={9}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse in={expanded === idx} unmountOnExit>
                              <div className="editHeader">
                                <h2>Editar</h2>
                                <CloseOutlined
                                  onClick={() => handleOpenEditForm(idx)}
                                />
                              </div>
                              <CourseForm
                                callback={(values) =>
                                  handleUpdateCourse(item.id, values)
                                }
                                initialValues={item}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {courses?.length === 0 && (
                  <TableRow>
                    <TableCell>
                      <h1>Nenhum curso encontrado</h1>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ManageCoursesSection>
        </Section>
      </div>
    </ManageCoursesContainer>
  );
}

export default ManageCourses;
