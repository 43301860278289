import React from 'react';
import styled from 'styled-components';
import {
  AccessTimeOutlined,
  CheckRounded,
  ErrorRounded,
  RefreshOutlined
} from '@material-ui/icons';

type BadgeProps = {
  number: number;
  status: string;
};

function Badge({ number, status }: BadgeProps) {
  return (
    <BadgeContainer status={status}>
      <span>{number}</span>
      {status === 'PROCESSED' && <CheckRounded fontSize="small" />}
      {status === 'PENDING' && <AccessTimeOutlined fontSize="small" />}
      {(status === 'REJECTED' || status === 'CANCELLED') && (
        <ErrorRounded fontSize="small" />
      )}
      {(status === 'REFUNDED' || status === 'CHARGED_BACK') && (
        <RefreshOutlined fontSize="small" />
      )}
    </BadgeContainer>
  );
}

export default Badge;

const BadgeContainer = styled.div<{ status: string }>`
  margin-right: 8px;
  display: -webkit-flex;
  align-items: center;
  border-radius: 5px;
  padding: 3px;
  background-color: ${(p) => {
    switch (p.status) {
      case 'PROCESSED':
        return '#75f5b1';
      case 'REJECTED':
        return '#ec483c';
      case 'CANCELLED':
        return '#ec483c';
      case 'PENDING':
        return '#d5f873';
      case 'REFUNDED':
        return '#f0ac37';
      case 'CHARGED_BACK':
        return '#f0ac37';
      default:
        return '#75f5b1';
    }
  }};

  color: ${(p) => {
    switch (p.status) {
      case 'PROCESSED':
        return '#75f5b1';
      case 'REJECTED':
        return '#ec483c';
      case 'CANCELLED':
        return '#ec483c';
      case 'PENDING':
        return '#d5f873';
      case 'REFUNDED':
        return '#f0ac37';
      case 'CHARGED_BACK':
        return '#f0ac37';
      default:
        return '#75f5b1';
    }
  }};

  span {
    font: 400 12px Roboto;
    color: black;
    margin: 0 5px;
  }

  .MuiSvgIcon-root {
    border-radius: 20px;
    background: ${(p) => {
      switch (p.status) {
        case 'PROCESSED':
          return '#09b358';
        case 'REJECTED':
          return '#a81208';
        case 'CANCELLED':
          return '#a81208';
        case 'PENDING':
          return '#6d9403';
        case 'REFUNDED':
          return '#bf831b';
        case 'CHARGED_BACK':
          return '#bf831b';
        default:
          return '#75f5b1';
      }
    }};
  }
`;
