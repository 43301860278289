import React, { useRef } from 'react';
import styled from 'styled-components';
import { Form, Formik, FormikValues } from 'formik';

import Button from 'shared/components/Button';

import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core';

import { PublishOutlined } from '@material-ui/icons';
import useCategory from '../hooks/useCategory';
import CategorySchema from '../validation/CategorySchema';

interface CategoryFormProps {
  id?: string;
  name?: string;
  file?: File | string;
  date?: string;
  edit?: boolean;
}

function CategoryForm({
  id,
  name = '',
  file = '',
  date = '',
  edit = false
}: CategoryFormProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { createCategory, updateCategory } = useCategory();

  const selectFile = () => {
    fileInputRef?.current?.click();
  };

  const handleCreateCategory = async (values: FormikValues) => {
    await createCategory(values.name, '', values.file);
  };

  const handleEditCategory = async (values: FormikValues) => {
    if (id) {
      await updateCategory(id, values.name, values.file, '');
    }
  };

  return (
    <CategoryFormContainer>
      <Formik
        initialValues={{ date, name, file }}
        onSubmit={edit ? handleEditCategory : handleCreateCategory}
        validationSchema={CategorySchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Digite o novo tema"
                    variant="outlined"
                    placeholder="Ex: Meditação, Concentração, Autocontrole"
                    value={values.name}
                    onChange={handleChange}
                    name="name"
                    helperText={touched.name && errors.name}
                    error={!!touched.name && !!errors.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    aria-readonly
                    onClick={selectFile}
                    label="Imagem"
                    variant="outlined"
                    value={
                      values.file
                        ? typeof values.file === 'string'
                          ? values.file
                          : 'Arquivo carregado'
                        : ''
                    }
                    helperText={touched.file && errors.file}
                    error={!!touched.file && !!errors.file}
                    InputProps={{
                      endAdornment: values.file ? (
                        <img
                          className="inputThumbIcon"
                          src={
                            typeof values.file === 'object'
                              ? URL.createObjectURL(values.file)
                              : values.file
                          }
                          alt="thumb"
                          height="25px"
                          width="40px"
                        />
                      ) : (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    accept=".png,.jpeg"
                    ref={fileInputRef}
                    onChange={(value) => {
                      if (value.target.files?.length) {
                        setFieldValue('file', value.target.files[0]);
                      }
                    }}
                    name="file"
                    hidden
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button type="submit">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Confirmar'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </CategoryFormContainer>
  );
}

export default CategoryForm;

const CategoryFormContainer = styled.div``;
