import React from 'react';
import styled from 'styled-components';
import { Dialog, IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import PlansForm from './PlansForm';

type ModalProps = {
  open: boolean;
  toggleOpen: () => void;
};

function CreatePlanModal({ open, toggleOpen }: ModalProps) {
  return (
    <Dialog open={open} onClose={toggleOpen} maxWidth="xl" fullWidth>
      <CreatePlanContainer>
        <header>
          <h1>Novo Plano</h1>
          <IconButton onClick={toggleOpen}>
            <CloseOutlined />
          </IconButton>
        </header>
        <PlansForm />
      </CreatePlanContainer>
    </Dialog>
  );
}

export default CreatePlanModal;

const CreatePlanContainer = styled.div`
  padding: 20px 20px 0 20px;
  header {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font: 700 14px Inter;
    }
  }
`;
