import { SortDirection } from '@material-ui/core';
import { useMemo, useState } from 'react';

interface Config<T> {
  key: keyof T;
  direction: 'desc' | 'asc';
}

const useSort = <T>(items: T[], config?: Config<T>) => {
  const [sortConfig, setSortConfig] = useState<Config<T> | null>(
    config || null
  );

  const sortedItems = useMemo(() => {
    if (items) {
      const sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }

    return [];
  }, [items, sortConfig]);

  const requestSort = (key: keyof T) => {
    let direction: SortDirection = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return { sortedItems, requestSort, sortConfig, setSortConfig };
};

export default useSort;
