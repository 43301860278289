import React, { HTMLProps, ReactNode } from 'react';
import { ArrowBackIos } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import { SectionContainer, SectionHeader } from './styles';

interface SectionProps extends HTMLProps<HTMLDivElement> {
  title: string;
  children: ReactNode;
  options?: ReactNode[];
  rollbackPath?: string;
}

function Section({ title, children, options, rollbackPath }: SectionProps) {
  return (
    <SectionContainer>
      <SectionHeader>
        <h2>
          {rollbackPath && (
            <Link to={rollbackPath}>
              <ArrowBackIos style={{ color: '#555' }} />
            </Link>
          )}
          {title}
        </h2>
        <div className="options">{options}</div>
      </SectionHeader>
      {children}
    </SectionContainer>
  );
}

export default Section;
