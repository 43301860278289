import React, { useState } from 'react';
import { AppointmentModel, ViewState } from '@devexpress/dx-react-scheduler';
import Button from 'shared/components/Button';
import CalendarReminder from 'http/schedule/api/ts/interface/CalendarReminder';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import {
  Scheduler,
  DayView,
  MonthView,
  Appointments,
  DateNavigator,
  AllDayPanel,
  AppointmentTooltip,
  Toolbar
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  ScheduleContainer,
  ScheduleSection,
  CalendarContainer,
  CalendarDayScaleCell,
  DayScheduleContainer,
  DayCheduler,
  TimeScaleDayLabel,
  Appointment
} from './styles';

import Section from '../../../../shared/components/Section';
import EventFormModal from '../../components/EventFormModal';

import useSchedule from '../../hooks/useSchedule';

function Schedule() {
  const { newSchedule, appointments, schedules, editSchedule, removeSchedule } =
    useSchedule();

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<CalendarReminder>();
  const [deleteId, setDeleteId] = useState<string>();

  const handleEditAppointment = (data: AppointmentModel) => {
    const event = schedules.find((item) => {
      return item.id === data.id;
    });

    setInitialValues(event);
    setIsEventFormOpen(true);
  };

  const handleDeleteAppointment = (id: string) => {
    setDeleteId(id);
  };

  const handleToggleModal = () => {
    if (initialValues) {
      setInitialValues(undefined);
    }
    setIsEventFormOpen((state) => !state);
  };

  const Header = ({
    children,
    appointmentData,
    onHide,
    ...restProps
  }: AppointmentTooltip.HeaderProps) => (
    <AppointmentTooltip.Header
      {...restProps}
      appointmentData={appointmentData}
      onOpenButtonClick={() => {
        onHide?.();
        handleEditAppointment(appointmentData as AppointmentModel);
      }}
      onDeleteButtonClick={() => {
        onHide?.();
        handleDeleteAppointment(appointmentData?.id as string);
      }}
    >
      {children}
    </AppointmentTooltip.Header>
  );

  const TimeCellComponent = ({ ...rest }: MonthView.TimeTableCellProps) => {
    return (
      <CalendarDayScaleCell
        {...rest}
        onDoubleClick={() => setCurrentDate(rest.startDate)}
      />
    );
  };

  const timeScaleDayComponent = ({
    ...restProps
  }: DayView.TimeScaleLabelProps) => {
    return <TimeScaleDayLabel {...restProps} />;
  };

  const appointmentComponent = ({
    children,
    ...props
  }: Appointments.AppointmentProps) => (
    <Appointment {...props}>{children}</Appointment>
  );

  const createAppointment = async (values: {
    start: string | Date;
    end: string | Date;
    title: string;
    message: string;
  }) => {
    await newSchedule({
      end: values.end,
      start: values.start,
      title: values.title,
      message: values.message
    });
  };

  const updateAppointment = async (
    id: string,
    values: {
      start: string | Date;
      end: string | Date;
      title: string;
      message: string;
    }
  ) => {
    await editSchedule({ id, ...values });
  };

  return (
    <>
      <EventFormModal
        open={isEventFormOpen}
        initialValues={initialValues}
        toggleOpen={handleToggleModal}
        callback={(values) =>
          initialValues
            ? updateAppointment(initialValues?.id, values)
            : createAppointment(values)
        }
      />

      <Dialog open={!!deleteId} onClose={() => setDeleteId(undefined)}>
        <DialogTitle>Deseja remover o evento?</DialogTitle>
        <DialogContent>
          Ao confirmar o evento será retirado do calendário dos usuários e os
          mesmos não terão mais acesso
        </DialogContent>
        <DialogActions>
          <Button variant="delete" onClick={() => setDeleteId(undefined)}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setDeleteId(undefined);
              removeSchedule(deleteId as string);
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <ScheduleContainer>
        <Section
          title="Agenda"
          options={[
            <Button size="sm" onClick={handleToggleModal}>
              Criar novo evento
            </Button>
          ]}
        >
          <ScheduleSection>
            <CalendarContainer>
              <Scheduler locale="pt-BR" data={appointments}>
                <ViewState
                  defaultCurrentDate={new Date()}
                  onCurrentDateChange={(date) => setCurrentDate(date)}
                  currentDate={currentDate}
                />
                <MonthView timeTableCellComponent={TimeCellComponent} />
                <Toolbar />
                <DateNavigator />
                <Appointments appointmentComponent={appointmentComponent} />

                <AppointmentTooltip
                  headerComponent={Header}
                  showOpenButton
                  showDeleteButton
                />
                <AllDayPanel />
              </Scheduler>
            </CalendarContainer>

            <DayScheduleContainer>
              <h1>Horários</h1>
              <DayCheduler locale="pt-BR" data={appointments} height={600}>
                <ViewState currentDate={currentDate} />
                <DayView
                  startDayHour={0}
                  endDayHour={24}
                  timeScaleLabelComponent={timeScaleDayComponent}
                />
                <Appointments appointmentComponent={appointmentComponent} />
                <AppointmentTooltip
                  headerComponent={Header}
                  showOpenButton
                  showDeleteButton
                />
                <AllDayPanel />
              </DayCheduler>
            </DayScheduleContainer>
          </ScheduleSection>
        </Section>
      </ScheduleContainer>
    </>
  );
}

export default Schedule;
