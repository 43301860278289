import { toast } from 'react-toastify';
import isAxiosError from '../../../helpers/isAxiosError';
import { addAudio, deleteAudio, getPlaylistContent, updateAudio } from '../api';
import { CreateSong } from '../ts/song.interface';

const usePlaylist = () => {
  const getPlaylist = async () => {
    try {
      const playlist = await getPlaylistContent();
      return playlist;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const deleteSong = async (playlistId: string, songId: string) => {
    try {
      await deleteAudio(playlistId, songId);
      toast.success('Audio deletado');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const addSong = async (playlistId: string, values: CreateSong) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append(
      'duration_millis',
      Math.round(values.duration_millis).toString()
    );
    formData.append('song', values.song);
    formData.append('playlist_id', playlistId);
    if (values.thumb) {
      formData.append('thumb', values.thumb);
    }
    if (values.description) {
      formData.append('description', values.description);
    }

    try {
      await addAudio(formData);

      toast.success('Adicionado à playlist!');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const editSong = async (playlistId: string, values: CreateSong) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append(
      'duration_millis',
      Math.round(values.duration_millis).toString()
    );
    formData.append('song', values.song);
    if (values.thumb) {
      formData.append('thumb', values.thumb);
    }
    if (values.description) {
      formData.append('description', values.description);
    }
    try {
      const res = await updateAudio(playlistId, formData);

      toast.success('Editado com sucesso');
      return res;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return { getPlaylist, deleteSong, addSong, editSong };
};

export default usePlaylist;
