import * as Yup from 'yup';

export const VideoSetupSchema = Yup.object({
  title: Yup.string().required('Insira um título para seu vídeo'),
  description: Yup.string()
    .max(150, 'Excedeu o número máximo de caracteres')
    .required('Insira uma descrição para seu vídeo'),
  category: Yup.string().required('Selecione uma categoria'),
  availableAt: Yup.date().required(
    'Selecione a data em que o vídeo ficará disponível'
  ),
  thumbnail: Yup.mixed()
    .notRequired()
    .test('fileFormat', 'Image only', (value) => {
      return value
        ? ['image/png', 'image/jpeg'].includes(value.type) ||
            typeof value === 'string'
        : !value;
    })
});
