import React, { ButtonHTMLAttributes } from 'react';

import { ButtonContainer } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  variant?: 'primary' | 'outline' | 'delete';
  size?: 'sm' | 'md' | 'lg' | 'full';
}

function Button({
  text,
  variant = 'primary',
  size,
  children,
  ...rest
}: ButtonProps) {
  return (
    <ButtonContainer variant={variant} size={size} {...rest}>
      {text}
      {children}
    </ButtonContainer>
  );
}

export default Button;
