import api from '../../../services/api';
import { Content, ESP } from '../ts/esp.interface';

export const getAll = async (): Promise<ESP[]> =>
  await api.get('/course/all?type=PROFISSIONAL').then((res) => res.data);

export const getESP = async (courseId: string): Promise<ESP> =>
  await api.get(`/course/${courseId}`);

export const getContent = async (
  type: string,
  courseId: string
): Promise<Content[]> =>
  await api.get('/course/content', {
    params: {
      format: type,
      course_id: courseId
    }
  });

export const update = async (id: string, espData: FormData): Promise<ESP> =>
  await api.put(`/course/${id}`, espData);

export const uploadContent = async (contentData: FormData): Promise<Content> =>
  await api.post('/course/content', contentData);

export const updateContent = async (
  contentId: string,
  contentData: FormData
): Promise<string> =>
  await api.put(`/course/content/${contentId}`, contentData);

export const deleteContent = async (id: string): Promise<string> =>
  await api.delete(`course/content/${id}`);

export const create = async (espData: FormData): Promise<ESP> =>
  await api.post('/course', espData);

export const remove = async (espId: string): Promise<string> =>
  await api.delete(`/course/${espId}`);
