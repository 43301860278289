import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { Fade } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useUpload from '../../hooks/useUpload';

import {
  UploadVideoContainer,
  UploadSection,
  DialogContainer,
  UploadContainer
} from './styles';

import Section from '../../../../shared/components/Section';
import Button from '../../../../shared/components/Button';
import UploadIcon from '../../../../assets/icons/upload.svg';

function UploadVideo() {
  const history = useHistory();
  const { saveVideo } = useUpload();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'video/*',
    onDropAccepted: (file) => saveVideoToSetup(file)
  });
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const saveVideoToSetup = (file: File[]) => {
    saveVideo(file[0]);
    toast.success('O vídeo foi carregado');
    history.push('/video-setup');
  };

  return (
    <Fade in>
      <UploadVideoContainer>
        <Section
          title="Upload"
          rollbackPath="/lives"
          options={[<Button text="Subir live editada" size="sm" />]}
        >
          <UploadSection>
            {isDialogOpen ? (
              <DialogContainer>
                <h1>Para começar, faça upload de um vídeo</h1>

                <Button
                  text="Enviar vídeo"
                  onClick={() => setIsDialogOpen(false)}
                />
              </DialogContainer>
            ) : (
              <UploadContainer
                className="upload"
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
              >
                <input {...getInputProps()} />

                <img src={UploadIcon} alt="Upload" />

                <Button text="Selecionar arquivo" />
              </UploadContainer>
            )}
          </UploadSection>
        </Section>
      </UploadVideoContainer>
    </Fade>
  );
}

export default UploadVideo;
