import { toast } from 'react-toastify';
import {
  create,
  deleteContent,
  getAll,
  getContent,
  getESP,
  remove,
  update,
  updateContent,
  uploadContent
} from '../api';
import isAxiosError from '../../../helpers/isAxiosError';
import { ContentData, ESPData } from '../ts/esp.interface';

const useESP = () => {
  const getESPs = async () => {
    try {
      const esps = await getAll();

      return esps;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const createESP = async (values: ESPData) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('description', values.description);
    if (values.file) {
      formData.append('file', values.file);
    }
    formData.append('amount', values.amount.toString());
    formData.append('type', values.type);
    formData.append('download', values.download ? 'yes' : '');
    formData.append('certificate', values.certificate ? 'yes' : '');
    formData.append('tags', values.tags.toString());
    formData.append('formats', values.formats.toString());
    formData.append('category', '[]');
    formData.append('subCategory', '[]');

    try {
      const res = await create(formData);

      return res;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };
  const updateESP = async (id: string, values: ESPData) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('description', values.description);
    if (values.file) {
      formData.append('file', values.file);
    }
    formData.append('amount', values.amount.toString());
    formData.append('type', values.type);
    formData.append('download', values.download ? 'yes' : '');
    formData.append('certificate', values.certificate ? 'yes' : '');
    formData.append('tags', values.tags.toString());
    formData.append('formats', values.formats.toString());
    formData.append('category', '[]');
    formData.append('subCategory', '[]');

    try {
      const data = await update(id, formData);

      toast.success('Curso alterado com sucesso');
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const deleteESP = async (espId: string) => {
    try {
      await remove(espId);

      toast.success('Curso deletado com sucesso');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getESPDetails = async (espId: string) => {
    try {
      const data = await getESP(espId);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getESPContent = async (type: string, espId: string) => {
    try {
      const data = await getContent(type, espId);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const uploadESPContent = async (values: ContentData) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('file', values.file);
    if (values.thumb) {
      formData.append('thumb', values.thumb);
    }
    formData.append('format', values.format);
    formData.append('course_id', values.course_id);
    formData.append(
      'duration_millis',
      Math.round(values.duration_millis).toString()
    );
    formData.append('time_stop', values.time_stop.toString());
    formData.append('position', values.position.toString());

    try {
      await uploadContent(formData);
      toast.success('Nova aula adicionada');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const updateESPContent = async (contentId: string, values: ContentData) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('file', values.file);
    if (values.thumb) {
      formData.append('thumb', values.thumb);
    }
    formData.append(
      'duration_millis',
      Math.round(values.duration_millis).toString()
    );

    try {
      await updateContent(contentId, formData);
      toast.success('Aula atualizada');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const deleteESPContent = async (id: string) => {
    try {
      await deleteContent(id);

      toast.success('Conteúdo deletado');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    getESPs,
    createESP,
    deleteESP,
    updateESP,
    getESPDetails,
    getESPContent,
    uploadESPContent,
    updateESPContent,
    deleteESPContent
  };
};

export default useESP;
