import React, { useState, useEffect } from 'react';
import { Error } from 'opentok-react/types/opentok';
import { OTSession, preloadScript, OTSubscriber } from 'opentok-react';
import { Link, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import useLiveHook from 'http/lives/hooks/useLiveHook';
import LiveListData from 'http/lives/api/Response/LiveListData';
import RecordingData from 'http/lives/api/Response/RecordingData';

import Section from 'shared/components/Section';
import Button from 'shared/components/Button';
import { Fade } from '@material-ui/core';
import { app } from '../../../../firebase';
import LiveChat from '../../components/LiveChat';
import Publisher from '../../components/Publisher';
import useWatchLiveHook from '../../hooks/useWatchLiveHook';
import {
  LiveStreamContainer,
  LiveStreamSection,
  StreamViewContainer,
  LiveFeedback
} from './styles';

const apiKey = process.env.REACT_APP_APIKEY_OPENTOK as string;

const WatchLive = () => {
  const { sessionId, token, id } = useWatchLiveHook();
  const { getLiveDetails, startLiveRecording, closeLive, stopLiveRecording } =
    useLiveHook();
  const [state, setState] = useState({
    error: '',
    recording: false,
    stoppedRecording: false,
    connected: false
  });
  const [watching, setWatching] = useState(0);
  const [liveDetails, setLiveDetails] = useState<LiveListData>();
  const [recordingData, setRecordingData] = useState<RecordingData>();

  const getStreamDetails = async () => {
    const live = await getLiveDetails(id);
    setLiveDetails(live);
    return live;
  };

  const stopLive = async () => {
    if (liveDetails?.is_active) {
      stopRecording();
      const data = await closeLive(id);
      toast.success('A live foi encerrada');

      setLiveDetails(data);
    }
  };

  const leavePageClean = async () => {
    const data = await closeLive(id);
    stopLiveRecording(data?.archive_id as string, id);
  };

  const startRecording = async () => {
    if (!state.recording && liveDetails) {
      const data = await startLiveRecording(sessionId, liveDetails?.title);
      if (data) {
        setRecordingData(data);
        toast.success('A live está sendo gravada');
      }
    }
  };

  const stopRecording = async () => {
    if (liveDetails && recordingData) {
      const data = await stopLiveRecording(recordingData.id, id);
      setRecordingData(data);
      toast.warning('A Live parou de ser gravada');
    }
  };

  const sessionEvents = {
    sessionConnected: () => {
      setState((state) => ({ ...state, connected: true }));
    },
    sessionDisconnected: () => {
      setState((state) => ({ ...state, connected: false }));
    },
    archiveStarted: () => {
      setState((state) => ({ ...state, recording: true }));
    },
    archiveStopped: () => {
      setState((state) => ({
        ...state,
        recording: false,
        stoppedRecording: true
      }));
    }
  };

  const onError = (error: Error) => {
    setState({ ...state, error: `Failed to connect: ${error.name}` });
  };

  useEffect(() => {
    getStreamDetails();

    window.onbeforeunload = () => true;
    const ref = app.database().ref(`/rooms/${id}/online`);
    const subscribe = ref.child('/').on('value', (snapshot) => {
      setWatching(snapshot.numChildren());
    });

    return () => {
      leavePageClean();
      ref.off('value', subscribe);
    };
  }, [id]);

  useEffect(() => {
    if (state.connected) {
      startRecording();
    }
  }, [state.connected]);

  return (
    <Fade in>
      <LiveStreamContainer>
        <Prompt
          when
          message="Tem certeza que deseja sair? Caso a live esteja ativa ela será encerrada"
        />
        <Section title="Live">
          <LiveStreamSection>
            <StreamViewContainer>
              <OTSession
                apiKey={apiKey}
                sessionId={sessionId}
                token={token}
                eventHandlers={sessionEvents}
                onError={onError}
              >
                {state.error ? <div id="error">{state.error}</div> : null}
                {liveDetails?.is_active ? (
                  <>
                    <Publisher
                      streamId={id}
                      watching={watching}
                      isLive={state.connected}
                      isRecording={state.recording}
                      blockComments={liveDetails?.blockComments}
                      closeLive={stopLive}
                    />
                    <OTSubscriber />
                    <div className="stream-data">
                      <h3>{liveDetails?.title}</h3>

                      <br />

                      <div>
                        <Button size="sm" onClick={stopLive}>
                          Fechar Live
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <LiveFeedback>
                      <h1>Live encerrada...</h1>
                    </LiveFeedback>

                    <br />

                    <div style={{ float: 'right', marginBottom: '20px' }}>
                      <Link to="/lives">
                        <Button>Sair</Button>
                      </Link>
                    </div>
                  </>
                )}
              </OTSession>
            </StreamViewContainer>

            {!liveDetails?.blockComments && <LiveChat streamId={id} />}
          </LiveStreamSection>
        </Section>
      </LiveStreamContainer>
    </Fade>
  );
};

export default preloadScript(WatchLive);
