import * as yup from 'yup';

const EventSchema = yup.object({
  start: yup.date().required('Selecione a data de início'),
  end: yup.date().required('Selecione a data de término'),
  title: yup.string().required('Insira um título'),
  message: yup.string().required('Insira uma mensagem')
});

export default EventSchema;
