import {
  Appointments,
  DayView,
  MonthView,
  Scheduler
} from '@devexpress/dx-react-scheduler-material-ui';
import { Paper } from '@material-ui/core';
import Colors from 'shared/constants/Colors';
import styled from 'styled-components';

export const ScheduleContainer = styled.div``;

export const ScheduleSection = styled.div`
  display: -webkit-flex;
  background: white;
  border-radius: 10px;

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

export const CalendarContainer = styled(Paper)`
  padding-right: 20px;
`;

export const CalendarDayScaleCell = styled(MonthView.TimeTableCell)`
  div.Cell-text-32,
  div.Cell-text-42,
  div.Cell-text-45,
  div.Cell-text-41,
  div.Cell-text-61 {
    text-align: left;
    font: 700 12px Inter;
  }

  border: none;
`;

export const DayScheduleContainer = styled(Paper)`
  h1 {
    font: 700 16px Inter;
    height: 64px;
    display: grid;
    align-items: center;
    margin-left: 30px;
  }
`;

export const DayCheduler = styled(Scheduler)`
  div.DayScaleEmptyCell-emptyCell-42 {
    width: 10px;
    background: red;
  }

  .makeStyles-container-8 {
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: ${Colors.primary};
    }

    ::-webkit-scrollbar-thumb {
      background: #6b66c4;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #5551a8;
    }
  }
`;

export const TimeScaleDayLabel = styled(DayView.TimeScaleLabel)`
  .Label-text-59,
  .Label-text-95,
  .Label-text-75,
  .Label-text-79,
  .Label-text-66 {
    font: 700 12px Inter !important;
  }
`;

export const Appointment = styled(Appointments.Appointment)`
  background-color: ${Colors.primary} !important;

  .VerticalAppointment-title-277,
  .HorizontalAppointment-title-147 {
    font: 700 12px Inter !important;
    padding: 5px 0;
  }
  align-items: bottom !important;
`;
