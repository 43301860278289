import styled from 'styled-components';
import Colors from '../../../../shared/constants/Colors';

export const LiveUpdateContainer = styled.div``;

export const LiveUpdateSection = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: -webkit-flex;
  gap: 40px;

  h3 {
    font: 600 14px Inter;
    text-transform: uppercase;
    color: #555;
    margin-bottom: 20px;
  }

  form {
    flex: 0.7;

    .FormControl {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 699px) {
    flex-direction: column-reverse;
  }
`;

export const PreviewSection = styled.div<{ error: boolean }>`
  flex: 0.3;

  div.thumbnail {
    width: 100%;
    min-height: 100px;
    height: max-content;
    cursor: pointer;
    border-radius: 10px;
    transform: scale(1.05);
    overflow: hidden;
    display: grid;
    place-items: center;

    img {
      width: 100%;
      size: contain;
    }

    span {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      border: 1px dashed ${(p) => (p.error ? 'red' : '#555')};
      border-radius: 10px;

      font: 600 14px Inter;
      color: ${(p) => (p.error ? 'red' : '#555')};
    }
  }

  footer {
    padding: 10px 20px;
    background: ${Colors.background};

    span {
      font: 300 12px Inter;
      color: #444;
    }

    h2 {
      font: 500 14px Inter;
      color: #444;
    }

    p {
      font-size: 12px;
      color: #444;
    }
  }
`;
