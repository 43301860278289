import styled from 'styled-components';

export const DetailsContainer = styled.div``;

export const DetailsSection = styled.div`
  background: white;
  padding: 30px 20px;

  span {
    font: 700 14px Roboto;
    color: #666;
  }
`;

export const ModalBody = styled.div`
  width: 500px;

  padding: 5px;

  span {
    font-weight: bold;
  }
`;

export const ModalHeader = styled.div`
  padding: 5px;

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  margin-bottom: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 15px;
`;
