import React from 'react';
import { Fade } from '@material-ui/core';
import { NotificationProps } from 'http/notification/ts/notification.interface';
import Section from '../../../../shared/components/Section';
import NotificationForm from '../../components/NotificationForm';
import {
  CreateNotificationContainer,
  CreateNotificationSection
} from './styles';

import useNotification from '../../hooks/useNotification';

function CreateNotification() {
  const { newNotification } = useNotification();

  const handleCreateNotification = async (values: NotificationProps) => {
    await newNotification(values);
  };

  return (
    <Fade in>
      <CreateNotificationContainer>
        <Section title="Nova notificação" rollbackPath="/notification">
          <CreateNotificationSection>
            <NotificationForm
              callback={(values) => handleCreateNotification(values)}
            />
          </CreateNotificationSection>
        </Section>
      </CreateNotificationContainer>
    </Fade>
  );
}

export default CreateNotification;
