import { ReactNode } from 'react';

import { AuthProvider } from './AuthContext';
import { UploadProvider } from './UploadContext';

import { ScheduleProvider } from '../../http/schedule/context';
import { CategoryProvider } from '../../http/categories/context';
import { MeditationProvider } from '../../http/meditations/context';

function ContextsProvider({ children }: { children: ReactNode }) {
  return (
    <AuthProvider>
      <CategoryProvider>
        <MeditationProvider>
          <ScheduleProvider>
            <UploadProvider>{children}</UploadProvider>
          </ScheduleProvider>
        </MeditationProvider>
      </CategoryProvider>
    </AuthProvider>
  );
}

export default ContextsProvider;
