import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ESP } from 'http/ESP/ts/esp.interface';

import { Box, Fade } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TuneOutlined } from '@material-ui/icons';

import useESP from '../../hooks/useESP';
import Button from '../../../../shared/components/Button';
import Section from '../../../../shared/components/Section';
import IconButton from '../../../../shared/components/IconButton';
import CourseItem from '../../components/CourseItem';
import CourseFilterDrawer from '../../components/CourseFilterDrawer';
import { CoursesListContainer, CoursesListSection } from './styles';

function CoursesList() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { getESPs } = useESP();
  const [filteredESPs, setFilteredESPs] = useState<ESP[] | undefined>();
  const [allESPs, setAllESPs] = useState<ESP[] | undefined>();

  const handleGetAllESPs = async () => {
    const data = await getESPs();
    setAllESPs(data);
    setFilteredESPs(data);
  };

  const handleFilterESPs = (values: { name: string; date: Date }) => {
    const filter = allESPs?.filter((esp) => {
      return (
        esp.name.includes(values.name) &&
        values.date?.toLocaleDateString() ===
          new Date(esp.created_at).toLocaleDateString()
      );
    });

    setFilteredESPs(filter);
  };

  const handleCleanFilter = () => {
    setFilteredESPs(allESPs);
  };

  useEffect(() => {
    handleGetAllESPs();
  }, []);

  return (
    <Fade in>
      <CoursesListContainer>
        <Section
          title="Espiritualidade no Sucesso Profissional"
          options={[
            <Link to="/espiritualidade/new">
              <Button size="sm">Novo Curso</Button>
            </Link>,
            <IconButton onClick={() => setIsFilterOpen(true)}>
              <TuneOutlined />
            </IconButton>
          ]}
        >
          <CoursesListSection>
            {filteredESPs
              ? filteredESPs?.map((item, idx) => {
                  return (
                    <CourseItem
                      id={item.id}
                      title={item.name}
                      description={item.description}
                    />
                  );
                })
              : Array.from(new Array(4)).map(() => {
                  return (
                    <Box width={300}>
                      <Skeleton variant="rect" width={300} height={180} />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  );
                })}
          </CoursesListSection>
          {filteredESPs?.length === 0 && (
            <div className="not-found-message">
              <h3>
                Nenhuma 'Espiritualidade no Sucesso Profissional' encontrado
              </h3>
              <span>
                Tente procurar utilizando outros filtros ou crie uma agora mesmo
              </span>
            </div>
          )}
        </Section>

        <CourseFilterDrawer
          open={isFilterOpen}
          toggleOpen={() => setIsFilterOpen((state) => !state)}
          handleFilter={(values) => handleFilterESPs(values)}
          cleanFilters={handleCleanFilter}
        />
      </CoursesListContainer>
    </Fade>
  );
}

export default CoursesList;
