import * as yup from 'yup';

const CourseSchema = yup.object({
  name: yup.string().required('Insira um nome'),
  description: yup.string().required('Insira uma descrição'),
  type: yup.string().required('Selecione a categoria'),
  file: yup
    .mixed()
    .required('Selecione uma capa')
    .test('fileFormat', 'Image only', (value) => {
      return (
        value &&
        (['image/png', 'image/jpeg'].includes(value.type) ||
          typeof value === 'string')
      );
    }),
  amount: yup.number().required('Insira um preço'),
  download: yup.number().required('Escolha uma opção'),
  certificate: yup.number().required('Escolha uma opção'),
  tags: yup.string(),
  formats: yup.string()
});

export default CourseSchema;
