import * as yup from 'yup';

const ESPContentSchema = yup.object({
  title: yup.string().required('Insira um nome'),
  description: yup.string().required('Insira uma descrição'),
  format: yup.string().required('Selecione a categoria'),
  thumb: yup
    .mixed()
    .required('Selecione uma capa')
    .test('fileFormat', 'Insira uma imagem válida', (value) => {
      return (
        value &&
        (['image/png', 'image/jpeg'].includes(value.type) ||
          typeof value === 'string')
      );
    }),
  file: yup
    .mixed()
    .required('Selecione uma capa')
    .test('fileFormat', 'Insira um arquivo válido', (value) => {
      return (
        value &&
        ([
          'image/png',
          'image/jpeg',
          'video/mp4',
          'video/ogg',
          'audio/ogg',
          'audio/mpeg',
          'application/pdf'
        ].includes(value.type) ||
          typeof value === 'string')
      );
    }),
  duration_millis: yup.number().required(),
  time_stop: yup.number(),
  position: yup.number(),
  course_id: yup.string().required()
});

export default ESPContentSchema;
