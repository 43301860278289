import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  Collapse,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

import { ESP, ESPData } from 'http/ESP/ts/esp.interface';
import useSort from 'shared/hooks/useSort';
import useESP from 'http/ESP/hooks/useESP';

import Button from 'shared/components/Button';
import Section from 'shared/components/Section';
import Table from 'shared/components/Table';
import TableHead from 'shared/components/TableHead';

import CourseForm from '../../components/CourseForm';

import { ManageCoursesContainer, ManageCoursesSection } from './styles';

function ManageCourses() {
  const { getESPs, updateESP } = useESP();
  const [ESPs, setESPs] = useState<ESP[] | undefined>();
  const [expanded, setExpanded] = useState<number | null>();

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const handleGetESPs = async () => {
    const data = await getESPs();
    setESPs(data);
  };

  const handleUpdateESP = async (id: string, values: ESPData) => {
    await updateESP(id, values);
    handleGetESPs();
  };

  useEffect(() => {
    handleGetESPs();
  }, []);

  const headCells = [
    { name: 'ID', align: 'left', id: 'id', sorting: true },
    { name: 'Categoria', align: 'left', id: 'type', sorting: true },
    { name: 'Imagem', align: 'left', id: 'thumb', sorting: false },
    { name: 'Nome', align: 'left', id: 'name', sorting: true },
    { name: 'Valor', align: 'center', id: 'amount', sorting: true },
    { name: 'Certificado', align: 'center', id: 'certificate', sorting: false },
    { name: 'Download', align: 'center', id: 'download', sorting: false },
    {
      name: 'Ações',
      align: 'center',
      id: 'actions',
      sorting: false
    }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<ESP>(ESPs as ESP[], {
    key: 'name' as keyof ESP,
    direction: 'desc'
  });

  return (
    <ManageCoursesContainer>
      <div>
        <Section title="Gerenciar Espiritualidade">
          <ManageCoursesSection>
            <Table>
              <TableHead
                headCells={headCells}
                sortConfig={sortConfig}
                sort={(id) => requestSort(id as keyof ESP)}
              />
              <TableBody>
                {ESPs ? (
                  sortedItems.map((item, idx) => {
                    return (
                      <>
                        <TableRow key={item.id}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.id.replace(/-/g, '_')}
                          </TableCell>
                          <TableCell>{item.type.toLowerCase()}</TableCell>
                          <TableCell>
                            <img
                              alt={item.name}
                              src={item.thumb}
                              height="60px"
                              width="60px"
                            />
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            R$ {item.amount.toFixed(2)}
                          </TableCell>
                          <TableCell padding="checkbox" align="center">
                            <Checkbox
                              color="primary"
                              checked={item.certificate}
                            />
                          </TableCell>
                          <TableCell padding="checkbox" align="center">
                            <Checkbox color="primary" checked={item.download} />
                          </TableCell>
                          <TableCell className="actions">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                text="Editar"
                                size="sm"
                                onClick={() => handleOpenEditForm(idx)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={9}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse in={expanded === idx} unmountOnExit>
                              <div className="editHeader">
                                <h2>Editar</h2>
                                <CloseOutlined
                                  onClick={() => handleOpenEditForm(idx)}
                                />
                              </div>
                              <CourseForm
                                callback={(values) =>
                                  handleUpdateESP(item.id, values)
                                }
                                initialValues={item}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {ESPs?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <h1>
                        Nenhuma 'Espitualidade no sucesso profissional'
                        encontrada
                      </h1>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ManageCoursesSection>
        </Section>
      </div>
    </ManageCoursesContainer>
  );
}

export default ManageCourses;
