const livesRouter = [
  { name: 'Lives', path: '/lives' },
  { name: 'LiveSetup', path: '/live-setup' },
  { name: 'VideoSetup', path: '/video-setup' },
  { name: 'UploadVideo', path: '/upload-video' },
  { name: 'LiveUpdate', path: '/update/:id' },
  {
    name: 'WatchLive',
    path: '/watch-live/:sessionId/:token/:id/:isRecording'
  }
];

export default livesRouter;
