import api, { baseURL } from 'services/api';
import UserSession from '../ts/Response/UserSession';

export const startSession = async ({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<UserSession> => {
  return await api.post(
    `${baseURL}/session`,
    {
      email,
      password
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }
  );
};
