import api from '../../../services/api';
import Revenue from './ts/interface/Revenue';

interface RevenueFilters {
  startDate?: string;
  endDate?: string;
  status?: string;
  type?: 'COURSE' | 'SUBSCRIPTION';
}

export const getPayments = async (
  page: number,
  filters?: RevenueFilters
): Promise<{ data: Revenue[]; totalPages: number }> =>
  await api.get(`/payments?page=${page}`, { params: { ...filters } });

export const getBillingReport = async (): Promise<{
  subscriptions: number;
  totalBilling: number;
  totalCancelledBilling: number;
}> => await api.get('/payments/reports/billing');

export const getAllPayments = async (): Promise<{
  data: Revenue[];
  count: number;
}> => await api.get('/payments/all/export-xlsx');
