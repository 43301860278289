import * as Yup from 'yup';

export const CreatePlanSchema = Yup.object({
  amount: Yup.number().required('Insira um valor'),
  installments: Yup.number()
    .required('Insira um número de parcelas')
    .min(1, 'Número mínimo de parcelas excedido')
    .max(12, 'Número máximo de parcelas excedido'),
  period: Yup.string().required('Selecione o tipo de assinatura'),
  title: Yup.string().required('Digite um título para o plano'),
  description: Yup.string().required('Insira uma descrição para o plano'),
  permissions: Yup.array().required('Selecione uma permissão para seu plano')
});

export const UpdatePlanSchema = Yup.object({
  amount: Yup.number().required('Insira um valor'),
  installments: Yup.number()
    .required('Insira um número de parcelas')
    .min(1, 'Número mínimo de parcelas excedido')
    .max(12, 'Número máximo de parcelas excedido'),
  period: Yup.string().required('Selecione o tipo de assinatura'),
  title: Yup.string().required('Digite um título para o plano'),
  description: Yup.string().required('Insira uma descrição para o plano')
});
