import { createContext, useContext, useState } from 'react';
import LiveStreamCategory from '../../lives/api/Response/LiveStreamCategory';

interface CategoryContextProps {
  categories: LiveStreamCategory[];
  setCategories: (categories: LiveStreamCategory[]) => void;
}

const categoryContext = createContext({} as CategoryContextProps);

const CategoryProvider = ({ children }: { children: JSX.Element }) => {
  const [categories, setCategories] = useState<LiveStreamCategory[]>([]);
  const { Provider } = categoryContext;
  return <Provider value={{ categories, setCategories }}>{children}</Provider>;
};

const useCategoryContext = () => {
  const context = useContext(categoryContext);
  return context;
};

export { CategoryProvider, useCategoryContext };
