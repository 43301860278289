import { createContext, ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import isAxiosError from '../../helpers/isAxiosError';
import { uploadStream } from '../../http/lives/api';

interface ContextProps {
  video?: File | undefined;
  saveVideo: (file: File) => void;
  isIndicatorOpen: boolean;
  setIsIndicatorOpen: (value: boolean) => void;
  uploadVideo: (
    title: string,
    category: string,
    file: File | undefined,
    description: string,
    thumb: File | undefined,
    onlyFor24Hrs: boolean,
    availableAt: Date
  ) => void;
}

const VideoUploadContext = createContext<ContextProps>({
  saveVideo: () => {},
  uploadVideo: () => {},
  isIndicatorOpen: false,
  setIsIndicatorOpen: () => {}
});

function UploadProvider({ children }: { children: ReactNode }) {
  const [video, setVideo] = useState<File>();
  const [isIndicatorOpen, setIsIndicatorOpen] = useState<boolean>(false);

  const saveVideo = (file: File | undefined) => {
    setVideo(file);
  };

  const uploadVideo = async (
    title: string,
    category: string,
    file: File | undefined,
    description: string,
    thumb: File | undefined,
    onlyFor24Hrs: boolean,
    availableAt: Date
  ) => {
    setIsIndicatorOpen(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('onlyFor24Hrs', String(onlyFor24Hrs));
    formData.append('categories', JSON.stringify([category]));
    formData.append('availableAt', availableAt.toISOString());
    if (thumb) {
      formData.append('thumb', thumb);
    }
    if (file) {
      formData.append('file', file);
    }

    try {
      await uploadStream(formData);
      toast.success('Vídeo carregado com sucesso');
      return;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    } finally {
      setIsIndicatorOpen(false);
    }
  };

  return (
    <VideoUploadContext.Provider
      value={{
        video,
        saveVideo,
        uploadVideo,
        isIndicatorOpen,
        setIsIndicatorOpen
      }}
    >
      {children}
    </VideoUploadContext.Provider>
  );
}

export { UploadProvider, VideoUploadContext };
