import { createContext, useContext, useState } from 'react';
import Course from '../api/ts/interface/Meditation';
import CourseContent from '../api/ts/interface/CourseContent';
import MeditationCategory from '../api/ts/interface/MeditationCategory';

interface MedsitationProps {
  contentType: string;
  setType: (contentType: string) => void;
  meditations: Course[];
  categories: MeditationCategory[];
  setCategories: (categories: MeditationCategory[]) => void;
  setMeditations: (meditations: Course[]) => void;
  content: CourseContent[];
  setContent: (content: CourseContent[]) => void;
}

const meditationContext = createContext({} as MedsitationProps);

const MeditationProvider = ({ children }: { children: JSX.Element }) => {
  const [meditations, setMeditations] = useState<Course[]>([]);
  const [content, setContent] = useState<CourseContent[]>([]);
  const [categories, setCategories] = useState<MeditationCategory[]>([]);
  const [contentType, setType] = useState<string>('');

  const { Provider } = meditationContext;
  return (
    <Provider
      value={{
        meditations,
        contentType,
        setType,
        setMeditations,
        content,
        setContent,
        categories,
        setCategories
      }}
    >
      {children}
    </Provider>
  );
};

const useMeditationContext = () => {
  const context = useContext(meditationContext);
  return context;
};

export { MeditationProvider, useMeditationContext };
