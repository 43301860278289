import React, { useRef } from 'react';
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';

import SongSchema from '../validation/SongSchema';
import { CreateSong, Song } from '../ts/song.interface';

import Button from '../../../shared/components/Button';

function PlaylistForm({
  callback,
  initialValues
}: {
  callback: (values: CreateSong) => void;
  initialValues?: Song;
}) {
  const songInputRef = useRef<HTMLInputElement>(null);
  const thumbInputRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const selectSongFile = () => {
    songInputRef?.current?.click();
  };

  const selectThumbFile = () => {
    thumbInputRef?.current?.click();
  };

  const handleSongUpload = (file: File | undefined) => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      audioRef.current?.setAttribute('src', fileUrl);
    }
  };

  return (
    <PlaylistFormContainer>
      <Formik
        initialValues={{
          title: initialValues?.title || '',
          song: initialValues?.url || new File([], ''),
          thumb: initialValues?.background_url,
          duration_millis: initialValues?.duration_millis || 0,
          description: initialValues?.description
        }}
        onSubmit={(values) => callback(values)}
        validationSchema={SongSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          isSubmitting,
          errors,
          touched
        }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Nome da Música/Áudio"
                    variant="outlined"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    helperText={touched.title && errors.title}
                    error={!!touched.title && !!errors.title}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    onClick={selectSongFile}
                    label="Música/Áudio"
                    variant="outlined"
                    value={
                      typeof values.song === 'string'
                        ? values.song
                        : values.song?.name
                    }
                    helperText={touched.song && errors.song}
                    error={!!touched.song && !!errors.song}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    ref={songInputRef}
                    accept="audio/*"
                    onChange={(event) => {
                      handleSongUpload(event?.target?.files?.[0]);
                      setFieldValue('song', event?.target?.files?.[0]);
                    }}
                    name="song"
                    hidden
                  />

                  <audio
                    ref={audioRef}
                    hidden
                    onLoadedMetadata={(e) =>
                      setFieldValue(
                        'duration_millis',
                        e.currentTarget.duration * 1000
                      )
                    }
                  >
                    <track kind="captions" />
                  </audio>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    onClick={selectThumbFile}
                    label="Capa"
                    variant="outlined"
                    value={
                      values.thumb
                        ? typeof values.thumb === 'string'
                          ? values.thumb
                          : 'Arquivo carregado'
                        : ''
                    }
                    helperText={touched.thumb && errors.thumb}
                    error={!!touched.thumb && !!errors.thumb}
                    InputProps={{
                      endAdornment: values.thumb ? (
                        <img
                          className="inputThumbIcon"
                          src={
                            typeof values.thumb === 'object'
                              ? URL.createObjectURL(values.thumb)
                              : values.thumb
                          }
                          alt="Thumb"
                        />
                      ) : (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={thumbInputRef}
                    onChange={(event) =>
                      setFieldValue('thumb', event?.target?.files?.[0])
                    }
                    name="thumb"
                    hidden
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Descrição breve"
                    variant="outlined"
                    multiline
                    minRows={4}
                    helperText={touched.description && errors.description}
                    error={!!touched.description && !!errors.description}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <div className="buttonWrapper">
                <Button size="lg" type="submit">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </PlaylistFormContainer>
  );
}

export default PlaylistForm;

const PlaylistFormContainer = styled.div`
  padding: 20px 0 40px 0;

  .buttonWrapper {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
`;
