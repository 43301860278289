import { Paper, SvgIconTypeMap, Tooltip } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import styled from 'styled-components';

import Colors from '../../../../shared/constants/Colors';

interface InformationItemProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  thisMoment: number;
  last24hours: number;
  thisWeek: number;
  title: string;
}

function InformationItem({
  Icon,
  thisMoment,
  last24hours,
  thisWeek,
  title
}: InformationItemProps) {
  return (
    <InformationItemContainer elevation={3}>
      <Tooltip title={title}>
        <Icon />
      </Tooltip>
      <div>
        <p>
          Nesse momento: <span>{thisMoment}</span>
        </p>
        <p>
          Últimas 24 hrs: <span>{last24hours}</span>
        </p>
        <p>
          Nessa semana: <span>{thisWeek}</span>
        </p>
      </div>
    </InformationItemContainer>
  );
}

export default InformationItem;

const InformationItemContainer = styled(Paper)`
  width: 100%;
  padding: 20px;
  border-top: 4px solid ${Colors.primary};
  display: -webkit-flex;

  div {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .MuiSvgIcon-root {
    width: 25px;
    fill: ${Colors.primary};
    margin-right: 35px;
  }

  p {
    font: 500 14px Inter;
    color: ${Colors.primary};

    span {
      font: 400 14px Inter;
      color: #333;
      margin-left: 8px;
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;

    .MuiSvgIcon-root {
      width: 35px;
      margin-bottom: 10px;
    }

    div {
      flex-direction: column;

      p {
        width: 100%;
        padding: 15px 0;
        border-top: 2px solid whitesmoke;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
