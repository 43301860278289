import { isAuthenticated } from 'helpers/handleTokenSession';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps
} from 'react-router-dom';

import Home from 'http/home/pages/main';
import Login from 'http/authentication/pages/login';

import liveRoutes from '../http/lives/routes';
import managementRoutes from '../http/management/routes';
import categoriesRoutes from '../http/categories/routes';
import notificationRoutes from '../http/notification/routes';
import scheduleRoutes from '../http/schedule/routes';
import coursesRoutes from '../http/courses/routes';
import meditationsRoutes from '../http/meditations/routes';
import playlistRoutes from '../http/playlist/routes';
import ESPRoutes from '../http/ESP/routes';
import planRoutes from '../http/plans/routes';
import userRoutes from '../http/users/routes';
import revenueRoutes from '../http/revenues/routes';

const routes = [
  ...liveRoutes,
  ...managementRoutes,
  ...categoriesRoutes,
  ...notificationRoutes,
  ...scheduleRoutes,
  ...coursesRoutes,
  ...meditationsRoutes,
  ...playlistRoutes,
  ...ESPRoutes,
  ...planRoutes,
  ...userRoutes,
  ...revenueRoutes
];

const PrivateRoute = ({
  component: Component,
  key,
  path
}: {
  key: string;
  path: string;
  component: React.ComponentType<RouteComponentProps>;
}) => (
  <Route
    key={key}
    path={path}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        {routes.map(({ name, path }) => (
          <PrivateRoute key={name} path={path} component={Home} />
        ))}
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </Router>
  );
};

export default Routes;
