import isAxiosError from 'helpers/isAxiosError';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';

export default interface User {
  id: string;
  name: string;
  avatar: string;
  birthdate: string;
  email: string;
  role: number;
  created_at: Date;
  update_at: Date;
}

const AuthContext = createContext<{
  user: User | null;
  saveUser: (user: User | null) => void;
  updateAvatar: (file: File) => unknown;
  updateLocalUser: () => void;
}>({
  user: null,
  saveUser: () => {},
  updateAvatar: () => {},
  updateLocalUser: () => {}
});

function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('@user');

    if (user) {
      return JSON.parse(user);
    }
  });

  const saveUser = useCallback((user: User | null) => {
    localStorage.setItem('@user', JSON.stringify(user));
    setUser(user);
  }, []);

  const updateLocalUser = useCallback(async () => {
    try {
      const res: User = await api.get(`/user/${user.id}`);

      saveUser(res);
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  }, []);

  const updateAvatar = useCallback(async (file: File) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const res = await api.patch('/user/avatar', formData);
      toast.success('Foto de perfil alterada');
      return res;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, saveUser, updateAvatar, updateLocalUser }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useUser = () => {
  const context = useContext(AuthContext);

  return context;
};

export { AuthProvider, useUser };
