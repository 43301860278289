import React from 'react';
import { ToastContainer } from 'react-toastify';
import ContextsProvider from 'shared/contexts';
import UploadIndicator from 'shared/components/UploadIndicator';
import Routes from './routes';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ContextsProvider>
      <Routes />
      <ToastContainer />
      <UploadIndicator />
    </ContextsProvider>
  );
}

export default App;
