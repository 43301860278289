import api from '../../../services/api';
import { CreatePlan, Plan, UpdatePlan } from '../ts/plan.interface';
import Permission from '../ts/permission.interface';

export const getPlans = async (): Promise<Plan[]> => await api.get('/plans');

export const updatePlan = async (
  planId: string,
  planData: UpdatePlan
): Promise<string> => await api.put(`/plans/${planId}`, planData);

export const createPlan = async (planData: CreatePlan): Promise<Plan> =>
  await api.post('/plans', planData);

export const getPermissions = async (): Promise<Permission[]> =>
  await api.get('/plans/permissions');
