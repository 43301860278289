import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { Drawer, FormControl, TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Button from '../../../shared/components/Button';

interface CourseFilterDrawerProps {
  open: boolean;
  toggleOpen: () => void;
  cleanFilters: () => void;
  handleFilter: (values: { name: string; date: Date }) => void;
}

function CourseFilterDrawer({
  open,
  toggleOpen,
  handleFilter,
  cleanFilters
}: CourseFilterDrawerProps) {
  return (
    <Drawer open={open} anchor="right" onClose={toggleOpen}>
      <FilterContainer>
        <div className="title">
          <h1>Filtros</h1>
          <span onClick={toggleOpen}>X</span>
        </div>
        <p>
          Procure pelo nome e/ou data do curso. Você pode limpar os filtros
          clicando{' '}
          <a href="#" onClick={cleanFilters} style={{ color: 'rebeccapurple' }}>
            aqui
          </a>
        </p>

        <Formik
          initialValues={{ date: new Date(), name: '' }}
          onSubmit={(values) => handleFilter(values)}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <FormControl fullWidth>
                <TextField
                  label="Nome"
                  placeholder="Ex: Meditação #56 - Aulas..."
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormControl>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl variant="outlined" fullWidth>
                  <KeyboardDatePicker
                    inputVariant="outlined"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    name="date"
                    value={values.date}
                    onChange={(date) => setFieldValue('date', date)}
                    id="date-picker"
                    label="Data"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>

              <Button text="Filtrar" size="full" type="submit" />
            </Form>
          )}
        </Formik>
      </FilterContainer>
    </Drawer>
  );
}

export default CourseFilterDrawer;

const FilterContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 40px 20px;

  form {
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  .title {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    font: 600 16px Inter;
    padding-bottom: 20px;

    span {
      cursor: pointer;
    }
  }

  p {
    font: 400 14px Roboto;
    padding-bottom: 30px;
  }
`;
