import styled from 'styled-components';

export const RevenuesContainer = styled.div``;

export const RevenuesSection = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  gap: 20px;
`;

export const OverviewItems = styled.div`
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 15px 40px;
  border-radius: 10px;
  border: 1px solid whitesmoke;
  width: 100%;

  p {
    font: 400 14px Roboto;

    span {
      font: 600 15px Inter;
    }
    .bad {
      color: red;
    }
    .good {
      color: green;
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
  }
`;

export const PagenationContainer = styled.div`
  width: 100%;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
`;
