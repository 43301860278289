import React from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import {
  CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import Button from '../../../shared/components/Button';
import Filter from '../ts/filter.interface';

type DrawerProps = {
  open: boolean;
  toggleOpen: () => void;
  callback: (values: Filter) => void;
  clean: () => void;
};

function FilterDrawer({ open, toggleOpen, callback, clean }: DrawerProps) {
  return (
    <Drawer open={open} anchor="right" onClose={toggleOpen}>
      <FilterContainer>
        <div className="title">
          <h1>Filtros</h1>
          <span onClick={toggleOpen}>X</span>
        </div>
        <p>
          Procure pelo usuário escolhendo os filtros adequados. Você pode limpar
          os filtros clicando{' '}
          <a href="#" style={{ color: 'rebeccapurple' }} onClick={clean}>
            aqui
          </a>
        </p>

        <Formik
          initialValues={{
            startDate: new Date(),
            endDate: new Date()
          }}
          onSubmit={(values: Filter) => callback(values)}
        >
          {({ values, setFieldValue, isSubmitting, handleChange }) => (
            <Form>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="subscriptionType-label">
                  Serviço/Produto
                </InputLabel>
                <Select
                  labelId="subscriptionType-label"
                  id="demo-simple-select-outlined"
                  label="Serviço/Produto"
                  defaultValue=""
                  value={values.transactionType}
                  onChange={handleChange}
                  name="transactionType"
                >
                  <MenuItem value="INDEPENDENT">Curso</MenuItem>
                  <MenuItem value="RECURRENT">Assinatura</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="subscriptionType-label">Status</InputLabel>
                <Select
                  labelId="subscriptionType-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  defaultValue=""
                  value={values.transactionStatus}
                  onChange={handleChange}
                  name="transactionStatus"
                >
                  <MenuItem value="CANCELLED">Cancelado</MenuItem>
                  <MenuItem value="PENDING">Pendente</MenuItem>
                  <MenuItem value="REFUNDED">Reembolsado</MenuItem>
                  <MenuItem value="PROCESSED">Processado</MenuItem>
                  <MenuItem value="REJECTED">Rejeitado</MenuItem>
                  <MenuItem value="CHARGED_BACK">Chargeback (Estorno)</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    value={values.startDate || undefined}
                    onChange={(date) => setFieldValue('startDate', date)}
                    id="date-picker"
                    label="Data"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>

              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="endDate"
                    value={values.endDate}
                    onChange={(date) => setFieldValue('endDate', date)}
                    id="date-picker"
                    label="Data"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <Button size="full">
                {isSubmitting ? (
                  <CircularProgress color="inherit" />
                ) : (
                  'Filtrar'
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </FilterContainer>
    </Drawer>
  );
}

export default FilterDrawer;

export const FilterContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 40px 20px;

  .title {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    font: 600 16px Inter;
    padding-bottom: 20px;

    span {
      cursor: pointer;
    }
  }

  p {
    font: 400 14px Roboto;
    padding-bottom: 30px;
  }

  form {
    width: 100%;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;
