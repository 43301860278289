import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import {
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import Button from '../../../../shared/components/Button';
import Section from '../../../../shared/components/Section';

import {
  LiveUpdateContainer,
  LiveUpdateSection,
  PreviewSection
} from './styles';

import useLiveHook from '../../hooks/useLiveHook';
import useCategories from '../../hooks/useCategories';

import LiveListData from '../../api/Response/LiveListData';
import { LiveSetupSchema } from '../../validation/LiveSetupSchema';

function LiveUpdate() {
  const [liveDetails, setLiveDetails] = useState<LiveListData>();
  const { getLiveDetails, updateLive } = useLiveHook();
  const { categories } = useCategories();

  const params = useParams();
  const { id } = params as { id: string };

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputFileRef?.current?.click();
  };

  const getLive = async () => {
    setLiveDetails(await getLiveDetails(id));
  };

  useEffect(() => {
    if (id) {
      getLive();
    }
  }, [id]);

  return (
    <Fade in>
      <LiveUpdateContainer>
        <Section title="Editar live" rollbackPath="/lives">
          <LiveUpdateSection>
            <Formik
              initialValues={{
                title: liveDetails?.title || '',
                description: liveDetails?.description || '',
                category: liveDetails?.categories[0]?.id || '',
                blockComments: liveDetails?.blockComments || false,
                file: liveDetails?.thumb || undefined,
                onlyFor24Hrs: liveDetails?.onlyFor24Hrs || false
              }}
              validationSchema={LiveSetupSchema}
              enableReinitialize
              onSubmit={({
                title,
                category,
                description,
                file,
                blockComments,
                onlyFor24Hrs
              }) => {
                updateLive({
                  title,
                  category,
                  file,
                  id,
                  description,
                  blockComments,
                  onlyFor24Hrs
                });
              }}
            >
              {({
                values,
                handleChange,
                setFieldValue,
                errors,
                touched,
                isSubmitting
              }) => (
                <>
                  <Form>
                    <h3>Detalhes</h3>

                    <FormControl className="FormControl" fullWidth>
                      <TextField
                        variant="outlined"
                        name="title"
                        label="Título"
                        value={values.title}
                        placeholder="Insira o título da sua live"
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                        error={!!touched.title && !!errors.title}
                      />
                    </FormControl>

                    <FormControl className="FormControl" fullWidth>
                      <TextField
                        variant="outlined"
                        name="description"
                        label="Descrição"
                        multiline
                        minRows={3}
                        value={values.description}
                        placeholder="Fale um pouco sobre o conteúdo de sua live"
                        onChange={handleChange}
                        helperText={touched.description && errors.description}
                        error={!!touched.description && !!errors.description}
                      />
                    </FormControl>

                    <FormControl
                      className="FormControl"
                      variant="outlined"
                      fullWidth
                    >
                      <InputLabel id="category-select-label">
                        Categoria
                      </InputLabel>
                      <Select
                        labelId="category-select-label"
                        id="category-select"
                        name="category"
                        value={values.category}
                        onChange={handleChange}
                        label="Categoria"
                        error={!!touched.category && !!errors.category}
                      >
                        {categories?.map((category) => {
                          return (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="onlyFor24Hrs"
                          value={values.onlyFor24Hrs}
                          onChange={handleChange}
                        />
                      }
                      label="Disponibilizar por 24hrs"
                    />

                    <br />
                    <br />

                    {isSubmitting ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Button size="md" type="submit">
                        Atualizar Informações
                      </Button>
                    )}
                  </Form>

                  <PreviewSection error={!!touched.file && !!errors.file}>
                    <input
                      type="file"
                      onChange={(event) => {
                        setFieldValue('file', event?.target?.files?.[0]);
                      }}
                      ref={inputFileRef}
                      name="file"
                      accept="image/*"
                      hidden
                    />

                    <div className="thumbnail" onClick={handleSelectFile}>
                      {values.file ? (
                        typeof values.file === 'string' ? (
                          <img src={values.file} alt="imagem" />
                        ) : (
                          <img
                            src={URL.createObjectURL(values.file)}
                            alt="thumbnail"
                          />
                        )
                      ) : (
                        <span>Selecione uma Capa</span>
                      )}
                    </div>

                    <footer>
                      <span>Preview da Live</span>
                      <h2>
                        {values.title !== '' ? values.title : 'Live sem título'}
                      </h2>
                    </footer>
                  </PreviewSection>
                </>
              )}
            </Formik>
          </LiveUpdateSection>
        </Section>
      </LiveUpdateContainer>
    </Fade>
  );
}

export default LiveUpdate;
