import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import Button from 'shared/components/Button';
import Section from 'shared/components/Section';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import useUpload from 'http/lives/hooks/useUpload';

import useCategories from 'http/lives/hooks/useCategories';
import { useHistory } from 'react-router-dom';
import { VideoSetupSchema } from '../../validation/VideoSetup';
import {
  VideoSetupContainer,
  VideoSetupSection,
  PreviewSection
} from './styles';

interface CreateLiveProps {
  title: string;
  category: string;
  description: string;
  file: File;
  thumb?: File;
  onlyFor24Hrs: boolean;
  availableAt: Date;
}

function VideoSetup() {
  const history = useHistory();
  const { categories } = useCategories();
  const { video, uploadVideo } = useUpload();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputFileRef?.current?.click();
  };

  const handleUpload = async (values: CreateLiveProps) => {
    if (!video) {
      toast.error(
        'O video não foi carregado corretamente. Por favor, selecione o vídeo e faça o upload novamente'
      );
      history.goBack();
      return;
    }

    uploadVideo(
      values.title,
      values.category,
      values.file,
      values.description,
      values.thumb,
      values.onlyFor24Hrs,
      values.availableAt
    );
    history.push('/lives');
  };

  return (
    <Fade in>
      <VideoSetupContainer>
        <Section title="Video" rollbackPath="/upload-video">
          <VideoSetupSection>
            <Formik
              initialValues={{
                title: '',
                description: '',
                category: '',
                file: video || new File([], ''),
                thumb: undefined,
                onlyFor24Hrs: false,
                availableAt: new Date()
              }}
              validationSchema={VideoSetupSchema}
              onSubmit={({
                title,
                category,
                file,
                description,
                thumb,
                onlyFor24Hrs,
                availableAt
              }: CreateLiveProps) =>
                handleUpload({
                  title,
                  category,
                  file,
                  description,
                  thumb,
                  onlyFor24Hrs,
                  availableAt
                })
              }
            >
              {({
                values,
                handleChange,
                setFieldValue,
                errors,
                touched,
                isSubmitting
              }) => (
                <>
                  <Form>
                    <h3>Detalhes</h3>

                    <FormControl className="FormControl" fullWidth>
                      <TextField
                        variant="outlined"
                        name="title"
                        label="Título"
                        value={values.title}
                        placeholder="Insira o título da sua Video"
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                        error={!!touched.title && !!errors.title}
                      />
                    </FormControl>

                    <FormControl className="FormControl" fullWidth>
                      <TextField
                        variant="outlined"
                        name="description"
                        label="Descrição"
                        multiline
                        minRows={3}
                        value={values.description}
                        placeholder="Fale um pouco sobre o conteúdo de sua Video"
                        onChange={handleChange}
                        helperText={touched.description && errors.description}
                        error={!!touched.description && !!errors.description}
                      />
                    </FormControl>

                    <FormControl
                      className="FormControl"
                      variant="outlined"
                      fullWidth
                    >
                      <InputLabel id="category-select-label">
                        Categoria
                      </InputLabel>
                      <Select
                        labelId="category-select-label"
                        id="category-select"
                        name="category"
                        value={values.category}
                        onChange={handleChange}
                        label="Categoria"
                        error={!!touched.category && !!errors.category}
                      >
                        {categories?.map((category) => {
                          return (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDateTimePicker
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy HH:mm"
                          name="availableAt"
                          value={values.availableAt}
                          onChange={(date) =>
                            setFieldValue('availableAt', date)
                          }
                          helperText={touched.availableAt && errors.availableAt}
                          error={!!touched.availableAt && !!errors.availableAt}
                          id="date-picker"
                          label="Data de disponibilidade"
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="onlyFor24Hrs"
                          value={values.onlyFor24Hrs}
                          onChange={handleChange}
                        />
                      }
                      label="Disponibilizar por 24hrs"
                    />

                    <br />
                    <br />

                    {isSubmitting ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Button
                        text="Salvar e publicar"
                        size="md"
                        type="submit"
                      />
                    )}
                  </Form>

                  <PreviewSection error={!!touched.thumb && !!errors.thumb}>
                    <input
                      type="file"
                      onChange={(event) => {
                        setFieldValue('thumb', event?.target?.files?.[0]);
                      }}
                      ref={inputFileRef}
                      name="thumb"
                      accept="image/*"
                      hidden
                    />
                    <div className="thumbnail" onClick={handleSelectFile}>
                      {values.thumb ? (
                        <img
                          src={URL.createObjectURL(values.thumb)}
                          alt="thumb"
                        />
                      ) : (
                        <span>Sem capa</span>
                      )}
                    </div>

                    <footer>
                      <span>Preview do Video</span>
                      <h2>
                        {values.title !== ''
                          ? values.title
                          : 'Video sem título'}
                      </h2>
                    </footer>
                  </PreviewSection>
                </>
              )}
            </Formik>
          </VideoSetupSection>
        </Section>
      </VideoSetupContainer>
    </Fade>
  );
}

export default VideoSetup;
