import styled from 'styled-components';

export const CategoriesContainer = styled.div``;

export const EditPagesSection = styled.div`
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 15px;

  h2 {
    font: 600 14px Inter;
    color: #555;
  }

  form {
    margin: 20px 0;
  }
`;
