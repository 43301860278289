import LiveStreamCategory from 'http/lives/api/Response/LiveStreamCategory';
import api from 'services/api';

export const createLiveStreamCategory = async (
  formData: FormData
): Promise<LiveStreamCategory> => await api.post('/stream/category', formData);

export const listLiveStreamCategories = async (): Promise<
  LiveStreamCategory[]
> => await api.get('/stream/category/listAll');

export const getLiveStreamCategory = async (
  categoryId: string
): Promise<LiveStreamCategory> => api.get(`/stream/category/${categoryId}`);

export const updateLiveStreamCategory = async (
  id: string,
  formData: FormData
): Promise<string> => await api.put(`/stream/category/${id}`, formData);

export const deleteLiveStreamCategory = async (
  categoryId: string
): Promise<string> => await api.delete(`/stream/category/${categoryId}`);
