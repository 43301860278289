interface Device {
  deviceId: string;
  label: string;
}

const gotDevices = (mediaDevices: MediaDeviceInfo[]): Promise<Device[]> =>
  new Promise((resolve, reject) => {
    const availableVideoInputs: Device[] = [];
    mediaDevices.forEach((mediaDevice) => {
      if (mediaDevice.kind === 'videoinput') {
        availableVideoInputs.push({
          deviceId: mediaDevice.deviceId,
          label: mediaDevice.label
        });
      }
    });

    if (availableVideoInputs.length > 0) {
      resolve(availableVideoInputs);
    } else {
      reject(new Error('ERR::NO_MEDIA_TO_STREAM'));
    }
  });

export default gotDevices;
