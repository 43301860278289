import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import {
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  TextField,
  IconButton
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Button from 'shared/components/Button';

import EventSchema from '../validation/EventSchema';
import CalendarReminder from '../api/ts/interface/CalendarReminder';

interface EventData {
  start: string | Date;
  end: string | Date;
  title: string;
  message: string;
}

function EventFormModal({
  open,
  toggleOpen,
  callback,
  initialValues
}: {
  open: boolean;
  toggleOpen: () => void;
  initialValues?: CalendarReminder;
  callback: (values: EventData) => void;
}) {
  const handleSubmit = (values: EventData) => {
    callback(values);
    toggleOpen();
  };
  return (
    <Dialog open={open} onClose={toggleOpen} fullWidth maxWidth="md">
      <EventFormModalContainer>
        <header>
          <h1 className="title">Novo evento</h1>
          <IconButton onClick={toggleOpen}>
            <CloseOutlined />
          </IconButton>
        </header>
        <Formik
          initialValues={{
            start: initialValues?.start || new Date(),
            end: initialValues?.end || new Date(),
            title: initialValues?.title || '',
            message: initialValues?.message || ''
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={EventSchema}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            isSubmitting,
            touched,
            errors
          }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl variant="outlined" fullWidth>
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        name="start"
                        value={values.start}
                        onChange={(date) => setFieldValue('start', date)}
                        helperText={touched.start && errors.start}
                        error={!!touched.start && !!errors.start}
                        id="date-picker"
                        label="Data de início"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl variant="outlined" fullWidth>
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        name="end"
                        value={values.end}
                        onChange={(date) => setFieldValue('end', date)}
                        helperText={touched.end && errors.end}
                        error={!!touched.end && !!errors.end}
                        id="date-picker"
                        label="Data final"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Nome do evento"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                      error={!!touched.title && !!errors.title}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Descrição"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      helperText={touched.message && errors.message}
                      error={!!touched.message && !!errors.message}
                      multiline
                      minRows={4}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: 'grid', placeItems: 'center' }}
                >
                  <Button size="lg" type="submit">
                    {isSubmitting ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </EventFormModalContainer>
    </Dialog>
  );
}

export default EventFormModal;

const EventFormModalContainer = styled.div`
  border-radius: 10px;
  background: white;
  padding: 25px 40px;

  header {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px 0;
  }

  .title {
    font: 700 18px Inter;
  }
`;
