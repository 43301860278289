import React, { FormEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Picker, { IEmojiData } from 'emoji-picker-react';
import { IconButton, Popover } from '@material-ui/core';
import { EmojiEmotionsOutlined, SendOutlined } from '@material-ui/icons';

import { useUser } from 'shared/contexts/AuthContext';
import ChatMessage from './ChatMessage';
import useChat from '../hooks/useChat';

function LiveChat({ streamId }: { streamId: string }) {
  const { user } = useUser();
  const { messages, sendMessage } = useChat(streamId);

  const [message, setMessage] = useState('');
  const [EmojiButtonEl, setEmojiButtonEl] =
    useState<HTMLButtonElement | null>();
  const isPopoverOpen = Boolean(EmojiButtonEl);

  const chatFooterRef = useRef<HTMLDivElement>(null);

  const handleEmojiSelect = (
    e: React.MouseEvent<Element, MouseEvent>,
    emojiObj: IEmojiData
  ) => {
    setMessage((state) => state + emojiObj.emoji);
    setEmojiButtonEl(null);
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (user) {
      sendMessage({
        id: Math.random(),
        name: user?.name,
        date: new Date(),
        thumb: user?.avatar,
        message
      });
    }
    setMessage('');
  };

  useEffect(() => {
    chatFooterRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <ChatContainer>
      <header>
        <h3>Chat da transmissão</h3>
      </header>
      <Chat>
        {messages.map((message) => {
          return (
            <ChatMessage
              userImg={message.thumb}
              message={message.message}
              userName={message.name}
            />
          );
        })}
        <div ref={chatFooterRef} />
      </Chat>
      <footer>
        <form onSubmit={handleSendMessage}>
          <label htmlFor="chatInput">
            Enviar Mensagem
            <div className="options">
              <IconButton onClick={(e) => setEmojiButtonEl(e.currentTarget)}>
                <EmojiEmotionsOutlined />
              </IconButton>
              <IconButton onClick={handleSendMessage} type="submit">
                <SendOutlined />
              </IconButton>
            </div>
          </label>
          <ChatInput
            name="chatInput"
            value={message}
            autoComplete="off"
            placeholder="Digite sua mensagem"
            onChange={(e) => setMessage(e.target.value)}
          />
        </form>

        <Popover
          open={isPopoverOpen}
          anchorEl={EmojiButtonEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Picker disableSearchBar onEmojiClick={handleEmojiSelect} />
        </Popover>
      </footer>
    </ChatContainer>
  );
}

export default LiveChat;

const ChatContainer = styled.div`
  width: 30%;
  border-radius: 20px;
  color: white;
  background: #303943;

  header {
    display: grid;
    place-items: center;
    padding: 10px;
    border-bottom: 1px solid grey;
    font-weight: 600;
  }

  footer {
    border-top: 1px solid grey;
    padding: 20px 10px;

    label {
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 4px;
      font: 700 14px Roboto;

      .MuiIconButton-root {
        padding: 0;
        margin-left: 5px;
      }

      .MuiSvgIcon-root {
        color: white;
      }
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Chat = styled.div`
  height: 55vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #444;
  }

  ::-webkit-scrollbar-thumb {
    background: #555;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #333;
  }
`;

const ChatInput = styled.input`
  background: /* #242b33 */ none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: 0;
  padding: 10px 0;
  font: 400 14px Roboto;
`;
