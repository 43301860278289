import isAxiosError from 'helpers/isAxiosError';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  listLiveStreamCategories,
  createLiveStreamCategory,
  updateLiveStreamCategory
} from '../api';
import { useCategoryContext } from '../context';

const useCategory = () => {
  const { categories, setCategories } = useCategoryContext();

  const getAllCategories = useCallback(() => {
    (async () => {
      try {
        const response = await listLiveStreamCategories();
        setCategories(response);
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    })();
  }, []);

  useEffect(() => {
    getAllCategories();
  }, []);

  const createCategory = async (
    name: string,
    description: string,
    file: File
  ) => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('file', file);
      formData.append('description', description);
      await createLiveStreamCategory(formData);
      toast.success('categoria criada com sucesso');
      getAllCategories();
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const updateCategory = async (
    id: string,
    name: string,
    file: File | string,
    description: string
  ) => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      if (file) formData.append('file', file);
      formData.append('description', description);

      await updateLiveStreamCategory(id, formData);
      toast.success('categoria atualizada com sucesso');
      getAllCategories();
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    categories,
    setCategories,
    createCategory,
    updateCategory
  };
};

export default useCategory;
