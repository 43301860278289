import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from 'shared/contexts/AuthContext';
import isAxiosError from 'helpers/isAxiosError';
import { startSession } from '../api';
import { setToken } from '../../../helpers/handleTokenSession';

const useLoginHook = () => {
  const { saveUser } = useUser();
  const history = useHistory();

  const login = async (email: string, password: string) => {
    try {
      const userSession = await startSession({ email, password });

      saveUser(userSession.user);
      setToken(userSession);
      history.push('/management');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const logout = async () => {
    localStorage.removeItem('@user');
    localStorage.removeItem('@token');
    saveUser(null);
    history.push('/');
  };

  return {
    login,
    logout
  };
};

export default useLoginHook;
