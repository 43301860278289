import styled from 'styled-components';

export const ManagementContainer = styled.div`
  max-width: 98vw;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
`;

export const OverviewSection = styled.div`
  width: 100%;
  display: -webkit-flex;
  flex-direction: column;
  gap: 10px;
`;

export const InformationSection = styled.div`
  width: 100%;
  padding: 15px 40px;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 10px;
  gap: 10px;

  @media (max-width: 599px) {
    padding: 15px 10px;
  }
`;

export const FilterTxt = styled.span`
  font-size: 12px;
  font-weight: bold;
`;
