import * as yup from 'yup';

const categorySchema = yup.object({
  date: yup.string(),
  name: yup.string().required('Insira um nome'),
  file: yup
    .mixed()
    .notRequired()
    .test('fileFormat', 'Insira uma imagem válida', (value) => {
      return (
        (value && ['image/png', 'image/jpeg'].includes(value.type)) ||
        typeof value === 'string'
      );
    })
});

export default categorySchema;
