import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  TextField
} from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import useUser from 'http/users/hooks/useUser';
import { User } from 'http/users/ts/user.interface';

import Button from 'shared/components/Button';
import Section from 'shared/components/Section';

import { Alert } from '@material-ui/lab';
import {
  DetailsContainer,
  DetailsSection,
  ModalHeader,
  ModalFooter,
  ModalBody
} from './styles';

function UserDetails() {
  const { getUser, postNewPassword } = useUser();
  const { id } = useParams() as { id: string };
  const [newPass, setNewPass] = useState('12354667890');
  const [user, setUser] = useState<User | undefined>();

  const handleGetUserDetails = async () => {
    const userData = await getUser(id);

    setUser(userData);
  };

  const generetedString = (size: number) => {
    let string = '';
    const caracteres = '1234567890';
    for (let i = 0; i < size; i += 1) {
      string += caracteres.charAt(
        Math.floor(Math.random() * caracteres.length)
      );
    }
    return string;
  };

  const handlePostNewPass = async () => {
    if (user && user.email) {
      try {
        await postNewPassword(id, user?.email, newPass);
        closeModal();
        alert_success();
      } catch (error) {
        alert_error();
        closeModal();
      }
    } else {
      alert_error();
      closeModal();
    }
  };

  const alert_success = () => {
    return (
      <Alert variant="outlined" severity="success">
        A senha do usuário foi alterada com sucesso!
      </Alert>
    );
  };

  const alert_error = () => {
    return (
      <Alert variant="outlined" severity="error">
        Usuário não definido!
      </Alert>
    );
  };

  useEffect(() => {
    handleGetUserDetails();
  }, []);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    setNewPass(generetedString(8));
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Fade in>
      <DetailsContainer>
        <Section title={user?.name || ''} rollbackPath="/users">
          <DetailsSection>
            <Modal open={modalIsOpen} onClose={closeModal} center>
              <ModalBody>
                <ModalHeader>
                  <h2>Nova senha:</h2>
                </ModalHeader>
                <div>
                  <h3>
                    Essa será a nova senha de <i>{user?.name}</i> :{' '}
                    <span>{newPass}</span>
                  </h3>
                </div>
                <ModalFooter>
                  <Button
                    onClick={() => closeModal()}
                    variant="outline"
                    size="sm"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => handlePostNewPass()}
                    variant="outline"
                    size="sm"
                  >
                    Enviar Nova Senha
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Modal>
            {user ? (
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Nome"
                      value={user?.name || ''}
                      aria-readonly
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Email"
                      value={user?.email || ''}
                      aria-readonly
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Endereço"
                      value={
                        user?.address
                          ? `${user?.address?.street} - ${user?.address?.city} ${user.address?.uf}`
                          : 'Não informado'
                      }
                      aria-readonly
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Número"
                      value={user?.address?.number || 'Não informado'}
                      aria-readonly
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Complemento"
                      value={user?.address?.complement || 'Não informado'}
                      aria-readonly
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <CircularProgress />
              </div>
            )}

            <br />
            <br />

            {user?.subscription && <span>Plano contratado</span>}

            <Grid container spacing={4} style={{ marginTop: '5px' }}>
              {user?.subscription && (
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label="Plano"
                        value={user?.subscription?.plan || 'Não informado'}
                        aria-readonly
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label="Status"
                        value={user?.subscription?.status || 'Não informado'}
                        aria-readonly
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label="Tempo"
                        value={
                          user?.subscription
                            ? `Desde ${user?.subscription?.created_at}`
                            : 'Não Informado'
                        }
                        aria-readonly
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <Link to="/users">
                  <Button size="lg">Voltar</Button>
                </Link>
                <Button onClick={() => openModal()} variant="outline" size="lg">
                  Gerar Nova Senha
                </Button>
                {user && (
                  <Link to={`/user/payments/${id}`}>
                    <Button variant="outline" size="lg">
                      Histórico de Pagamento
                    </Button>
                  </Link>
                )}
              </Grid>
            </Grid>
          </DetailsSection>
        </Section>
      </DetailsContainer>
    </Fade>
  );
}

export default UserDetails;
