import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Xls from 'assets/icons/XLS.svg';

import { TuneOutlined } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';

import useSort from 'shared/hooks/useSort';
import Revenue from 'http/revenues/api/ts/interface/Revenue';
import Filter from 'http/revenues/ts/filter.interface';

import IconButton from 'shared/components/IconButton';

import RevenuesFilterDrawer from '../../components/RevenuesFilterDrawer';
import RevenuesTable from '../../components/RevenuesTable';
import Section from '../../../../shared/components/Section';

import useRevenues from '../../hook/useRevenues';

import {
  OverviewItems,
  RevenuesContainer,
  RevenuesSection,
  PagenationContainer
} from './styles';

function Revenues() {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const {
    revenues,
    billingReport,
    fetchPayments,
    currentPage,
    currentFilters,
    setCurrentFilters,
    setCurrentPage,
    totalPages,
    getXslBuffer
  } = useRevenues();

  const handleFilter = async (values: Filter) => {
    if (values.startDate && values.endDate) {
      const start = moment(values.startDate?.toISOString()).format(
        'MM-DD-yyyy'
      );
      const end = moment(values.endDate?.toISOString()).format('MM-DD-yyyy');

      const filters = {
        startDate: start,
        endDate: end,
        transactionStatus: values.transactionStatus,
        transactionType: values.transactionType
      };
      setCurrentFilters(filters);
      fetchPayments(currentPage, filters);
    }
  };

  const handleChangePagination = async (
    e: ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    fetchPayments(value, currentFilters);
  };

  const handleCleanFilter = async () => {
    fetchPayments(currentPage);
    setCurrentFilters({});
  };

  const handleExportToXsl = async () => {
    const buffer = await getXslBuffer();

    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });

    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = `relatorio-faturamento_${new Date().toLocaleDateString()}.xlsx`;
    a.click();
  };

  const { requestSort, sortedItems, sortConfig } = useSort<Revenue>(revenues, {
    key: 'subscription.title' as keyof Revenue,
    direction: 'desc'
  });

  return (
    <RevenuesContainer>
      <Section
        title="Faturamento"
        options={[
          <IconButton onClick={() => handleExportToXsl()}>
            <img
              src={Xls}
              style={{ margin: '8px 3px', height: 20, width: 20 }}
              alt="xls icon"
            />
          </IconButton>,
          <IconButton onClick={() => setIsFilterOpen(true)}>
            <TuneOutlined />
          </IconButton>
        ]}
      >
        <RevenuesSection>
          <OverviewItems>
            <p>
              Faturamento total:{' '}
              <span className="good">
                <NumberFormat
                  value={billingReport.totalBilling}
                  displayType="text"
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator="."
                  prefix="R$ "
                />
              </span>
            </p>
            <p>
              Cancelamentos:{' '}
              <span className="bad">
                <NumberFormat
                  value={billingReport.totalCancelledBilling}
                  displayType="text"
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator="."
                  prefix="R$ "
                />
              </span>
            </p>
          </OverviewItems>

          <RevenuesTable
            sortedItems={sortedItems}
            sortConfig={sortConfig}
            requestSort={requestSort}
          />

          <PagenationContainer>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePagination}
              color="primary"
            />
          </PagenationContainer>
        </RevenuesSection>
      </Section>

      <RevenuesFilterDrawer
        open={isFilterOpen}
        toggleOpen={() => setIsFilterOpen((state) => !state)}
        callback={handleFilter}
        clean={handleCleanFilter}
      />
    </RevenuesContainer>
  );
}

export default Revenues;
