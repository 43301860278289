import * as yup from 'yup';

const SongSchema = yup.object({
  title: yup.string().required('Insira um título').min(3),
  description: yup
    .string()
    .max(200, 'Excedeu o máximo de caracteres')
    .notRequired(),
  duration_millis: yup.number().min(1000, 'O áudio escolhido é muito pequeno'),
  thumb: yup
    .mixed()
    .notRequired()
    .test('fileFormat', 'Selecione uma imagem', (value) => {
      return value
        ? ['image/png', 'image/jpeg'].includes(value.type) ||
            typeof value === 'string'
        : !value;
    }),
  song: yup
    .mixed()
    .required('Selecione uma capa para sua live')
    .test('fileFormat', 'Selecione um áudio', (value) => {
      return (
        value &&
        (['audio/ogg', 'audio/mpeg'].includes(value.type) ||
          typeof value === 'string')
      );
    })
});

export default SongSchema;
