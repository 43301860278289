import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Button from '../../../shared/components/Button';

interface CourseFilterDrawerProps {
  open: boolean;
  toggleOpen: () => void;
  filterCourses: (
    category: string,
    name: string,
    date: MaterialUiPickersDate
  ) => void;
  cleanFilters: () => void;
}

function CourseFilterDrawer({
  open,
  toggleOpen,
  filterCourses,
  cleanFilters
}: CourseFilterDrawerProps) {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [date, setDate] = useState<MaterialUiPickersDate>(new Date());

  return (
    <Drawer open={open} anchor="right" onClose={toggleOpen}>
      <FilterContainer>
        <div className="title">
          <h1>Filtros</h1>
          <span onClick={toggleOpen}>X</span>
        </div>
        <p>
          Procure pelo nome e/ou data do curso. Você pode limpar os filtros
          clicando{' '}
          <a href="#" style={{ color: 'rebeccapurple' }} onClick={cleanFilters}>
            aqui
          </a>
        </p>

        <FormControl fullWidth variant="outlined">
          <InputLabel id="course-filter-label">Tipo</InputLabel>
          <Select
            labelId="course-filter-label"
            id="course-filter"
            name="category"
            label="Categoria"
            value={category}
            onChange={(e) => setCategory(e.target.value as string)}
          >
            <MenuItem value="">
              <em>Selecione a categoria</em>
            </MenuItem>
            <MenuItem value="CURSO">Curso</MenuItem>
            <MenuItem value="MENTORIA">Mentoria</MenuItem>
          </Select>
        </FormControl>

        <br />

        <FormControl fullWidth>
          <TextField
            label="Nome"
            placeholder="Ex: Meditação #56 - Aulas..."
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl variant="outlined" fullWidth>
            <KeyboardDatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              value={date}
              onChange={(date) => {
                setDate(date);
              }}
              id="date-picker"
              label="Data"
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </FormControl>
        </MuiPickersUtilsProvider>

        <br />

        <Button
          text="Filtrar"
          size="full"
          onClick={() => filterCourses(category, name, date)}
        />
      </FilterContainer>
    </Drawer>
  );
}

export default CourseFilterDrawer;

const FilterContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 40px 20px;

  .title {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    font: 600 16px Inter;
    padding-bottom: 20px;

    span {
      cursor: pointer;
    }
  }

  p {
    font: 400 14px Roboto;
    padding-bottom: 30px;
  }
`;
