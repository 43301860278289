import styled from 'styled-components';

export const CoursesListContainer = styled.div`
  .not-found-message {
    width: 100%;
    padding: 100px 0;
    display: grid;
    place-items: center;
    text-align: center;

    h3 {
      font: 700 24px Inter;
      padding: 15px 0;
      color: #555;
    }

    span {
      font: 400 15px Roboto;
      color: #666;
    }
  }
`;

export const CoursesListSection = styled.div`
  border-radius: 10px;
  display: grid;
  row-gap: 25px;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;
