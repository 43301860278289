import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  closeStream,
  createStream,
  deleteStream,
  editStream,
  getStream,
  listStreams,
  startRecording,
  stopRecording,
  uploadStream
} from '../api';

import isAxiosError from '../../../helpers/isAxiosError';
import { app } from '../../../firebase';

interface CreateLiveProps {
  title: string;
  category: string;
  description: string;
  file?: File | string;
  onlyFor24Hrs: boolean;
  blockComments: boolean;
}

interface UpdateLiveProps extends CreateLiveProps {
  id: string;
}

const useLiveHook = () => {
  const history = useHistory();

  const startLive: (props: CreateLiveProps) => void = async ({
    title,
    category,
    file,
    description,
    onlyFor24Hrs,
    blockComments
  }: CreateLiveProps) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('onlyFor24Hrs', String(onlyFor24Hrs));
    formData.append('blockComments', String(blockComments));
    formData.append('categories', JSON.stringify([category]));

    if (file) {
      formData.append('file', file);
    }

    try {
      const {
        stream: {
          sessionId,
          id,
          thumb,
          streamer,
          title,
          created_at,
          update_at,
          viewers
        },
        token
      } = await createStream(formData);
      const liveRef = app.database().ref(`/streams/${id}`);
      await liveRef.set({
        id,
        sessionId,
        streamer,
        thumb,
        title,
        created_at,
        update_at,
        viewers
      });
      history.push(
        `/watch-live/${sessionId}/${token}/${id}/${
          onlyFor24Hrs ? 'RECORDING' : 'NOT_RECORDING'
        }`
      );
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const closeLive = async (id: string) => {
    try {
      const data = await closeStream(id);
      const liveRef = app.database().ref(`/streams/${id}`);
      await liveRef.remove();
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const deleteLive = async (id: string) => {
    try {
      const data = await deleteStream(id);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const updateLive: (props: UpdateLiveProps) => void = async ({
    title,
    category,
    file,
    id,
    description,
    onlyFor24Hrs,
    blockComments
  }) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('onlyFor24Hrs', String(onlyFor24Hrs));
    formData.append('blockComments', String(blockComments));
    formData.append('categories', JSON.stringify([category]));
    if (file) {
      formData.append('file', file);
    }

    try {
      await editStream(formData, id);

      history.push('/lives');
      return;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const uploadLive = async ({ title, category, file }: CreateLiveProps) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('categories', JSON.stringify([category]));
    if (file) {
      formData.append('file', file);
    }

    try {
      const data = await uploadStream(formData);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const getAllLives = async () => {
    try {
      const livesList = await listStreams();
      return livesList;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const getLiveDetails = async (liveId: string) => {
    try {
      const liveDetails = await getStream(liveId);

      return liveDetails;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const startLiveRecording = async (sessionId: string, name: string) => {
    try {
      const recordingData = await startRecording(sessionId, name);

      return recordingData;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  const stopLiveRecording = async (archiveId: string, streamId: string) => {
    try {
      const data = await stopRecording(archiveId, streamId);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.message);
      }
    }
  };

  return {
    startLive,
    closeLive,
    uploadLive,
    updateLive,
    deleteLive,
    getAllLives,
    getLiveDetails,
    startLiveRecording,
    stopLiveRecording
  };
};
export default useLiveHook;
