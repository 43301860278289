import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Collapse,
  Fade,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useUser from 'http/users/hooks/useUser';
import { Transaction, User } from 'http/users/ts/user.interface';

import Section from '../../../../shared/components/Section';
import Badge from '../../components/Badge';
import {
  ListContainer,
  PaymentHistoryContainer,
  PaymentHistorySection
} from './styles';

function PaymentHistory() {
  const { getUserTransactions } = useUser();
  const { id } = useParams() as { id: string };
  const [expanded, setExpanded] = useState<number | null>();
  const [data, setData] = useState<User>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const handleOpen = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const handleGetUserPayment = async () => {
    const data = await getUserTransactions(id);

    if (data?.transactions) {
      setData(data);
      setTransactions(data?.transactions);
    }
  };

  useEffect(() => {
    handleGetUserPayment();
  }, []);

  return (
    <Fade in>
      <PaymentHistoryContainer>
        <Section
          title={`${data?.name || ''} - Histórico de Pagamento`}
          rollbackPath={`/users/${id}`}
        >
          <PaymentHistorySection>
            <ListContainer>
              <List>
                {transactions.length === 0 ? (
                  <div className="feedback">
                    <h1>Não há nada por aqui</h1>
                    <p>Este usuário não tem nenhuma transação listada</p>
                  </div>
                ) : (
                  transactions?.map((item, idx) => (
                    <>
                      <ListItem button onClick={() => handleOpen(idx)}>
                        <Badge number={idx + 1} status={item.status} />
                        <ListItemText
                          primary={new Date(
                            item.created_at
                          ).toLocaleDateString()}
                        />

                        <span className="price">
                          R$ {(item.amount / 100).toFixed(2)}
                        </span>
                        <ExpandMore />
                      </ListItem>
                      <Collapse
                        in={expanded === idx}
                        timeout="auto"
                        unmountOnExit
                      >
                        <div className="info">
                          <p>Valor:</p>
                          <span>R$ {(item.amount / 100).toFixed(2)}</span>
                        </div>
                        <div className="info">
                          <p>Método de pagamento:</p>
                          <span>
                            {item.method === 'CREDIT_CARD' &&
                              'Cartão de crédito'}
                            {item.method === 'DEBIT_CARD' && 'Cartão de débito'}
                          </span>
                        </div>
                        <div className="info">
                          <p>Situação:</p>
                          <span>
                            {item.status === 'PROCESSED' && 'Pago'}
                            {item.status === 'REJECTED' && 'Rejeitado'}
                            {item.status === 'CANCELLED' && 'Cancelado'}
                            {item.status === 'PENDING' && 'Pendente'}
                            {item.status === 'REFUNDED' && 'Reembolsado'}
                            {item.status === 'CHARGED_BACK' &&
                              'Cobrado de volta'}
                          </span>
                        </div>
                      </Collapse>
                    </>
                  ))
                )}
              </List>
            </ListContainer>
          </PaymentHistorySection>
        </Section>
      </PaymentHistoryContainer>
    </Fade>
  );
}

export default PaymentHistory;
