import React, { useRef } from 'react';
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';

import Button from '../../../shared/components/Button';
import { Course, CourseData } from '../ts/course.interface';
import CourseSchema from '../validation/CourseSchema';

interface CourseFormProps {
  callback: (values: CourseData) => void;
  initialValues?: Course;
}

function CourseForm({ callback, initialValues }: CourseFormProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const selectFile = () => {
    fileInputRef?.current?.click();
  };

  return (
    <CourseFormContainer>
      <Formik
        initialValues={{
          name: initialValues?.name || '',
          description: initialValues?.description || '',
          type: initialValues?.type || '',
          file: initialValues?.thumb || '',
          amount: initialValues?.amount || 0,
          download: initialValues?.download ? 1 : 0 || !!0,
          certificate: initialValues?.certificate ? 1 : 0 || !!0,
          tags: '[]',
          formats: '[]'
        }}
        onSubmit={(values) => callback(values)}
        validationSchema={CourseSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Nome"
                    placeholder="Ex: A meditação aplicada na segunda guerra mundial"
                    name="name"
                    value={values.name}
                    helperText={touched.name && errors.name}
                    error={!!touched.name && !!errors.name}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    error={!!touched.type && !!errors.type}
                  >
                    Tipo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Tipo"
                    value={values.type}
                    onChange={handleChange}
                    name="type"
                    error={!!touched.type && !!errors.type}
                  >
                    <MenuItem value="CURSO">Curso</MenuItem>
                    <MenuItem value="MENTORIA">Mentoria</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    aria-readonly
                    onClick={selectFile}
                    label="Imagem"
                    variant="outlined"
                    value={
                      values.file && values.file !== ''
                        ? typeof values.file === 'string'
                          ? values.file
                          : 'Arquivo carregado'
                        : ''
                    }
                    helperText={touched.file && errors.file}
                    error={!!touched.file && !!errors.file}
                    InputProps={{
                      endAdornment: values.file ? (
                        <img
                          className="inputThumbIcon"
                          src={
                            typeof values.file === 'object'
                              ? URL.createObjectURL(values.file)
                              : values.file
                          }
                          alt="thumb"
                          height="25px"
                          width="40px"
                        />
                      ) : (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    accept=".png,.jpeg"
                    ref={fileInputRef}
                    onChange={(value) => {
                      if (value.target.files?.length) {
                        setFieldValue('file', value.target.files[0]);
                      }
                    }}
                    name="file"
                    hidden
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    error={!!touched.certificate && !!errors.certificate}
                  >
                    Certificado
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Certificado"
                    value={values.certificate}
                    onChange={handleChange}
                    name="certificate"
                    error={!!touched.certificate && !!errors.certificate}
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Valor"
                    placeholder="R$ 00,00"
                    name="amount"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                    helperText={touched.amount && errors.amount}
                    error={!!touched.amount && !!errors.amount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <strong>R$</strong>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    error={!!touched.download && !!errors.download}
                  >
                    Download
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Download"
                    value={values.download}
                    onChange={handleChange}
                    name="download"
                    error={!!touched.download && !!errors.download}
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Descrição"
                    placeholder="Fale um pouco sobre seu novo contéudo"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    minRows={4}
                    variant="outlined"
                    helperText={touched.description && errors.description}
                    error={!!touched.description && !!errors.description}
                  />
                </FormControl>
              </Grid>

              <div className="buttonWrapper">
                <Button size="lg" type="submit">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </CourseFormContainer>
  );
}

export default CourseForm;

const CourseFormContainer = styled.div`
  padding: 20px 0 40px 0;

  .buttonWrapper {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
`;
