import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import {
  getAll,
  getAllUsers,
  getOne,
  getPaymentHistory,
  postNewPass
} from '../api';
import isAxiosError from '../../../helpers/isAxiosError';
import Filters from '../ts/filter.interface';

const useUser = () => {
  const getUsers = async (page: number | undefined = 1, filters: Filters) => {
    try {
      const data = await getAll(page, filters);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const postNewPassword = async (
    id: string,
    email: string,
    newPass: string
  ) => {
    try {
      const data = await postNewPass(id, newPass, email);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getUser = async (id: string) => {
    try {
      const user = await getOne(id);

      return user;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getUserTransactions = async (id: string) => {
    try {
      const data = await getPaymentHistory(id);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getXslBuffer = async () => {
    try {
      const { users } = await getAllUsers();

      const worksheet = XLSX.utils.json_to_sheet(users);

      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ['data']
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      return excelBuffer;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    getUsers,
    getUser,
    getUserTransactions,
    getXslBuffer,
    postNewPassword
  };
};

export default useUser;
