import React, { useRef } from 'react';
import { Form, Formik } from 'formik';

import Button from 'shared/components/Button';
import Section from 'shared/components/Section';

import {
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import useLiveHook from 'http/lives/hooks/useLiveHook';
import useCategories from 'http/lives/hooks/useCategories';
import { LiveSetupContainer, LiveSetupSection, PreviewSection } from './styles';

import { LiveSetupSchema } from '../../validation/LiveSetupSchema';

function LiveSetup() {
  const { startLive } = useLiveHook();
  const { categories } = useCategories();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputFileRef?.current?.click();
  };

  return (
    <Fade in>
      <LiveSetupContainer>
        <Section title="Preparativos para a live" rollbackPath="/lives">
          <LiveSetupSection>
            <Formik
              initialValues={{
                title: '',
                description: '',
                category: '',
                blockComments: false,
                file: undefined,
                onlyFor24Hrs: false
              }}
              validationSchema={LiveSetupSchema}
              onSubmit={({
                title,
                category,
                file,
                description,
                onlyFor24Hrs,
                blockComments
              }) =>
                startLive({
                  title,
                  category,
                  file,
                  blockComments,
                  description,
                  onlyFor24Hrs
                })
              }
            >
              {({
                values,
                handleChange,
                setFieldValue,
                errors,
                touched,
                isSubmitting
              }) => (
                <>
                  <Form>
                    <h3>Detalhes</h3>

                    <FormControl className="FormControl" fullWidth>
                      <TextField
                        variant="outlined"
                        name="title"
                        label="Título"
                        value={values.title}
                        placeholder="Insira o título da sua live"
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                        error={!!touched.title && !!errors.title}
                      />
                    </FormControl>

                    <FormControl className="FormControl" fullWidth>
                      <TextField
                        variant="outlined"
                        name="description"
                        label="Descrição"
                        multiline
                        minRows={3}
                        value={values.description}
                        placeholder="Fale um pouco sobre o conteúdo de sua live"
                        onChange={handleChange}
                        helperText={touched.description && errors.description}
                        error={!!touched.description && !!errors.description}
                      />
                    </FormControl>

                    <FormControl
                      className="FormControl"
                      variant="outlined"
                      fullWidth
                    >
                      <InputLabel id="category-select-label">
                        Categoria
                      </InputLabel>
                      <Select
                        labelId="category-select-label"
                        id="category-select"
                        name="category"
                        value={values.category}
                        onChange={handleChange}
                        label="Categoria"
                        error={!!touched.category && !!errors.category}
                      >
                        {categories?.map((category) => {
                          return (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="blockComments"
                          value={values.blockComments}
                          onChange={handleChange}
                        />
                      }
                      label="Bloquear Comentários"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="onlyFor24Hrs"
                          value={values.onlyFor24Hrs}
                          onChange={handleChange}
                        />
                      }
                      label="Disponibilizar por 24hrs"
                    />

                    <br />
                    <br />

                    {isSubmitting ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Button text="Iniciar live" size="md" type="submit" />
                    )}
                  </Form>

                  <PreviewSection error={!!touched.file && !!errors.file}>
                    <input
                      type="file"
                      onChange={(event) => {
                        setFieldValue('file', event?.target?.files?.[0]);
                      }}
                      ref={inputFileRef}
                      name="file"
                      accept="image/*"
                      hidden
                    />
                    <div className="thumbnail" onClick={handleSelectFile}>
                      {values.file ? (
                        <img
                          src={URL.createObjectURL(values.file)}
                          alt="thumbnail"
                        />
                      ) : (
                        <span>Sem capa</span>
                      )}
                    </div>

                    <footer>
                      <span>Preview da Live</span>
                      <h2>
                        {values.title !== '' ? values.title : 'Live sem título'}
                      </h2>
                    </footer>
                  </PreviewSection>
                </>
              )}
            </Formik>
          </LiveSetupSection>
        </Section>
      </LiveSetupContainer>
    </Fade>
  );
}

export default LiveSetup;
