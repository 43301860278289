import styled from 'styled-components';

import Colors from '../../constants/Colors';

export const IconButtonContainer = styled.button`
  border: 1px solid ${Colors.primary};
  fill: ${Colors.primary};
  color: ${Colors.primary};
  border-radius: 5px;

  width: 30px;

  .MuiSvgIcon-root {
    padding: 3px;
    fill: ${Colors.primary};
    color: ${Colors.primary};
  }
`;
