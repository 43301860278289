import styled from 'styled-components';

export const PaymentHistoryContainer = styled.div``;

export const PaymentHistorySection = styled.div`
  background: white;
  display: grid;
  place-items: center;
`;

export const ListContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  min-width: 320px;

  header {
    width: 100%;
    padding: 15px 30px;
    display: grid;
    place-items: center;
    background: #f4f8ff;

    h1 {
      font: 700 14px Inter;
    }
  }

  span.price {
    font: 600 14px Inter;
  }

  .info {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    font-size: 12px;
  }

  .feedback {
    text-align: center;
    padding: 20px 0;
    h1 {
      font: 700 20px Inter;
      padding-bottom: 20px;
      color: #666;
    }

    p {
      font: 400 14px Roboto;
      color: #666;
    }
  }
`;
