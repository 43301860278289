import Colors from 'shared/constants/Colors';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  position: relative;
  top: 60px;
  right: 0;
  left: 270px;
  padding: 20px 30px;
  width: calc(100% - 270px);
  background-color: ${Colors.background};
  min-height: calc(100vh - 60px);

  @media (max-width: 599px) {
    width: 100vw;
    position: initial;
    margin-top: 60px;
    left: 0;
    padding: 0 15px;
  }
`;
