import { Popover } from '@material-ui/core';
import styled from 'styled-components';

export const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 270px);
  height: 60px;
  padding: 0 30px;
  background-color: white;
  border-bottom: 1px solid whitesmoke;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  z-index: 90;

  .MenuIcon {
    display: none;

    @media (max-width: 699px) {
      display: block;
    }
  }

  div {
    display: -webkit-flex;
    gap: 5px;
  }

  @media (max-width: 699px) {
    width: 100%;
    left: 0;
  }
`;

export const UserPopover = styled(Popover)`
  .info {
    display: -webkit-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 10 20px;

    h1 {
      font: 600 16px Inter;
    }
  }
`;
