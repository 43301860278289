import styled from 'styled-components';

export const LivesListContainer = styled.div`
  .not-found-message {
    width: 100%;
    padding: 100px 0;
    display: grid;
    place-items: center;

    h3 {
      font: 700 24px Inter;
      padding: 15px 0;
      color: #555;
    }

    span {
      font: 400 15px Roboto;
      color: #666;
    }
  }
`;

export const LivesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 10px;
`;

export const FilterContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 40px 20px;

  .title {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    font: 600 16px Inter;
    padding-bottom: 20px;

    span {
      cursor: pointer;
    }
  }

  p {
    font: 400 14px Roboto;
    padding-bottom: 30px;
  }
`;
