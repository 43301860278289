import styled from 'styled-components';

export const UsersListContainer = styled.div``;

export const UsersListSection = styled.div`
  background: white;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 0;
`;
