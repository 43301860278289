import React, { useRef, useState } from 'react';
import { useUser } from 'shared/contexts/AuthContext';
import useLoginHook from 'http/authentication/hooks/useLoginHook';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { ExitToAppOutlined, MenuOutlined } from '@material-ui/icons';
import { NavbarContainer, UserPopover } from './styled';

function Navbar({ handleOpenMenu }: { handleOpenMenu: () => void }) {
  const { user, updateAvatar, updateLocalUser } = useUser();
  const { logout } = useLoginHook();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>();

  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const handleSelectImage = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = async (file: File) => {
    await updateAvatar(file);
    updateLocalUser();
  };

  return (
    <NavbarContainer>
      <div>
        <MenuOutlined onClick={handleOpenMenu} className="MenuIcon" />
      </div>

      <div>
        <Avatar src={user?.avatar || undefined} onClick={handlePopoverOpen} />
      </div>

      <UserPopover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
      >
        <List>
          <ListItem>
            <div className="info">
              <Avatar
                src={user?.avatar || undefined}
                onClick={handleSelectImage}
              />
              <div>
                <h1>{user?.name}</h1>
                <span>{user?.email}</span>
              </div>
            </div>
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>

        <input
          type="file"
          accept=".png,.jpeg"
          ref={fileInputRef}
          onChange={(value) => {
            if (value.target.files?.length) {
              handleUpload(value.target?.files?.[0]);
            }
          }}
          name="file"
          hidden
        />
      </UserPopover>
    </NavbarContainer>
  );
}

export default Navbar;
