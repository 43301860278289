import api from 'services/api';
import CalendarReminder from './ts/interface/CalendarReminder';

export const getSchedules = async (): Promise<CalendarReminder[]> =>
  await api.get('/calendar');

export const createSchedule = async ({
  end,
  start,
  title,
  message
}: Partial<CalendarReminder>): Promise<CalendarReminder> =>
  await api.post('/calendar', { end, start, title, message });

export const updateSchedule = async ({
  id: scheduleId,
  end,
  start,
  title,
  message
}: Partial<CalendarReminder>): Promise<string> =>
  await api.put(`/calendar/${scheduleId}`, {
    end,
    start,
    title,
    message,
    is_active: true
  });

export const deleteSchedule = async (scheduleId: string): Promise<string> =>
  await api.delete(`/calendar/${scheduleId}`);
