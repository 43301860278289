import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { TuneOutlined } from '@material-ui/icons';

import { User } from 'http/users/ts/user.interface';
import Filters from 'http/users/ts/filter.interface';

import useSort from 'shared/hooks/useSort';
import TableHead from 'shared/components/TableHead';
import IconButton from 'shared/components/IconButton';
import Section from 'shared/components/Section';
import Table from 'shared/components/Table';
import Xls from 'assets/icons/XLS.svg';

import useUser from '../../hooks/useUser';
import FilterDrawer from '../../components/FilterDrawer';
import {
  UsersListContainer,
  UsersListSection,
  PaginationContainer
} from './styles';

function UsersList() {
  const { getUsers, getXslBuffer } = useUser();

  const history = useHistory();
  const [users, setUsers] = useState<User[]>();
  const [currentFilters, setCurrentFilters] = useState<Filters>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleSelectUser = (id: string) => {
    history.push(`/users/${id}`);
  };

  const handleGetUsers = async (page: number, filters: Filters) => {
    const data = await getUsers(page, filters);

    if (data) {
      setUsers(data.data);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    }
  };

  const handleChangePagination = async (
    e: ChangeEvent<unknown>,
    value: number
  ) => {
    handleGetUsers(value, currentFilters || { withSubscription: false });
  };

  const filterUsers = (values: Filters) => {
    setCurrentFilters({
      ...values,
      withSubscription: !!values.subscriptionType
    });
    handleGetUsers(1, {
      ...values,
      withSubscription: !!values.subscriptionType
    });
  };

  const cleanFilters = () => {
    handleGetUsers(1, { withSubscription: false });
    setIsFilterOpen(false);
    setCurrentFilters({});
  };

  const handleExportToXsl = async () => {
    const buffer = await getXslBuffer();

    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });

    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = `relatorio-usuarios_${new Date().toLocaleDateString()}.xlsx`;
    a.click();
  };

  useEffect(() => {
    handleGetUsers(1, { withSubscription: false });
  }, []);

  const buttons = [
    <IconButton onClick={() => handleExportToXsl()}>
      <img
        src={Xls}
        style={{ margin: '8px 3px', height: 20, width: 20 }}
        alt="xls icon"
      />
    </IconButton>,
    <IconButton onClick={() => setIsFilterOpen((state) => !state)}>
      <TuneOutlined />
    </IconButton>
  ];

  const headCells = [
    { name: 'Nome', align: 'left', id: 'name', sorting: true },
    { name: 'Email', align: 'left', id: 'email', sorting: true },
    { name: 'Endereço', align: 'center', id: 'address.street', sorting: false },
    {
      name: 'Cidade/Estado',
      align: 'center',
      id: 'address',
      sorting: false
    },
    {
      name: 'Data de nascimento',
      align: 'center',
      id: 'birthdate',
      sorting: true
    },
    { name: 'Assinatura', align: 'center', id: 'plan', sorting: false },
    { name: 'Data', align: 'center', id: 'created_at', sorting: true }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<User>(
    users as User[],
    {
      key: 'name' as keyof User,
      direction: 'desc'
    }
  );

  return (
    <UsersListContainer>
      <Section title="Usuários" options={buttons}>
        <UsersListSection>
          <Table>
            <TableHead
              headCells={headCells}
              sortConfig={sortConfig}
              sort={(id) => requestSort(id as keyof User)}
            />
            <TableBody>
              {!users && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <div style={{ display: 'grid', placeItems: 'center' }}>
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {users?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <div style={{ display: 'grid', placeItems: 'center' }}>
                      <h1>Naõ há nada por aqui</h1>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                sortedItems?.map((item) => {
                  return (
                    <TableRow
                      hover
                      key={item.id}
                      onClick={() => handleSelectUser(item.id)}
                    >
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {item.name}
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell align="center">
                        {item.address
                          ? `${item.address?.street} ${
                              item.address?.number || ''
                            } `
                          : 'Não informado'}
                      </TableCell>
                      <TableCell align="center">
                        {item.address?.city || 'Não informado'} -{' '}
                        {item.address?.uf || 'Não informado'}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(item.birthdate).toLocaleDateString('pt-BR')}
                      </TableCell>
                      <TableCell align="center">
                        {item.subscription?.plan.title || 'Não inscrito'}
                      </TableCell>

                      <TableCell align="center">
                        {new Date(item.created_at).toLocaleDateString('pt-BR')}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <PaginationContainer>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePagination}
              color="primary"
            />
          </PaginationContainer>
        </UsersListSection>
      </Section>

      <FilterDrawer
        open={isFilterOpen}
        toggleOpen={() => setIsFilterOpen((state) => !state)}
        callback={filterUsers}
        clean={cleanFilters}
      />
    </UsersListContainer>
  );
}

export default UsersList;
