import api from '../../../services/api';
import { Song } from '../ts/song.interface';
import Playlist from '../ts/playlist.interface';

export const getPlaylistContent = async (): Promise<Playlist> =>
  await api.get('/playlist/owner-playlist');

export const deleteAudio = async (playlistId: string, songId: string) =>
  await api.delete('/playlist/song', {
    params: {
      playlist_id: playlistId,
      song_id: songId
    }
  });

export const addAudio = async (songData: FormData): Promise<Song> =>
  await api.post('/playlist/song', songData);

export const updateAudio = async (
  playlistId: string,
  songData: FormData
): Promise<Song> => await api.put(`/playlist/song/${playlistId}`, songData);
