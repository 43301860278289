import * as yup from 'yup';

const MeditationSchema = yup.object({
  title: yup.string().required('Insira um título'),
  format: yup.string(),
  file: yup
    .mixed()
    .required('Selecione um arquivo')
    .test('fileFormat', 'Insira um arquivo válido', (value) => {
      return (
        value &&
        ([
          'video/mpeg',
          'video/mp4',
          'video/ogg',
          'audio/ogg',
          'audio/mpeg'
        ].includes(value.type) ||
          typeof value === 'string')
      );
    }),
  thumb: yup.mixed().notRequired(),
  duration_millis: yup.number().required(),
  description: yup.string().notRequired(),
  subCategory: yup.string().notRequired(),

  category: yup.string().required('Escolha uma categoria')
});

export default MeditationSchema;
