import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CloseOutlined } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';

import TableHead from 'shared/components/TableHead';
import Button from 'shared/components/Button';
import useSort from 'shared/hooks/useSort';
import Table from '../../../shared/components/Table';
import NotificationForm from './NotificationForm';
import Notification from '../api/ts/interface/Notification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750,
      border: '1px solid whitesmoke'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    date: {
      padding: '25px 0px !important;',
      width: '100px'
    },
    repeat: {
      width: '75px'
    },
    image: {
      width: '120px',
      padding: '25px 0px !important'
    },
    course: {
      width: '120px'
    }
  })
);

interface EnhancedTableProps {
  data: Notification[];
  status: { name: string; value: string };
  handleDelete: (id: string) => void;
  handleEdit: (id: string, values: any) => void;
}

export default function EnhancedTable({
  data: rows,
  status,
  handleDelete,
  handleEdit
}: EnhancedTableProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<number | null>();

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const headCells = [
    { name: 'Data', align: 'center', id: 'startAt', sorting: true },
    { name: 'Hora', align: 'center', id: 'startAt', sorting: true },
    { name: 'Repetir', align: 'left', id: 'repeatType', sorting: false },
    {
      name: 'Imagem',
      align: 'center',
      id: 'image',
      sorting: false
    },
    {
      name: 'Mensagem',
      align: 'left',
      id: 'message',
      sorting: true
    },
    {
      name: 'Destinatários',
      align: 'center',
      id: 'receciversType',
      sorting: false
    },
    { name: 'Ações', align: 'center', id: 'created_at', sorting: false }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<Notification>(
    rows as Notification[],
    {
      key: 'startAt' as keyof Notification,
      direction: 'desc'
    }
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
            <TableHead
              headCells={headCells}
              sortConfig={sortConfig}
              sort={(id) => requestSort(id as keyof Notification)}
            />
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <h1>Nenhum registro encontrado</h1>
                  </TableCell>
                </TableRow>
              ) : (
                sortedItems.map((row, idx) => {
                  return (
                    <>
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {new Date(row.startAt).toLocaleDateString('pt-BR')}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(row.startAt).toLocaleTimeString('pt-BR')}
                        </TableCell>
                        <TableCell>
                          {row.repeatType === 'NOW' && 'Agora'}
                          {row.repeatType === 'NOT_REPEAT' && 'Agendada'}
                          {row.repeatType === 'EVERY_DAY' &&
                            `Diariamente até ${new Date(
                              row.endAt
                            ).toLocaleDateString('pt-BR')}`}
                          {row.repeatType === 'PER_ONE_WEEK' &&
                            `Semanalmente até ${new Date(
                              row.endAt
                            ).toLocaleDateString('pt-BR')}`}
                        </TableCell>
                        <TableCell align="center">
                          <img
                            height="60px"
                            width="60px"
                            src={row.image}
                            alt={row.id}
                          />
                        </TableCell>
                        <TableCell>{row.message}</TableCell>
                        <TableCell align="center">
                          {row.receiversType === 'FREE_USERS' &&
                            'Usuários não pagantes'}
                          {row.receiversType === 'EVERYONE' && 'Todos'}
                          {row.receiversType === 'SUBSCRIBER_USERS' &&
                            'Assinantes'}
                          {row.receiversType === 'YEARLY_SUBSCRIBER_USERS' &&
                            'Assinantes anuais'}
                          {row.receiversType === 'MONTHY_SUBSCRIBER_USERS' &&
                            'Assinantes mensais'}
                          {row.receiversType ===
                            'HAVE_COURSE_PURCHASED_USERS' &&
                            'Usuários que compraram cursos'}
                        </TableCell>
                        <TableCell align="right" className="actions">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              justifyContent: 'center'
                            }}
                          >
                            {status.value === 'CANCELLED' && (
                              <Button text="Reenviar" size="sm" />
                            )}

                            <Button
                              size="sm"
                              onClick={() => handleOpenEditForm(idx)}
                            >
                              Editar
                            </Button>
                            <Button
                              size="sm"
                              variant="delete"
                              onClick={() => handleDelete(row.id)}
                            >
                              Excluir
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={7}
                        >
                          <Collapse in={expanded === idx} unmountOnExit>
                            <div className="editHeader">
                              <h2>Editar notificação</h2>
                              <CloseOutlined
                                onClick={() => handleOpenEditForm(idx)}
                              />
                            </div>

                            <NotificationForm
                              initialValues={row}
                              callback={(values) => handleEdit(row.id, values)}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
