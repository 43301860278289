import api from '../../../services/api';
import { LiveStreamToken } from '../ts/interface/LiveStreamToken';
import streamEnpoint from './endpoints';
import LiveStreamCategory from './Response/LiveStreamCategory';
import LiveListData from './Response/LiveListData';
import RecordingData from './Response/RecordingData';
import LiveStream from './Response/LiveStream';

export const createStream = async (
  formData: FormData
): Promise<LiveStreamToken> => {
  return await api.post(`${streamEnpoint.STREAM}`, formData);
};

export const closeStream = async (id: string): Promise<LiveListData> => {
  return await api.put(`${streamEnpoint.STREAM}/close/${id}`);
};

export const startRecording = async (
  sessionId: string,
  name: string
): Promise<RecordingData> => {
  return await api.post(`${streamEnpoint.STREAM}/archive/startArchive`, {
    sessionId,
    name
  });
};

export const stopRecording = async (
  archiveId: string,
  streamId: string
): Promise<RecordingData> => {
  return await api.post(`${streamEnpoint.STREAM}/archive/stopArchive`, {
    archiveId,
    streamId
  });
};

export const listStreams = async (): Promise<LiveListData[]> => {
  return await api.get(`${streamEnpoint.STREAM}/find/getAll`);
};

export const getStream = async (streamId: string): Promise<LiveListData> => {
  return await api.get(`${streamEnpoint.STREAM}/find/${streamId}`);
};

export const getCategories = async (): Promise<LiveStreamCategory[]> => {
  return await api.get(`${streamEnpoint.STREAM}/category/listAll`);
};

export const uploadStream = async (formData: FormData): Promise<LiveStream> => {
  return await api.post(`${streamEnpoint.STREAM}/upload`, formData);
};

export const deleteStream = async (streamId: string): Promise<string> => {
  return api.delete(`${streamEnpoint.STREAM}/${streamId}`);
};

export const editStream = async (
  formData: FormData,
  streamId: string
): Promise<LiveListData> => {
  return api.patch(`${streamEnpoint.STREAM}/${streamId}`, formData);
};
