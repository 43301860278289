import React from 'react';
import moment from 'moment';

import { TableBody, TableCell, TableRow } from '@material-ui/core';

import Revenue from '../api/ts/interface/Revenue';

import Table from '../../../shared/components/Table';
import TableHead from '../../../shared/components/TableHead';

interface Config {
  key: keyof Revenue;
  direction: 'desc' | 'asc';
}

interface TableProps {
  sortConfig: Config | null;
  sortedItems: Revenue[];
  requestSort: (id: keyof Revenue) => void;
}

function RevenuesTable({ sortConfig, sortedItems, requestSort }: TableProps) {
  const headCells = [
    { name: 'Serviço', align: 'left', id: 'plan.title', sorting: false },
    { name: 'Usuário', align: 'center', id: 'user.name', sorting: false },
    { name: 'CPF', align: 'center', id: 'payerDocument', sorting: true },
    {
      name: 'Forma de pagamento',
      align: 'center',
      id: 'method',
      sorting: true
    },
    { name: 'Email', align: 'center', id: 'user.email', sorting: false },
    { name: 'Data', align: 'center', id: 'created_at', sorting: true },
    { name: 'Status', align: 'center', id: 'status', sorting: true }
  ];
  return (
    <Table>
      <TableHead
        headCells={headCells}
        sortConfig={sortConfig}
        sort={(id) => requestSort(id as keyof Revenue)}
      />

      <TableBody style={{ background: 'white' }}>
        {sortedItems?.map((item) => {
          return (
            <TableRow key={item.id}>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                {item.subscriptionId
                  ? item.subscription?.plan?.title || 'não informado'
                  : item.course?.name || 'não informado'}
              </TableCell>
              <TableCell align="center">{item.user.name}</TableCell>
              <TableCell align="center">
                {item.payerDocument || 'não informado'}
              </TableCell>
              <TableCell align="center">
                {item.method === 'CREDIT_CARD' && 'Cartão de crédito'}
                {item.method === 'IAP' && 'IAP'}
              </TableCell>
              <TableCell align="center">{item.user.email}</TableCell>
              <TableCell align="center">
                {moment(item.created_at).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell align="center">
                {item.status === 'CANCELLED' && 'Cancelado'}
                {item.status === 'PENDING' && 'Pendente'}
                {item.status === 'PROCESSED' && 'Processado'}
                {item.status === 'REFUNDED' && 'Reembolsado'}
                {item.status === 'REJECTED' && 'Rejeitado'}
                {item.status === 'CHARGED_BACK' && 'Chargeback (Estorno)'}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default RevenuesTable;
