import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface SidebarProps {
  title: string;
  icon: string;
  path: string;
  subLinks?: {
    title: string;
    path: string;
  }[];
}

function SidebarLink({ title, icon, path, subLinks }: SidebarProps) {
  return (
    <Container>
      <LinkContainer to={path}>
        <div>
          <img src={icon} alt="Icon" />
        </div>
        {title}
      </LinkContainer>
      {subLinks?.map((link) => {
        return <SubLinkContainer to={link.path}>{link.title}</SubLinkContainer>;
      })}
    </Container>
  );
}

export default SidebarLink;

const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

const LinkContainer = styled(Link)`
  display: -webkit-flex;
  align-items: center;
  color: white;
  font: 400 12px Inter;
  transition: ease 0.5s;

  div {
    width: 20px;
    margin-right: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const SubLinkContainer = styled(LinkContainer)`
  margin-left: 40px;
`;
