import React, { ReactNode } from 'react';

import { TableContainer, Table } from '@material-ui/core';
import styled from 'styled-components';
import Colors from 'shared/constants/Colors';

function TableComponent({ children }: { children: ReactNode }) {
  return (
    <StyledTableContainer>
      <Table aria-label="simple table">{children}</Table>
    </StyledTableContainer>
  );
}

export default TableComponent;

const StyledTableContainer = styled(TableContainer)`
  .MuiTableRow-head {
    background-color: #f4f8ff;

    .MuiTableCell-head {
      font: 700 14px Roboto;
      color: #7b7b7b;
      padding: 25px 15px;
    }
  }

  .MuiTableCell-root {
    color: #7b7b7b;
    font-size: 12px;
  }

  .center-cell {
    height: 100%;
    align-items: center;
    display: -webkit-flex;
    justify-content: center;
  }

  img {
    margin: 0;
    width: 60px;
    height: 50px;
    border-radius: 5px;
  }

  .actions {
    .iconButton {
      padding: 5px 10px;
      font-size: 12px;
      border-radius: 10px;
      margin: 0 5px;
    }
  }

  .editHeader {
    padding-top: 10px;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors.primary};

    h2 {
      font: 700 15px Inter;
      padding: 8px 0;
    }
  }
`;
