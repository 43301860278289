import * as yup from 'yup';

export const LiveSetupSchema = yup.object({
  title: yup.string().required('Insira um título para sua live').min(3),
  description: yup.string().max(200, 'Excedeu o máximo de caracteres'),
  category: yup.string().required('Selecione uma categoria'),
  blockComments: yup.boolean(),
  onlyFor24Hrs: yup.boolean(),
  file: yup
    .mixed()
    .notRequired()
    .test('fileFormat', 'Image only', (value) => {
      return value
        ? ['image/png', 'image/jpeg'].includes(value.type) ||
            typeof value === 'string'
        : !value;
    })
});
