import api from '../../../services/api';
import Meditation from './ts/interface/Meditation';
import MeditationCategory from './ts/interface/MeditationCategory';

export const getAllMeditations = async (
  page: number
): Promise<{
  data: Meditation[];
  finalPage: number;
}> =>
  await api.get('/meditation/all', {
    params: {
      page
    }
  });

export const getFilteredMeditations = async (
  page: number,
  format?: string
): Promise<{ data: Meditation[]; finalPage: number }> =>
  await api.get('/meditation/filter', {
    params: {
      page,
      format
    }
  });

export const createMeditation = async (
  formData: FormData
): Promise<Meditation> => await api.post('/meditation', formData);

export const updateMeditation = async (
  id: string,
  formData: FormData
): Promise<string> => await api.put(`/meditation/${id}`, formData);

export const removeMeditation = async (id: string): Promise<string> =>
  await api.delete(`/meditation/${id}`);

export const getMeditationCagories = async (): Promise<MeditationCategory[]> =>
  await api.get('/course/meditation/categories');
