import { toast } from 'react-toastify';
import { useCallback, useEffect } from 'react';
import isAxiosError from 'helpers/isAxiosError';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import {
  getSchedules,
  createSchedule,
  deleteSchedule,
  updateSchedule
} from '../api';
import { useScheduleContext } from '../context';

interface AppointmentData {
  id?: string;
  end: string | Date;
  start: string | Date;
  title: string;
  message: string;
}

const useSchedule = () => {
  const { schedules, appointments, setAppointments, setSchedules } =
    useScheduleContext();

  const fetchSchedules = useCallback(async () => {
    try {
      const response = await getSchedules();

      const prevState: AppointmentModel[] = [];
      if (response.length === 0) {
        setSchedules([]);
        setAppointments([]);
        return;
      }

      response.forEach((schedule) => {
        prevState.push({
          startDate: schedule.start,
          endDate: schedule.end,
          id: schedule.id,
          title: schedule.title
        });
        setSchedules(response);
        setAppointments([...prevState]);
      });
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  }, []);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const editSchedule = async ({
    id,
    end,
    start,
    title,
    message
  }: AppointmentData) => {
    try {
      await updateSchedule({
        id,
        end: new Date(end).toISOString(),
        start: new Date(start).toISOString(),
        title,
        message
      });
      toast.success('Agendamento atualizado com sucesso!');
      await fetchSchedules();
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const newSchedule = async ({
    end,
    start,
    title,
    message
  }: AppointmentData) => {
    try {
      await createSchedule({
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
        title,
        message
      });
      toast.success('Agendamento realizado com sucesso!');
      fetchSchedules();
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const removeSchedule = async (scheduleId: string) => {
    try {
      await deleteSchedule(scheduleId);
      toast.success('Agendamento removido com sucesso!');
      fetchSchedules();
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    schedules,
    appointments,
    newSchedule,
    editSchedule,
    removeSchedule,
    setAppointments
  };
};

export default useSchedule;
