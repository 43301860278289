import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getCategories } from '../api/index';
import LiveStreamCategory from '../api/Response/LiveStreamCategory';
import isAxiosError from '../../../helpers/isAxiosError';

const useCategories = () => {
  const [categories, setCategories] = useState<LiveStreamCategory[]>();

  useEffect(() => {
    const getCategoriesList = async () => {
      try {
        const list = await getCategories();

        setCategories(list);
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    };

    getCategoriesList();
  }, []);

  return { categories };
};

export default useCategories;
