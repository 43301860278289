import React from 'react';
import styled from 'styled-components';

import { Dialog, IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import PlaylistContentForm from './PlaylistContentForm';
import usePlaylist from '../hooks/usePlaylist';
import { CreateSong } from '../ts/song.interface';

interface UploadContentProps {
  open: boolean;
  toggleOpen: () => void;
  handleCreateSong: (values: CreateSong) => void;
}

function UploadContentModal({
  open,
  toggleOpen,
  handleCreateSong
}: UploadContentProps) {
  return (
    <Dialog open={open} onClose={toggleOpen} maxWidth="md" fullWidth>
      <UploadContentModalContainer>
        <header>
          <h3>Novo áudio</h3>
          <IconButton onClick={toggleOpen}>
            <CloseOutlined />
          </IconButton>
        </header>

        <PlaylistContentForm
          callback={(values: CreateSong) => handleCreateSong(values)}
        />
      </UploadContentModalContainer>
    </Dialog>
  );
}

export default UploadContentModal;

const UploadContentModalContainer = styled.div`
  padding: 20px 20px 10px 20px;

  header {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: 700 15px Inter;
      color: #555;
    }
  }
`;
