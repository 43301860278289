import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import isAxiosError from 'helpers/isAxiosError';
import { useCallback, useEffect, useState } from 'react';
import { getPayments, getBillingReport, getAllPayments } from '../api';
import Revenue from '../api/ts/interface/Revenue';

interface RevenueFilters {
  startDate?: string;
  endDate?: string;
  status?: string;
  type?: 'COURSE' | 'SUBSCRIPTION';
}

const useRevenues = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [currentFilters, setCurrentFilters] = useState<RevenueFilters>();

  const [revenues, setRevenues] = useState<Revenue[]>([]);
  const [billingReport, setReport] = useState<{
    subscriptions: number;
    totalBilling: number;
    totalCancelledBilling: number;
  }>({
    subscriptions: 0,
    totalBilling: 0,
    totalCancelledBilling: 0
  });

  const fetchPayments = useCallback(
    (page: number, filters?: RevenueFilters) => {
      (async () => {
        try {
          const { data, totalPages } = await getPayments(page, filters);
          const reports = await getBillingReport();
          setReport(reports);
          setRevenues(data);
          setTotalPages(totalPages);
        } catch (error) {
          if (isAxiosError(error)) {
            toast.error(error.message);
          }
        }
      })();
    },
    []
  );

  const getXslBuffer = async () => {
    try {
      const { data } = await getAllPayments();

      if (!data) {
        toast.error('Desculpe, não foi possível fazer o download');
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ['data']
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      return excelBuffer;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    fetchPayments(currentPage);
  }, []);

  return {
    fetchPayments,
    getXslBuffer,
    revenues,
    billingReport,
    currentPage,
    totalPages,
    setCurrentPage,
    currentFilters,
    setCurrentFilters
  };
};

export default useRevenues;
