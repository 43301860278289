import { ChangeEvent, useEffect, useState } from 'react';
import {
  LibraryMusicOutlined,
  MenuBookOutlined,
  PersonOutlined,
  TuneOutlined,
  TvOutlined
} from '@material-ui/icons';

import IconButton from 'shared/components/IconButton';

import { Pagination, Skeleton } from '@material-ui/lab';
import ManagementData from 'http/management/api/ts/ManagementResponse';
import RevenuesTable from 'http/revenues/components/RevenuesTable';
import useSort from 'shared/hooks/useSort';
import { PagenationContainer } from 'http/revenues/pages/main/styles';
import useRevenues from 'http/revenues/hook/useRevenues';
import Revenue from 'http/revenues/api/ts/interface/Revenue';

import useManagement from '../../hooks/useManagement';
import Section from '../../../../shared/components/Section';
import OverviewItem from '../../components/OverviewItem';
import InformationItem from '../../components/InformationItem';
import FilterDrawer from '../../components/FilterDashboard';

import {
  ManagementContainer,
  OverviewSection,
  InformationSection,
  FilterTxt
} from './styles';

function Management() {
  const [data, setData] = useState<ManagementData>();
  const { getManagementData } = useManagement();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('Todo Período');
  const { revenues, fetchPayments, currentPage, setCurrentPage, totalPages } =
    useRevenues();

  const handleGetData = async () => {
    try {
      setLoading(true);
      const result = await getManagementData(filter);
      console.log(result);
      setData(result);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [filter]);

  const handleChangePagination = async (
    e: ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    fetchPayments(value);
  };

  const { requestSort, sortedItems, sortConfig } = useSort<Revenue>(revenues, {
    key: 'subscription.title' as keyof Revenue,
    direction: 'desc'
  });

  const filterUsers = (values: any) => {
    setFilter(values);

    if (values.period === 'ALL') {
      setFilterText('Todo Período');
    }
    if (values.period === 1) {
      setFilterText('Hoje');
    }
    if (values.period > 1) {
      setFilterText(`Ultimos ${values.period} dias`);
    }
  };

  return (
    <ManagementContainer>
      <Section title="Overview">
        {!loading && data ? (
          <OverviewSection>
            <IconButton onClick={() => setIsFilterOpen((state) => !state)}>
              <TuneOutlined />
            </IconButton>
            <FilterTxt>{filterText}</FilterTxt>
            <OverviewItem
              type="positive-user"
              positiveData={{
                totalUsers: data.totalCommonUsers,
                userNoScriber: data.totalCommonUsersNoScriber,
                userScriber: data.totalCommonUsersSubScriber
              }}
            />
            <OverviewItem
              type="positive"
              positiveData={{
                totalUsers: data.totalCommonUsers,
                anualSubs: data.yearlySubscriptions,
                monthlySubs: data.monthlySubscriptions
              }}
            />
            <OverviewItem
              type="negative"
              negativeData={{
                totalCancels: data.totalCancelled,
                initialSevenCancels: data.cancelledLast7Days,
                initialThirtyCancels: data.cancelledLast30Days
              }}
            />
          </OverviewSection>
        ) : (
          <>
            <Skeleton width="100%" height={70} />
            <Skeleton width="100%" height={70} />
            <Skeleton width="100%" height={70} />
          </>
        )}
      </Section>

      <Section title="Informações">
        {!loading && data ? (
          <InformationSection>
            <InformationItem
              title="Usuários"
              Icon={PersonOutlined}
              thisMoment={data?.additionalInfos?.users.now}
              last24hours={data?.additionalInfos?.users.last24hours}
              thisWeek={data?.additionalInfos?.users.lastWeek}
            />
            <InformationItem
              title="Assistindo Lives"
              Icon={TvOutlined}
              thisMoment={data?.additionalInfos?.lives.now}
              last24hours={data?.additionalInfos?.lives.last24hours}
              thisWeek={data?.additionalInfos?.lives.lastWeek}
            />
            <InformationItem
              title="Ouvindo a playlist"
              Icon={LibraryMusicOutlined}
              thisMoment={data?.additionalInfos?.playlist.now}
              last24hours={data?.additionalInfos?.playlist.last24hours}
              thisWeek={data?.additionalInfos?.playlist.lastWeek}
            />
            <InformationItem
              title="Comprando Cursos"
              Icon={MenuBookOutlined}
              thisMoment={12}
              last24hours={12}
              thisWeek={187}
            />
          </InformationSection>
        ) : (
          <>
            <Skeleton width="100%" height={100} />
            <Skeleton width="100%" height={100} />
            <Skeleton width="100%" height={100} />
          </>
        )}
      </Section>

      <div style={{ maxWidth: '95vw' }}>
        <Section title="Faturamento">
          <RevenuesTable
            sortedItems={sortedItems}
            sortConfig={sortConfig}
            requestSort={requestSort}
          />

          <br />

          <PagenationContainer>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePagination}
              color="primary"
            />
          </PagenationContainer>
        </Section>
      </div>

      <FilterDrawer
        open={isFilterOpen}
        toggleOpen={() => setIsFilterOpen((state) => !state)}
        callback={filterUsers}
        clean={() => {}}
      />
    </ManagementContainer>
  );
}

export default Management;
