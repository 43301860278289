import React from 'react';

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

interface SortingTableHeadProps {
  headCells: {
    id: string;
    name: string;
    align: 'left' | 'center' | 'right' | string;
    sorting: boolean;
  }[];
  sortConfig: { key: string; direction: 'asc' | 'desc' } | null;
  sort: (id: string) => void;
}

function SortingTableHead({
  headCells,
  sortConfig,
  sort
}: SortingTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((item) => (
          <TableCell
            key={item.name}
            align={(item.align as 'left') || 'center'}
            sortDirection={
              sortConfig?.key === item.id ? sortConfig.direction : 'asc'
            }
          >
            {item.sorting ? (
              <TableSortLabel
                active={sortConfig?.key === item.id}
                direction={
                  sortConfig?.key === item.id ? sortConfig.direction : 'asc'
                }
                onClick={() => sort(item.id)}
              >
                {item.name}
              </TableSortLabel>
            ) : (
              item.name
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default SortingTableHead;
