import { useHistory } from 'react-router';
import { Fade } from '@material-ui/core';
import useESP from 'http/ESP/hooks/useESP';
import { ESPData } from 'http/ESP/ts/esp.interface';
import CourseForm from 'http/ESP/components/CourseForm';
import Section from 'shared/components/Section';

import { NewCourseContainer, NewCourseSection } from './styles';

function NewESP() {
  const { createESP } = useESP();
  const history = useHistory();
  const handleCreateNewCourse = async (values: ESPData) => {
    const data = await createESP(values);

    history.push(`/espiritualidade/${data?.id}`);
  };
  return (
    <Fade in>
      <NewCourseContainer>
        <Section title="Nova espiritualidade">
          <NewCourseSection>
            <CourseForm callback={handleCreateNewCourse} />
          </NewCourseSection>
        </Section>
      </NewCourseContainer>
    </Fade>
  );
}

export default NewESP;
