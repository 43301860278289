import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Formik, Form, FormikValues } from 'formik';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import styled from 'styled-components';
import Button from 'shared/components/Button';

import usePlans from '../hooks/usePlans';
import { CreatePlanSchema, UpdatePlanSchema } from '../validation/PlanSchema';
import { Plan } from '../ts/plan.interface';
import Permission from '../ts/permission.interface';

type PlansFormProps = {
  initialValues?: Plan;
  edit?: boolean;
  refresh?: () => void;
};

function PlansForm({ initialValues, edit, refresh }: PlansFormProps) {
  const history = useHistory();
  const [permissions, setPermissions] = useState<Permission[]>();
  const { editPlan, createNewPlan, listPermissions } = usePlans();

  const handleUpdatePlan = async (data: FormikValues) => {
    if (initialValues && refresh && edit) {
      await editPlan(initialValues.id, {
        title: data.title,
        description: data.description,
        amount: data.amount,
        period: data.period,
        installments: data.installments
      });

      refresh();
    }
  };

  const handleCreatePlan = async (data: FormikValues) => {
    await createNewPlan({
      title: data.title,
      description: data.description,
      amount: parseInt(data.amount, 10),
      period: data.period,
      installments: data.installments,
      permissions: data.permissions
    });

    history.push('/plans');
  };

  const getPermissions = async () => {
    setPermissions(await listPermissions());
  };

  useEffect(() => {
    if (!edit) {
      getPermissions();
    }
  }, []);

  return (
    <PlansFormContainer>
      <Formik
        initialValues={
          initialValues || {
            title: '',
            amount: 0,
            installments: 1,
            period: '',
            description: '',
            permissions: []
          }
        }
        validationSchema={edit ? UpdatePlanSchema : CreatePlanSchema}
        onSubmit={(values) =>
          edit ? handleUpdatePlan(values) : handleCreatePlan(values)
        }
      >
        {({ values, handleChange, errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Nome"
                    placeholder="insira o nome do plano"
                    variant="outlined"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    helperText={touched.title && errors.title}
                    error={!!touched.title && !!errors.title}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Valor"
                    name="amount"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                    helperText={touched.amount && errors.amount}
                    error={!!touched.amount && !!errors.amount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <strong>R$</strong>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!touched.period && !!errors.period}
                >
                  <InputLabel id="course-filter-label">Período</InputLabel>
                  <Select
                    labelId="course-filter-label"
                    id="course-filter"
                    name="period"
                    label="Período"
                    value={values.period}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    <MenuItem value="MONTHLY">Mensal</MenuItem>
                    <MenuItem value="QUARTERLY">Quadrimestral</MenuItem>
                    <MenuItem value="SEMESTRALLY">Semestral</MenuItem>
                    <MenuItem value="YEARLY">Anual</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Descrição"
                    placeholder="Insira uma descrição dos benefícios"
                    variant="outlined"
                    name="description"
                    multiline
                    minRows={3}
                    value={values.description}
                    onChange={handleChange}
                    helperText={touched.description && errors.description}
                    error={!!touched.description && !!errors.description}
                  />
                </FormControl>
              </Grid>

              {!edit && (
                <Grid item xs={12}>
                  <span>Permissões</span>
                  <br />
                  {permissions?.map((permission) => {
                    return (
                      <FormControlLabel
                        label={permission.name}
                        control={
                          <Checkbox
                            name="permissions"
                            color="primary"
                            value={permission.id}
                            onChange={handleChange}
                          />
                        }
                      />
                    );
                  })}
                </Grid>
              )}

              <div className="buttonWrapper">
                <Button size="lg" type="submit">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </PlansFormContainer>
  );
}

export default PlansForm;

const PlansFormContainer = styled.div`
  padding: 30px 0;

  span {
    font: 400 14px Roboto;
  }

  .buttonWrapper {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
`;
