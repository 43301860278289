import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Fade,
  CircularProgress
} from '@material-ui/core';

import { RefreshOutlined } from '@material-ui/icons';

import Button from 'shared/components/Button';
import Notification from 'http/notification/api/ts/interface/Notification';
import IconButton from 'shared/components/IconButton';

import { NotificationProps } from '../../ts/notification.interface';

import useNotification from '../../hooks/useNotification';

import EnhancedTable from '../../components/EnhancedTable';
import FilterForm from '../../components/Filter';
import Table from '../../../../shared/components/Table';
import Section from '../../../../shared/components/Section';
import {
  NotificationPageContainer,
  NotificationManagementSection
} from './styles';
import Filter from '../../ts/filter.interface';

const status = {
  accomplished: { name: 'Realizadas', value: 'ACCOMPLISHED' },
  scheduled: { name: 'Agendadas', value: 'SCHEDULING' },
  cancelled: { name: 'Canceladas', value: 'CANCELLED' }
};

function NotificationPage() {
  const { getNotificationByStatus, removeNotification, editNotification } =
    useNotification();
  const [notificationStatus, setNotificationStatus] = useState(
    status.scheduled
  );
  const [filters, setFilters] = useState<Filter>();
  const [notifications, setNotifications] = useState<Notification[]>();

  const handleGetNotifications = async () => {
    setNotifications(
      await getNotificationByStatus(notificationStatus.value, filters)
    );
  };

  const handleDelete = async (id: string) => {
    await removeNotification(id);
    await handleGetNotifications();
  };

  const handleFilter = (values: Filter) => {
    setFilters(values);
  };

  const handleCleanFilters = () => {
    setFilters({});
  };

  const handleEdit = async (id: string, values: NotificationProps) => {
    await editNotification(id, values);
    await handleGetNotifications();
  };

  useEffect(() => {
    handleGetNotifications();
  }, [notificationStatus, filters]);

  return (
    <Fade in>
      <NotificationPageContainer>
        <Section
          title={`Gerenciar Notificações - ${notificationStatus.name}`}
          options={[
            <Link to="/notification/new">
              <Button text="Criar nova notificação" size="sm" />
            </Link>,
            <IconButton
              className="iconButton"
              onClick={() => handleGetNotifications()}
            >
              <RefreshOutlined />
            </IconButton>
          ]}
        >
          <NotificationManagementSection>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    onClick={() => setNotificationStatus(status.scheduled)}
                  >
                    Agendadas
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => setNotificationStatus(status.accomplished)}
                  >
                    Realizadas
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => setNotificationStatus(status.cancelled)}
                  >
                    Erro ou canceladas
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4}>
                    <FilterForm
                      handleFilter={handleFilter}
                      cleanFilters={handleCleanFilters}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {notifications ? (
              <EnhancedTable
                data={notifications}
                status={notificationStatus}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  placeItems: 'center',
                  padding: '25px 0'
                }}
              >
                <CircularProgress />
              </div>
            )}
          </NotificationManagementSection>
        </Section>
      </NotificationPageContainer>
    </Fade>
  );
}

export default NotificationPage;
