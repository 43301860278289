import { Route, Switch } from 'react-router-dom';

import { useState } from 'react';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import MainContentContainer from '../../components/MainContentContainer';

import Management from '../../../management/pages/main';
import WatchLive from '../../../lives/pages/LiveStream';
import LivesList from '../../../lives/pages/LivesList';
import LiveSetup from '../../../lives/pages/LiveSetup';
import LiveUpdate from '../../../lives/pages/LiveUpdate';
import UploadVideo from '../../../lives/pages/UploadVideo';
import VideoSetup from '../../../lives/pages/VideoSetup';
import Categories from '../../../categories/pages/main';
import Notification from '../../../notification/pages/main';
import Schedule from '../../../schedule/pages/main';
import CreateNotification from '../../../notification/pages/CreateNotification';
import CoursesList from '../../../courses/pages/CoursesList';
import CourseContent from '../../../courses/pages/CourseContent';
import NewCourse from '../../../courses/pages/NewCourse';
import ManageCourses from '../../../courses/pages/ManageCourses';
import MeditationsList from '../../../meditations/pages/Main';
import Playlist from '../../../playlist/pages/main';
import ESPList from '../../../ESP/pages/CoursesList';
import ESPContent from '../../../ESP/pages/CourseContent';
import NewESP from '../../../ESP/pages/NewCourse';
import ManageESP from '../../../ESP/pages/ManageCourses';
import Plans from '../../../plans/pages/main';
import UsersList from '../../../users/pages/main';
import UserDetails from '../../../users/pages/Details';
import UserPayment from '../../../users/pages/PaymentHistory';
import Revenues from '../../../revenues/pages/main';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div>
      <Sidebar
        open={isMenuOpen}
        toggleDrawer={() => setIsMenuOpen((state) => !state)}
      />
      <Navbar handleOpenMenu={() => setIsMenuOpen((state) => !state)} />

      <MainContentContainer>
        <Switch>
          <Route component={Management} path="/management" />

          <Route component={LivesList} path="/lives" />
          <Route component={LiveSetup} path="/live-setup" />
          <Route component={UploadVideo} path="/upload-video" />
          <Route component={VideoSetup} path="/video-setup" />
          <Route component={LiveUpdate} path="/update/:id" />
          <Route
            component={WatchLive}
            path="/watch-live/:sessionId/:token/:id/:isRecording"
          />
          <Route component={Categories} path="/categories" />

          <Route component={Notification} path="/notification" exact />
          <Route component={CreateNotification} path="/notification/new" />

          <Route component={Schedule} path="/schedule" />

          <Route component={NewCourse} path="/courses/new" exact />
          <Route component={ManageCourses} path="/courses/manage" exact />
          <Route component={CourseContent} path="/courses/:id" exact />
          <Route component={CoursesList} path="/courses" exact />

          <Route component={MeditationsList} path="/meditations" exact />

          <Route component={Playlist} path="/playlist" />

          <Route component={NewESP} path="/espiritualidade/new" exact />
          <Route component={ManageESP} path="/espiritualidade/manage" exact />
          <Route component={ESPContent} path="/espiritualidade/:id" exact />
          <Route component={ESPList} path="/espiritualidade" exact />

          <Route component={Plans} path="/plans" exact />
          <Route component={UserDetails} path="/users/:id" exact />
          <Route component={UserPayment} path="/user/payments/:id" exact />
          <Route component={UsersList} path="/users" exact />

          <Route component={Revenues} path="/revenues" exact />
        </Switch>
      </MainContentContainer>
    </div>
  );
}

export default Home;
