import React, { FormEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IconButton } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';

import { useUser } from '../../../shared/contexts/AuthContext';
import ChatMessage from './ChatMessage';
import useChat from '../hooks/useChat';

function MobileChat({ streamId }: { streamId: string }) {
  const { user } = useUser();
  const { messages, sendMessage } = useChat(streamId);

  const [message, setMessage] = useState('');

  const chatFooterRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (user) {
      sendMessage({
        id: Math.random(),
        name: user?.name,
        date: new Date(),
        thumb: user?.avatar,
        message
      });
    }
    setMessage('');
  };

  useEffect(() => {
    chatFooterRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <Wrapper>
      <ChatContainer>
        <Chat>
          {messages.map((message) => {
            return (
              <ChatMessage
                userImg={message.thumb}
                message={message.message}
                userName={message.name}
              />
            );
          })}
          <div ref={chatFooterRef} />
        </Chat>

        <form onSubmit={handleSendMessage}>
          <ChatInput
            name="chatInput"
            value={message}
            autoComplete="off"
            placeholder="Digite sua mensagem"
            onChange={(e) => setMessage(e.target.value)}
          />
          <IconButton onClick={handleSendMessage} type="submit">
            <SendOutlined />
          </IconButton>
        </form>
      </ChatContainer>
    </Wrapper>
  );
}

export default MobileChat;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 15px;
`;

const ChatContainer = styled.div`
  display: none;
  color: white;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);

  form {
    padding: 20px 10px;
    width: 100%;
    display: flex;
    gap: 15px;

    .MuiIconButton-root {
      padding: 0;
      margin-left: 5px;
    }

    .MuiSvgIcon-root {
      color: white;
    }
  }

  @media (max-width: 599px) {
    display: block;
  }
`;

const Chat = styled.div`
  height: fit-content;
  max-height: 28vh;
  display: -webkit-flex;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ChatInput = styled.input`
  background: none;
  border: 1px solid white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  outline: 0;
  padding: 10px 15px;
  font: 400 14px Roboto;
`;
