import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from 'shared/components/Button';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import {
  AccessTimeOutlined,
  CheckRounded,
  CloseRounded,
  DeleteOutlined,
  EditOutlined,
  TimerOutlined
} from '@material-ui/icons';
import IconButton from '../../../shared/components/IconButton';

import LiveDefault from '../../../assets/images/live-default.png';
import LiveListData from '../api/Response/LiveListData';
import useLiveHook from '../hooks/useLiveHook';

function LiveItem({
  liveData,
  refreshCallback
}: {
  liveData: LiveListData;
  refreshCallback: () => void;
}) {
  const { deleteLive } = useLiveHook();

  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleDeleteLive = async () => {
    await deleteLive(liveData.id);
    toast.success('A live foi deletada com sucesso');
    refreshCallback();
  };

  const isAvailable = moment(liveData.availableAt).isBefore(new Date());

  return (
    <LiveItemContainer>
      <Thumbnail background={liveData.thumb || LiveDefault} />

      <LiveInfo>
        <div>
          <h2>{liveData.title !== '' ? liveData.title : 'Live sem título'}</h2>
          <span>
            {liveData.viewers} visualizações -{' '}
            {new Date(liveData.created_at).toLocaleDateString('pt-BR')}
          </span>
          <br />
          <span className="available">
            Disponível em {moment(liveData.availableAt).format('DD/MM/yyyy')},
            às {moment(liveData.availableAt).format('HH:mm')}
            {isAvailable ? <CheckRounded /> : <AccessTimeOutlined />}
          </span>
        </div>

        <div className="options">
          <IconButton onClick={() => setDeleteDialog(true)}>
            <DeleteOutlined />
          </IconButton>
          <Link to={`/update/${liveData.id}`}>
            <IconButton>
              <EditOutlined />
            </IconButton>
          </Link>
        </div>
      </LiveInfo>

      <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
        <DialogTitle>Deseja excluir esta live?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Essa ação não poderá ser desfeita
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outline" onClick={() => setDeleteDialog(false)}>
            Cancelar
          </Button>
          <Button variant="delete" onClick={handleDeleteLive}>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </LiveItemContainer>
  );
}

export default LiveItem;

const LiveItemContainer = styled.div``;

const LiveInfo = styled.div`
  padding: 8px 0;
  width: 100%;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  h2 {
    color: #333;
    font: 500 14px Inter;
    margin: 0;
  }

  span {
    font: 300 10px Inter;
    color: #555;
  }

  .available {
    display: flex;
    align-items: center;
    gap: 5px;

    .MuiSvgIcon-root {
      padding: 3px;
    }
  }

  .options {
    width: 100%;
    display: -webkit-flex;
    justify-content: flex-end;
    gap: 5px;
  }
`;

const Thumbnail = styled.div<{ background: string }>`
  height: 250px;
  border-radius: 10px;
  transition: ease-in 0.3s;
  background-image: url(${(p) => p.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  :hover {
    transform: scale(1.02);
  }
`;
