import { useRef } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';
import Button from 'shared/components/Button';
import { Content, ContentData } from '../ts/course.interface';
import CourseContentSchema from '../validation/CourseContentSchema';

interface ContentFormProps {
  callback: (values: ContentData) => void;
  format: string;
  courseId: string;
  initialValues?: Content;
}

function ContentForm({
  callback,
  format,
  courseId,
  initialValues
}: ContentFormProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const thumbInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const selectFile = () => {
    fileInputRef?.current?.click();
  };
  const selectThumb = () => {
    thumbInputRef?.current?.click();
  };

  const handleFileUpload = (file: File | Blob | MediaSource | undefined) => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);

      if (format === 'VIDEO') {
        videoRef.current?.setAttribute('src', fileUrl);
      }

      if (format === 'AUDIO') {
        audioRef.current?.setAttribute('src', fileUrl);
      }
    }
  };

  return (
    <ContentFormContainer>
      <Formik
        initialValues={{
          file: initialValues?.url || new File([], ''),
          thumb: initialValues?.thumb || undefined,
          title: initialValues?.title || '',
          description: initialValues?.description || '',
          duration_millis: initialValues?.duration_millis || 0,
          time_stop: 0,
          position: initialValues?.position || 0,
          course_id: courseId,
          format
        }}
        onSubmit={(values) => callback(values)}
        validationSchema={CourseContentSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          errors,
          touched,
          isSubmitting
        }) => (
          <Form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    onClick={selectFile}
                    label="Arquivo da aula"
                    variant="outlined"
                    value={
                      typeof values.file === 'string'
                        ? values.file
                        : values.file.name
                    }
                    helperText={touched.file && errors.file}
                    error={!!touched.file && !!errors.file}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleFileUpload(e?.currentTarget?.files?.[0]);
                      setFieldValue('file', e?.currentTarget?.files?.[0]);
                    }}
                    accept={`${
                      format === 'AUDIO' || format === 'VIDEO'
                        ? `${format.toLowerCase()}/*`
                        : 'application/pdf'
                    }`}
                    name="file"
                    hidden
                  />

                  <video
                    ref={videoRef}
                    hidden
                    onLoadedMetadata={(e) =>
                      setFieldValue(
                        'duration_millis',
                        e.currentTarget.duration * 1000
                      )
                    }
                  >
                    <track kind="captions" />
                  </video>
                  <audio
                    ref={audioRef}
                    hidden
                    onLoadedMetadata={(e) =>
                      setFieldValue(
                        'duration_millis',
                        e.currentTarget.duration * 1000
                      )
                    }
                  >
                    <track kind="captions" />
                  </audio>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    aria-readonly
                    onClick={selectThumb}
                    label="Imagem"
                    variant="outlined"
                    value={
                      values.thumb
                        ? typeof values.thumb === 'string'
                          ? values.thumb
                          : 'Arquivo carregado'
                        : ''
                    }
                    helperText={touched.thumb && errors.thumb}
                    error={!!touched.thumb && !!errors.thumb}
                    InputProps={{
                      endAdornment: values.thumb ? (
                        <img
                          className="inputThumbIcon"
                          src={
                            typeof values.thumb === 'object'
                              ? URL.createObjectURL(values.thumb)
                              : values.thumb
                          }
                          alt="thumb"
                          height="25px"
                          width="40px"
                        />
                      ) : (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={thumbInputRef}
                    onChange={(value) => {
                      if (value.target.files?.length) {
                        setFieldValue('thumb', value.target.files[0]);
                      }
                    }}
                    name="thumb"
                    hidden
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Nome da aula"
                    placeholder="Ex: Aula #1 - Meditação quantica"
                    name="title"
                    helperText={touched.title && errors.title}
                    error={!!touched.title && !!errors.title}
                    value={values.title}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Descrição breve"
                    placeholder="Insira uma descrição breve da sua aula"
                    name="description"
                    multiline
                    minRows={3}
                    helperText={touched.description && errors.description}
                    error={!!touched.description && !!errors.description}
                    value={values.description}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <div className="buttonWrapper">
                <Button size="lg">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </ContentFormContainer>
  );
}

export default ContentForm;

const ContentFormContainer = styled.div`
  padding: 20px 0 40px 0;

  .buttonWrapper {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
`;
