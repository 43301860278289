import Colors from 'shared/constants/Colors';
import styled from 'styled-components';

interface ButtonProps {
  variant: 'primary' | 'outline' | 'delete';
  size?: 'sm' | 'md' | 'lg' | 'full';
}

export const ButtonContainer = styled.button<ButtonProps>`
  padding: 10px 25px;
  color: ${(p) => {
    switch (p.variant) {
      case 'primary':
        return 'white';
      case 'outline':
        return Colors.primary;
      case 'delete':
        return 'red';
      default:
        return 'white';
    }
  }};
  background: ${(p) => {
    switch (p.variant) {
      case 'primary':
        return Colors.primary;
      case 'outline':
        return 'white';
      case 'delete':
        return 'white';
      default:
        return 'white';
    }
  }};
  border: ${(p) => {
    switch (p.variant) {
      case 'primary':
        return 'none';
      case 'outline':
        return `1px solid ${Colors.primary}`;
      case 'delete':
        return '1px solid red';
      default:
        return 'white';
    }
  }};
  ${(p) => {
    switch (p.size) {
      case 'sm':
        return 'font: 500 14px Inter; padding: 10px 15px';
      case 'md':
        return 'font: 500 16px Inter';
      case 'lg':
        return 'font: 500 18px Inter; padding: 20px 30px';
      case 'full':
        return 'font: 500 18px Inter; width: 100%; padding: 20px 30px;';
      default:
        return 'font: 500 16px Inter';
    }
  }};
  border-radius: 10px;
`;
