import { toast } from 'react-toastify';
import { createPlan, getPermissions, getPlans, updatePlan } from '../api';
import isAxiosError from '../../../helpers/isAxiosError';
import { CreatePlan, UpdatePlan } from '../ts/plan.interface';

const usePlans = () => {
  const getAllPlans = async () => {
    try {
      const plans = await getPlans();

      return plans;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const editPlan = async (planId: string, planData: UpdatePlan) => {
    try {
      await updatePlan(planId, planData);

      toast.success('Plano atualizado com sucesso');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const createNewPlan = async (planData: CreatePlan) => {
    try {
      const res = createPlan(planData);
      toast.success('Plano criado com sucesso');
      return res;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const listPermissions = async () => {
    try {
      const permissions = await getPermissions();

      return permissions;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    getAllPlans,
    editPlan,
    createNewPlan,
    listPermissions
  };
};

export default usePlans;
