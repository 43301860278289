import api from '../../../services/api';
import Filter from '../ts/filter.interface';
import Notification from './ts/interface/Notification';

export const createNotification = async (data: FormData) =>
  await api.post('/notification', data);

export const getAllnotifications = async (): Promise<Notification[]> =>
  await api.get('/notification');

export const getByStatus = async (
  status: string,
  filters: Filter | undefined
): Promise<Notification[]> =>
  await api.get(`/notification?status=${status}`, { params: { ...filters } });

export const updateNotification = async (notifyId: string, data: FormData) =>
  await api.put(`/notification/${notifyId}`, data);

export const deleteNotification = async (notifyId: string): Promise<string> =>
  await api.delete(`/notification/${notifyId}`);
