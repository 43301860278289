import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';

import Course, { CreateMeditation } from '../api/ts/interface/Meditation';
import MeditationSchema from '../validation/MeditationSchema';
import Button from '../../../shared/components/Button';
import useMeditation from '../hooks/useMeditations';
import MeditationCategory from '../api/ts/interface/MeditationCategory';

function MeditationForm({
  initialValues,
  callback,
  format
}: {
  initialValues?: Partial<Course>;
  callback: (values: CreateMeditation) => void;
  format: 'VIDEO' | 'AUDIO';
}) {
  const { getCategories } = useMeditation();
  const [cIndex, setIndex] = useState(0);

  const [categories, setCategories] = useState<MeditationCategory[]>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const thumbInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const selectFile = () => {
    fileInputRef?.current?.click();
  };
  const selectThumb = () => {
    thumbInputRef?.current?.click();
  };

  const handleFileUpload = (file: File | Blob | MediaSource | undefined) => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);

      if (format === 'VIDEO') {
        videoRef.current?.setAttribute('src', fileUrl);
      }

      if (format === 'AUDIO') {
        audioRef.current?.setAttribute('src', fileUrl);
      }
    }
  };

  const handleGeMeditations = async () => {
    setCategories((await getCategories()) as MeditationCategory[]);
  };

  useEffect(() => {
    handleGeMeditations();
  }, []);

  const file: File | string = initialValues?.url || new File([], '');

  return (
    <MeditationFormContainer>
      <Formik
        enableReinitialize
        initialValues={{
          title: initialValues?.title || '',
          format,
          file,
          thumb: initialValues?.thumb,
          duration_millis: initialValues?.duration_millis || 0,
          description: initialValues?.description,
          subCategory: initialValues?.subCategory?.length
            ? initialValues?.subCategory
            : '',
          category: initialValues?.category?.length
            ? initialValues?.category
            : ''
        }}
        onSubmit={(values) => callback(values)}
        validationSchema={MeditationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    value={values.title}
                    name="title"
                    onChange={handleChange}
                    label="Nome da Meditação"
                    variant="outlined"
                    helperText={touched.title && errors.title}
                    error={!!touched.title && !!errors.title}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="meditation-filter-label"
                    error={!!touched.category && !!errors.category}
                  >
                    Categoria
                  </InputLabel>
                  <Select
                    labelId="meditation-filter-label"
                    value={values.category}
                    onChange={(value) => {
                      const index = categories?.findIndex(
                        (item) => item.name === value.target.value
                      );
                      setIndex(index as number);
                      setFieldValue('category', value.target.value);
                    }}
                    error={!!touched.category && !!errors.category}
                    id="meditation-filter"
                    name="category"
                    label="Categoria"
                  >
                    <MenuItem value="">
                      <em>Selecione o curso</em>
                    </MenuItem>
                    {categories?.map((item) => (
                      <MenuItem value={item.name} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="meditation-filter-label"
                    error={!!touched.subCategory && !!errors.subCategory}
                  >
                    Sub-Categoria
                  </InputLabel>
                  <Select
                    labelId="meditation-filter-label"
                    id="meditation-filter"
                    value={values.subCategory}
                    error={!!touched.subCategory && !!errors.subCategory}
                    onChange={handleChange}
                    name="subCategory"
                    label="Sub-Categoria"
                  >
                    <MenuItem value="">
                      <em>Selecione o curso</em>
                    </MenuItem>
                    {categories?.[cIndex]?.subCategory?.map((subCategory) => (
                      <MenuItem value={subCategory} key={subCategory}>
                        {subCategory}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    onClick={selectFile}
                    label="Arquivo"
                    variant="outlined"
                    value={
                      typeof values.file === 'string'
                        ? values.file
                        : values.file.name
                    }
                    helperText={touched.file && errors.file}
                    error={!!touched.file && !!errors.file}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleFileUpload(e?.currentTarget?.files?.[0]);
                      setFieldValue('file', e?.currentTarget?.files?.[0]);
                    }}
                    accept={format && `${format.toLowerCase()}/*`}
                    name="file"
                    hidden
                  />

                  <video
                    ref={videoRef}
                    hidden
                    onLoadedMetadata={(e) =>
                      setFieldValue(
                        'duration_millis',
                        e.currentTarget.duration * 1000
                      )
                    }
                  >
                    <track kind="captions" />
                  </video>
                  <audio
                    ref={audioRef}
                    hidden
                    onLoadedMetadata={(e) =>
                      setFieldValue(
                        'duration_millis',
                        e.currentTarget.duration * 1000
                      )
                    }
                  >
                    <track kind="captions" />
                  </audio>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    aria-readonly
                    onClick={selectThumb}
                    label="Imagem"
                    variant="outlined"
                    value={
                      values.thumb
                        ? typeof values.thumb === 'string'
                          ? values.thumb
                          : 'Arquivo carregado'
                        : ''
                    }
                    helperText={touched.thumb && errors.thumb}
                    error={!!touched.thumb && !!errors.thumb}
                    InputProps={{
                      endAdornment: values.thumb ? (
                        <img
                          className="inputThumbIcon"
                          src={
                            typeof values.thumb === 'object'
                              ? URL.createObjectURL(values.thumb)
                              : values.thumb
                          }
                          alt="thumb"
                          height="25px"
                          width="40px"
                        />
                      ) : (
                        <InputAdornment position="end">
                          <PublishOutlined color="action" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={thumbInputRef}
                    onChange={(value) => {
                      if (value.target.files?.length) {
                        setFieldValue('thumb', value.target.files[0]);
                      }
                    }}
                    name="thumb"
                    hidden
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Descrição da meditação"
                    variant="outlined"
                    value={values.description}
                    helperText={touched.description && errors.description}
                    error={!!touched.description && !!errors.description}
                    name="description"
                    onChange={handleChange}
                    multiline
                    minRows={3}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <Button type="submit" size="lg">
                  {isSubmitting ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </MeditationFormContainer>
  );
}

export default MeditationForm;

const MeditationFormContainer = styled.div`
  padding: 20px 0 40px 0;

  .buttonWrapper {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
`;
