import { DropzoneRootProps } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const UploadVideoContainer = styled.div``;

export const UploadSection = styled.div`
  display: grid;
  place-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  height: 75vh;
`;

export const DialogContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;

  img {
    width: 150px;

    @media (max-width: 599px) {
      width: 80px;
    }
  }

  h1 {
    font: 700 25px Inter;
    color: #8e8e8e;
  }

  p {
    font-size: 14px;
    width: 50%;
    color: #8e8e8e;
    text-align: center;
  }
`;

export const UploadContainer = styled(DialogContainer)`
  border-color: ${(p) => getColor(p)};
  transition: border 0.24s ease-in-out;
  border-style: dashed;
  border-width: 2px;
  width: 100%;
`;
