import { useContext } from 'react';
import { VideoUploadContext } from '../../../shared/contexts/UploadContext';

const useUpload = () => {
  const context = useContext(VideoUploadContext);

  return context;
};

export default useUpload;
