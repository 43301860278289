import React from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';

import {
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import Filters from '../../users/ts/filter.interface';
import Button from '../../../shared/components/Button';

type DrawerProps = {
  open: boolean;
  toggleOpen: () => void;
  callback: (values: Filters) => void;
  clean: () => void;
};

interface UF {
  id: number;
  title: string;
  value: string;
}

function FilterDrawer({ open, toggleOpen, callback, clean }: DrawerProps) {
  return (
    <Drawer open={open} anchor="right" onClose={toggleOpen}>
      <FilterContainer>
        <div className="title">
          <h1>Filtros</h1>
          <span onClick={toggleOpen}>X</span>
        </div>
        <p>
          Você pode limpar os filtros clicando{' '}
          <a href="#" style={{ color: 'rebeccapurple' }} onClick={clean}>
            aqui
          </a>
        </p>

        <Formik
          initialValues={{
            period: 'ALL'
            // startDate: new Date(),
            // endDate: new Date()
          }}
          onSubmit={callback}
        >
          {({ values, setFieldValue, handleChange }) => (
            <Form>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="period-label">
                  Ultimo(s) {values?.period} dia(s)
                </InputLabel>
                <Select
                  labelId="period-label"
                  id="demo-simple-select-outlined"
                  label={`Ultimo(s) ${values?.period} dia(s)`}
                  defaultValue=""
                  value={values.period}
                  onChange={handleChange}
                  name="period"
                >
                  <MenuItem value={1}>Hoje</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                  <MenuItem value="ALL">Todo Período</MenuItem>
                </Select>
              </FormControl>
              {/* <p>Ou filtre por Data</p>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    value={values.startDate || undefined}
                    onChange={(date) => setFieldValue('startDate', date)}
                    id="date-picker"
                    label="Data Inicial"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>

              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="endDate"
                    value={values.endDate}
                    onChange={(date) => setFieldValue('endDate', date)}
                    id="date-picker"
                    label="Data Final"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl> */}
              <Button text="Filtrar" size="full" />
            </Form>
          )}
        </Formik>
      </FilterContainer>
    </Drawer>
  );
}

export default FilterDrawer;

export const FilterContainer = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 40px 20px;

  .title {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    font: 600 16px Inter;
    padding-bottom: 20px;

    span {
      cursor: pointer;
    }
  }

  p {
    font: 400 14px Roboto;
    padding-bottom: 30px;
  }

  form {
    width: 100%;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;
