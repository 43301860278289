import { useEffect, useState } from 'react';
import { app } from '../../../firebase';

const useChat = (streamId: string) => {
  const [messages, setMessages] = useState<
    Array<{
      id: number;
      thumb: string;
      name: string;
      message: string;
      date: Date;
    }>
  >([]);

  useEffect(() => {
    app
      .database()
      .ref(`/chats/${streamId}`)
      .on('value', (snapshot) => {
        const messagings: Array<{
          id: number;
          thumb: string;
          name: string;
          message: string;
          date: Date;
        }> = [];
        snapshot.forEach((snap) => {
          messagings.push(snap.val());
        });
        setMessages(messagings);
      });
  }, []);

  const sendMessage = (thread: {
    id: number;
    thumb: string;
    name: string;
    message: string;
    date: Date;
  }) => {
    if (thread.message === '') {
      return;
    }
    app.database().ref(`/chats/${streamId}`).push(thread);
  };

  return { messages, sendMessage };
};

export default useChat;
