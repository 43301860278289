import isAxiosError from 'helpers/isAxiosError';
import { toast } from 'react-toastify';
import { getInfo } from '../api';

const useManagement = () => {
  const getManagementData = async (filter: any) => {
    try {
      const data = await getInfo(filter);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return { getManagementData };
};

export default useManagement;
