import styled from 'styled-components';

export const SectionContainer = styled.div`
  max-width: 2300px;
`;

export const SectionHeader = styled.header`
  display: -webkit-flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0;

  h2 {
    font: 600 16px Inter;
    margin: 0;

    a {
      margin-right: 15px;
    }

    @media (max-width: 599px) {
      font: 600 12px Inter;
    }
  }

  .options {
    display: -webkit-flex;
    gap: 8px;

    @media (max-width: 599px) {
      button {
        font-size: 12px;
      }
    }
  }
`;
