import isAxiosError from 'helpers/isAxiosError';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  createMeditation,
  getFilteredMeditations,
  getMeditationCagories,
  removeMeditation,
  updateMeditation
} from '../api';
import { CreateMeditation } from '../api/ts/interface/Meditation';

const useMeditation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [contentType, setContentType] = useState<'AUDIO' | 'VIDEO'>();

  const fetchMeditations = useCallback(
    async (page = 1, format = contentType) => {
      try {
        const { data, finalPage } = await getFilteredMeditations(page, format);
        setTotalPages(finalPage);

        return data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    []
  );

  const newMeditation = useCallback(
    async ({
      title,
      description,
      format,
      thumb,
      category,
      file,
      subCategory,
      duration_millis
    }: CreateMeditation) => {
      try {
        const data = new FormData();
        data.append('title', title);
        data.append('format', format);
        data.append('category', category);
        data.append('subCategory', subCategory);
        if (thumb) {
          data.append('thumb', thumb);
        }
        if (description) {
          data.append('description', description);
        }
        data.append('file', file);
        data.append('duration_millis', Math.round(duration_millis).toString());
        await createMeditation(data);
        toast.success('Criado com sucesso');
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    []
  );

  const editMeditation = useCallback(
    async (
      id,
      {
        title,
        description,
        format,
        thumb,
        category,
        file,
        subCategory,
        duration_millis
      }: CreateMeditation
    ) => {
      try {
        const data = new FormData();
        data.append('title', title);

        data.append('format', format);
        data.append('category', category);
        data.append('subCategory', subCategory);
        if (thumb) {
          data.append('thumb', thumb);
        }
        if (description) {
          data.append('description', description);
        }
        data.append('file', file);
        data.append('duration_millis', Math.round(duration_millis).toString());
        await updateMeditation(id, data);
        toast.success('atualizado com sucesso');
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    []
  );

  const deleteMeditation = useCallback(async (id: string) => {
    try {
      await removeMeditation(id);
      toast.success('Meditação deletada');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  }, []);

  const getCategories = async () => {
    try {
      const data = getMeditationCagories();

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    editMeditation,
    fetchMeditations,
    newMeditation,
    deleteMeditation,
    contentType,
    setContentType,
    getCategories,
    currentPage,
    setCurrentPage,
    totalPages
  };
};

export default useMeditation;
