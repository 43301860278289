import { HTMLProps } from 'react';
import styled from 'styled-components';

import CloudBg from '../../../../assets/images/cloud.png';

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #8e96ff;
  background-image: url(${CloudBg});
  background-size: 600px 400px;
  background-repeat: round space;
  background-origin: border-box;
`;

export const LoginBox = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 100px;

  img {
    width: 50%;
    margin-bottom: 40px;
  }

  h1 {
    font: 700 25px Inter;
    color: #3b4c68;
  }

  form {
    width: 55%;

    .formControl {
      margin: 15px 0;

      input {
        color: #03014c;
        font: 600 16px Inter;
      }
    }
  }
`;

export const SubmitButton = styled.button<HTMLProps<HTMLButtonElement>>`
  width: 100%;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 52px;

  font: 700 16px Inter;
  color: white;
  background: #ff9977;
  padding: 16px;
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0px 20px 20px 0px #00000033;
  transition: 0.5s ease-in;
  cursor: pointer;

  &:hover {
    background: #de8b6f;
  }
`;
