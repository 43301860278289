import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  Collapse,
  List,
  Fade,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import {
  AddOutlined,
  CloseOutlined,
  DeleteOutlined,
  MicOutlined,
  VideocamOutlined
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { PaginationContainer } from 'http/users/pages/main/styles';
import useSort from 'shared/hooks/useSort';

import Meditation, {
  CreateMeditation
} from 'http/meditations/api/ts/interface/Meditation';
import useMeditation from 'http/meditations/hooks/useMeditations';
import MeditationForm from 'http/meditations/components/MeditationForm';
import Button from 'shared/components/Button';
import TableHead from 'shared/components/TableHead';
import IconButton from 'shared/components/IconButton';
import Table from '../../../../shared/components/Table';
import Section from '../../../../shared/components/Section';
import UploadContentModal from '../../components/UploadContentModal';

import { MeditationContentContainer, MeditationContentSection } from './styles';

function MeditationContent() {
  const {
    deleteMeditation,
    editMeditation,
    fetchMeditations,
    currentPage,
    setCurrentPage,
    totalPages,
    contentType,
    setContentType
  } = useMeditation();
  const [meditations, setMeditations] = useState<Meditation[]>([]);

  const [expanded, setExpanded] = useState<string | null>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

  const [newContentType, setNewContentType] = useState<string | null>(null);

  const popoverOpen = Boolean(anchorEl);

  const handleGetMeditations = async () => {
    setMeditations((await fetchMeditations(1)) as Meditation[]);
  };

  const handleChangePagination = async (
    e: ChangeEvent<unknown>,
    value: number
  ) => {
    setMeditations(
      (await fetchMeditations(value, contentType)) as Meditation[]
    );
    setCurrentPage(value);
  };

  const handleOpenEditForm = (panel: string) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const handleSearchMeditations = async (format: string) => {
    setMeditations(
      (await fetchMeditations(currentPage, format)) as Meditation[]
    );
  };

  const handleEditMeditations = async (
    id: string,
    values: CreateMeditation
  ) => {
    await editMeditation(id, values);
    setMeditations(
      (await fetchMeditations(currentPage, contentType)) as Meditation[]
    );
  };

  const handleDelete = async (id: string) => {
    await deleteMeditation(id);
    setMeditations(
      (await fetchMeditations(currentPage, contentType)) as Meditation[]
    );
  };

  useEffect(() => {
    handleGetMeditations();
  }, []);

  const contentTypeOptions = [
    <Button
      size="sm"
      onClick={() => {
        setCurrentPage(1);
        setContentType('AUDIO');
        handleSearchMeditations('AUDIO');
      }}
    >
      Áudio
    </Button>,
    <Button
      size="sm"
      onClick={() => {
        setCurrentPage(1);
        setContentType('VIDEO');
        handleSearchMeditations('VIDEO');
      }}
    >
      Vídeo
    </Button>,
    <>
      <Button
        variant="outline"
        size="sm"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Adicionar <AddOutlined />
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
      >
        <List>
          <ListItem
            button
            onClick={() => {
              setNewContentType('AUDIO');
            }}
          >
            <ListItemIcon>
              <MicOutlined />
            </ListItemIcon>
            <ListItemText primary="Áudio" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNewContentType('VIDEO');
            }}
          >
            <ListItemIcon>
              <VideocamOutlined />
            </ListItemIcon>
            <ListItemText primary="Vídeo" />
          </ListItem>
        </List>
      </Popover>
    </>
  ];

  const headCells = [
    { name: 'Nome', align: 'left', id: 'title', sorting: true },
    { name: 'Descrição', align: 'left', id: 'description', sorting: true },
    {
      name: 'Categoria',
      align: 'center',
      id: 'category',
      sorting: false
    },
    {
      name: 'Sub-categoria',
      align: 'center',
      id: 'subCategory',
      sorting: false
    },
    {
      name: 'Tipo',
      align: 'center',
      id: 'format',
      sorting: false
    },
    {
      name: 'Data de inclusão',
      align: 'center',
      id: 'created_at',
      sorting: true
    },
    { name: 'Ações', align: 'center', id: 'actions', sorting: false }
  ];

  const { sortedItems, requestSort, sortConfig } = useSort<Meditation>(
    meditations as Meditation[],
    {
      key: 'name' as keyof Meditation,
      direction: 'desc'
    }
  );

  return (
    <Fade in>
      <MeditationContentContainer>
        <Section
          title={`Conteúdo - ${contentType || 'Todos'}`}
          options={contentTypeOptions}
        >
          <MeditationContentSection>
            <Table>
              <TableHead
                headCells={headCells}
                sortConfig={sortConfig}
                sort={(id) => requestSort(id as keyof Meditation)}
              />
              <TableBody>
                {meditations ? (
                  sortedItems.map((item) => {
                    return (
                      <>
                        <TableRow key={item.id}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.title}
                          </TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell align="center">{item.category}</TableCell>
                          <TableCell align="center">
                            {item.subCategory}
                          </TableCell>
                          <TableCell align="center">
                            {item.format === 'VIDEO' && 'Vídeo'}
                            {item.format === 'AUDIO' && 'Áudio'}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.created_at, 'YYYY-MM-DD').format(
                              'DD/MM/YYYY'
                            )}
                          </TableCell>
                          <TableCell className="actions">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                              }}
                            >
                              <Button
                                text="Editar"
                                size="sm"
                                onClick={() => handleOpenEditForm(item.id)}
                              />
                              <IconButton
                                className="iconButton"
                                onClick={() => handleDelete(item.id)}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse in={expanded === item.id} unmountOnExit>
                              <div className="editHeader">
                                <h2>Editar</h2>
                                <CloseOutlined
                                  onClick={() => handleOpenEditForm(item.id)}
                                />
                              </div>
                              <MeditationForm
                                format={item.format as 'VIDEO' | 'AUDIO'}
                                callback={(values) =>
                                  handleEditMeditations(item.id, values)
                                }
                                initialValues={item}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {meditations?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <h1>Nenhuma meditação encontrada</h1>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <PaginationContainer>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePagination}
                color="primary"
              />
            </PaginationContainer>
          </MeditationContentSection>
        </Section>
        <UploadContentModal
          open={!!newContentType}
          toggleOpen={() => setNewContentType(null)}
          newContentType={newContentType}
        />
      </MeditationContentContainer>
    </Fade>
  );
}

export default MeditationContent;
