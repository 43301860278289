import { Drawer } from '@material-ui/core';
import styled from 'styled-components';

import colors from '../../../../shared/constants/Colors';

export const SidebarContainer = styled.div`
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.primary};
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  font: 400 16px Inter;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.primary};
  }

  ::-webkit-scrollbar-thumb {
    background: #6b66c4;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #5551a8;
  }

  @media (max-width: 699px) {
    display: none;
  }
`;

export const SidebarHeader = styled.header`
  width: 100%;
  height: 60px;
  display: grid;
  place-items: center;
`;

export const LinkList = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding: 30px 40px;
`;

export const DrawerContent = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.primary};
  color: white;
  font: 400 16px Inter;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.primary};
  }

  ::-webkit-scrollbar-thumb {
    background: #6b66c4;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #5551a8;
  }
`;

export const SidebarDrawer = styled(Drawer)`
  width: 100%;

  display: none;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 699px) {
    display: -webkit-flex;
  }
`;
