import api from '../../../services/api';
import { User } from '../ts/user.interface';

export const getAll = async (
  page: number | undefined,
  filters: any
): Promise<{ currentPage: number; data: User[]; totalPages: number }> =>
  await api.post('/user/all', filters, {
    params: {
      page
    }
  });

export const getAllUsers = async (): Promise<{
  users: User[];
  total: number;
}> => await api.get('/user/all/export-xlsx');

export const getOne = async (id: string): Promise<User> =>
  await api.get(`/user/${id}`);

export const postNewPass = async (
  id: string,
  newPass: string,
  email: string
): Promise<User> => await api.post(`/user/new-pass`, { id, newPass, email });

export const getPaymentHistory = async (id: string): Promise<User> =>
  await api.get(`/user/payments/${id}`);
