import React from 'react';
import styled from 'styled-components';

interface OverviewItemProps {
  type: 'positive' | 'negative' | 'positive-user';
  positiveData?: {
    totalUsers?: number;
    monthlySubs?: number;
    anualSubs?: number;
    userNoScriber?: number;
    userScriber?: number;
  };
  negativeData?: {
    totalCancels: number;
    initialSevenCancels: number;
    initialThirtyCancels: number;
  };
}

function OverviewItem({
  type = 'positive',
  positiveData,
  negativeData
}: OverviewItemProps) {
  if (type === 'positive')
    return (
      <OverviewItemContainer type={type}>
        <p style={{ width: '100%' }}>
          Novas assinaturas MENSAIS: <span>{positiveData?.monthlySubs}</span>
        </p>
        <p style={{ width: '100%' }}>
          Novas assinaturas ANUAIS: <span>{positiveData?.anualSubs}</span>
        </p>
        <p style={{ width: '100%' }} />
      </OverviewItemContainer>
    );

  if (type === 'positive-user')
    return (
      <OverviewItemContainer type={type}>
        <p style={{ width: '100%' }}>
          Total de Usuários: <span>{positiveData?.totalUsers}</span>
        </p>
        <p style={{ width: '100%' }}>
          Usuários pagantes: <span>{positiveData?.userScriber}</span>
        </p>
        <p style={{ width: '100%' }}>
          Usuários não pagantes: <span>{positiveData?.userNoScriber}</span>
        </p>
      </OverviewItemContainer>
    );

  if (type === 'negative')
    return (
      <OverviewItemContainer type={type}>
        <p style={{ width: '100%' }}>
          Total de Cancelamentos: <span>{negativeData?.totalCancels}</span>
        </p>
        <p style={{ width: '100%' }} />
        <p style={{ width: '100%' }} />
      </OverviewItemContainer>
    );

  return <></>;
}

export default OverviewItem;

const OverviewItemContainer = styled.div<OverviewItemProps>`
  width: 100%;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: 15px 10px;
  border-radius: 10px;
  border: 1px solid whitesmoke;

  p {
    font: 500 13px Inter;
  }

  span {
    font: 600 12px Inter;
    color: ${(p) => (p.type === 'negative' ? 'red' : 'green')};
  }

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
  }
`;
