import { useHistory } from 'react-router';
import useCourse from 'http/courses/hooks/useCourse';
import CourseForm from 'http/courses/components/CourseForm';
import { CourseData } from 'http/courses/ts/course.interface';
import Section from 'shared/components/Section';

import { NewCourseContainer, NewCourseSection } from './styles';

function NewCourse() {
  const { createCourse } = useCourse();
  const history = useHistory();
  const handleCreateNewCourse = async (values: CourseData) => {
    const data = await createCourse(values);

    history.push(`/courses/${data?.id}`);
  };
  return (
    <NewCourseContainer>
      <Section title="Novo curso">
        <NewCourseSection>
          <CourseForm callback={handleCreateNewCourse} />
        </NewCourseSection>
      </Section>
    </NewCourseContainer>
  );
}

export default NewCourse;
