import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { OTPublisher, OTPublisherRef } from 'opentok-react';
import { List, ListItem, ListItemText, Popover } from '@material-ui/core';
import gotDevices from 'helpers/getLocalVideoDevices';
import {
  CloseOutlined,
  FlipCameraIosOutlined,
  PersonOutlined
} from '@material-ui/icons';

import MobileChat from './MobileChat';

interface Device {
  deviceId: string;
  label: string;
}

const Publisher = ({
  watching,
  isLive,
  streamId,
  closeLive,
  isRecording,
  blockComments
}: {
  streamId: string;
  watching: number;
  isLive: boolean;
  isRecording: boolean;
  blockComments: boolean;
  closeLive: () => void;
}) => {
  const prev = useRef<OTPublisherRef>(null);
  const publisher = prev.current?.getPublisher();

  const [availableVideoSources, setAvailableVideoSources] = useState<Device[]>(
    []
  );

  const [accessAllowed, setAccessAllowed] = useState(false);
  const [state, setState] = useState({
    error: '',
    audio: true,
    video: true,
    videoSource: ''
  });

  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const popoverOpen = Boolean(anchorEl);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(gotDevices)
      .then((availableVideoInputs) => {
        setAvailableVideoSources(availableVideoInputs);
      })
      .catch((err) => toast.error(err));
  }, [accessAllowed]);

  return (
    <div className="publisher">
      <OTPublisher
        ref={prev}
        eventHandlers={{
          accessAllowed: () => {
            setAccessAllowed(true);
          }
        }}
        properties={{
          width: window.screen.width < 699 ? '100vw' : '50vw',
          height: window.screen.width < 699 ? '100vh' : '60vh',
          mirror: false,
          resolution: '1280x720',
          audioBitrate: 105000,
          insertMode: 'replace',
          enableStereo: false,
          maxResolution: { height: 720, width: 1280 },
          frameRate: 29,
          facingMode: 'environment',
          fitMode: 'contain',
          showControls: false,
          publishAudio: state.audio,
          publishVideo: state.video,
          videoSource: state.videoSource ? state.videoSource : undefined
        }}
      />

      <div className="cover-options">
        <div className="base-indicators">
          {isLive && <div className="is-live">Ao vivo</div>}
          <div className="icon">
            <PersonOutlined /> {watching}
          </div>
        </div>

        <div className="options">
          <CloseOutlined
            onClick={closeLive}
            fontSize="large"
            className="mobile"
          />

          <FlipCameraIosOutlined
            fontSize="large"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          />

          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            disableRestoreFocus
          >
            <List>
              {availableVideoSources?.map((source) => (
                <ListItem
                  button
                  key={source.deviceId}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      videoSource: source.deviceId
                    }))
                  }
                >
                  <ListItemText primary={source.label} />
                </ListItem>
              ))}
            </List>
          </Popover>
        </div>

        {!blockComments && <MobileChat streamId={streamId} />}
      </div>
    </div>
  );
};
export default Publisher;
