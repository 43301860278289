import styled from 'styled-components';

export const NotificationPageContainer = styled.div``;

export const NotificationManagementSection = styled.div`
  border-radius: 15px;
  background: white;

  table {
    th {
      cursor: pointer;
    }
    td {
      border-left: 2px solid whitesmoke;
    }
  }
`;
