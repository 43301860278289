import React from 'react';
import { Formik, Form } from 'formik';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { DeleteOutlined, TuneOutlined } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import Button from '../../../shared/components/Button';
import Filter from '../ts/filter.interface';

interface FilterProps {
  handleFilter: (values: Filter) => void;
  cleanFilters: () => void;
}

function FilterForm({ handleFilter, cleanFilters }: FilterProps) {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values: Filter) =>
        handleFilter({
          ...values
        })
      }
      onReset={() => cleanFilters()}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="recipient-filter-label">
                  Destinatários
                </InputLabel>
                <Select
                  labelId="recipient-filter-label"
                  id="recipient-filter"
                  name="receiversType"
                  label="Quem irá receber?"
                  defaultValue=""
                  value={values.receiversType}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value="EVERYONE">Publico geral</MenuItem>
                  <MenuItem value="FREE_USERS">Usuários não pagantes</MenuItem>
                  <MenuItem value="SUBSCRIBER_USERS">
                    Usuários com assinatura
                  </MenuItem>
                  <MenuItem value="HAVE_COURSE_PURCHASED_USERS">
                    Usuários que possuem cursos comprados
                  </MenuItem>
                  <MenuItem value="YEARLY_SUBSCRIBER_USERS">
                    Usuários que possuem um assinatura anual
                  </MenuItem>
                  <MenuItem value="MONTHLY_SUBSCRIBER_USERS">
                    Usuários que possuem um assinatura mensal
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="course-filter-label">Programação</InputLabel>
                <Select
                  labelId="course-filter-label"
                  id="course-filter"
                  name="repeatType"
                  label="Programação"
                  defaultValue=""
                  value={values.repeatType}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>selecione</em>
                  </MenuItem>
                  <MenuItem value="NOW">Agora</MenuItem>
                  <MenuItem value="NOT_REPEAT">Programado</MenuItem>
                  <MenuItem value="EVERY_DAY">Diariamente</MenuItem>
                  <MenuItem value="PER_ONE_WEEK">Semanalmente</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl variant="outlined" fullWidth>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    name="date"
                    value={values.date}
                    onChange={(date) => setFieldValue('date', date)}
                    id="date-picker"
                    label="Data"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  justifyContent: 'flex-start'
                }}
              >
                <Button className="iconButton" size="md" type="submit">
                  <TuneOutlined />
                </Button>
                <Button variant="outline" className="iconButton" type="reset">
                  <DeleteOutlined />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FilterForm;
