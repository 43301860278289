import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';

import Section from 'shared/components/Section';
import Button from 'shared/components/Button';
import IconButton from 'shared/components/IconButton';

import { TuneOutlined } from '@material-ui/icons';
import { Box, Drawer, Fade, FormControl, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useLiveHook from 'http/lives/hooks/useLiveHook';
import LiveItem from 'http/lives/components/LiveItem';
import LiveListData from '../../api/Response/LiveListData';
import { LivesGrid, LivesListContainer, FilterContainer } from './styles';

function LivesList() {
  const [allLives, setAllLives] = useState<LiveListData[] | undefined>();
  const [filteredLives, setFilteredLives] = useState<
    LiveListData[] | undefined
  >();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState<MaterialUiPickersDate>(null);
  const [nameFilter, setNameFilter] = useState<string>('');

  const { getAllLives } = useLiveHook();

  const getLives = async () => {
    const data = await getAllLives();
    setAllLives(data);
    setFilteredLives(data);
  };

  const filterLives = () => {
    const newLivesList = allLives?.filter((live) => {
      if (nameFilter && dateFilter) {
        return (
          live.title.includes(nameFilter) &&
          dateFilter
            ?.toLocaleDateString()
            .includes(new Date().toLocaleDateString())
        );
      }

      return (
        (nameFilter && live.title.includes(nameFilter)) ||
        (dateFilter &&
          dateFilter?.toLocaleDateString() ===
            new Date(live.created_at).toLocaleDateString())
      );
    });

    setFilteredLives(newLivesList);
  };

  const cleanFilters = () => {
    setFilteredLives(allLives);
    setNameFilter('');
    setDateFilter(null);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    getLives();
  }, []);

  const sectionOptions = [
    <Link to="/live-setup">
      <Button text="Nova live" size="sm" />
    </Link>,
    <Link to="/upload-video">
      <Button text="Novo vídeo" size="sm" />
    </Link>,
    <IconButton onClick={() => setIsFilterOpen(true)}>
      <TuneOutlined />
    </IconButton>
  ];
  return (
    <Fade in>
      <LivesListContainer>
        <Section title="Lives" options={sectionOptions}>
          <LivesGrid>
            {!filteredLives
              ? Array.from(new Array(4)).map(() => {
                  return (
                    <Box width={150}>
                      <Skeleton variant="rect" width={150} height={250} />
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  );
                })
              : filteredLives?.map((live) => {
                  return (
                    <LiveItem
                      liveData={live}
                      key={live.id}
                      refreshCallback={getLives}
                    />
                  );
                })}
          </LivesGrid>
          {filteredLives?.length === 0 && (
            <div className="not-found-message">
              <h3>Nenhuma live encontrada</h3>
              <span>
                Tente procurar utilizando outros filtros ou inicie uma live
                agora mesmo
              </span>
            </div>
          )}
        </Section>

        <Drawer
          open={isFilterOpen}
          anchor="right"
          onClose={() => setIsFilterOpen(false)}
        >
          <FilterContainer>
            <div className="title">
              <h1>Filtros</h1>
              <span onClick={() => setIsFilterOpen(false)}>X</span>
            </div>
            <p>
              Procure pelo nome e/ou data da live. Você pode limpar os filtros
              clicando{' '}
              <a
                href="#"
                onClick={cleanFilters}
                style={{ color: 'rebeccapurple' }}
              >
                aqui
              </a>
            </p>

            <FormControl fullWidth>
              <TextField
                label="Nome"
                placeholder="Ex: Meditação #56 - Aulas..."
                variant="outlined"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </FormControl>

            <br />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl variant="outlined" fullWidth>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={dateFilter}
                  onChange={(date) => setDateFilter(date)}
                  id="date-picker"
                  label="Data"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>

            <br />

            <Button text="Filtrar" size="full" onClick={filterLives} />
          </FilterContainer>
        </Drawer>
      </LivesListContainer>
    </Fade>
  );
}

export default LivesList;
