import { useEffect, useState } from 'react';

import {
  CircularProgress,
  Collapse,
  Fade,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { AddOutlined, CloseOutlined, DeleteOutlined } from '@material-ui/icons';

import useSort from 'shared/hooks/useSort';
import { CreateSong, Song } from 'http/playlist/ts/song.interface';
import Playlist from 'http/playlist/ts/playlist.interface';
import usePlaylist from '../../hooks/usePlaylist';

import Button from '../../../../shared/components/Button';
import TableHead from '../../../../shared/components/TableHead';
import IconButton from '../../../../shared/components/IconButton';
import Table from '../../../../shared/components/Table';
import Section from '../../../../shared/components/Section';

import PlaylistContentForm from '../../components/PlaylistContentForm';
import UploadContentModal from '../../components/UploadContentModal';

import { PlaylistContentContainer, PlaylistContentSection } from './styles';

function PlaylistContent() {
  const { getPlaylist, editSong, deleteSong, addSong } = usePlaylist();
  const [playlist, setPlaylist] = useState<Playlist | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number | null>();

  const getPlaylistContent = async () => {
    setPlaylist(await getPlaylist());
  };

  const handleOpenEditForm = (panel: number) => {
    setExpanded((state) => (state === panel ? null : panel));
  };

  const handleEditSong = async (songId: string, values: CreateSong) => {
    if (playlist) {
      await editSong(songId, values);
      getPlaylistContent();
    }
  };

  const handleDeleteSong = async (songId: string) => {
    if (playlist) {
      await deleteSong(playlist?.id, songId);
      getPlaylistContent();
    }
  };

  const handleCreateSong = async (values: CreateSong) => {
    if (playlist) {
      await addSong(playlist?.id, values);
      setIsModalOpen(false);
      getPlaylistContent();
    }
  };

  useEffect(() => {
    getPlaylistContent();
  }, []);

  const { sortConfig, sortedItems, requestSort } = useSort(
    playlist?.songs as Song[],
    {
      key: 'title' as keyof Song,
      direction: 'desc'
    }
  );

  const headCells = [
    { name: 'Música/Áudio', align: 'left', id: 'title', sorting: true },
    { name: 'Capa', align: 'left', id: 'background_url', sorting: false },
    {
      name: 'Data de inclusão',
      align: 'left',
      id: 'created_at',
      sorting: true
    },
    {
      name: 'Ações',
      align: 'center',
      id: 'actions',
      sorting: false
    }
  ];

  return (
    <Fade in>
      <PlaylistContentContainer>
        <Section
          title="Playlist do Tadashi"
          options={[
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsModalOpen((state) => !state)}
            >
              Adicionar <AddOutlined />
            </Button>
          ]}
        >
          <PlaylistContentSection>
            <Table>
              <TableHead
                headCells={headCells}
                sortConfig={sortConfig}
                sort={(id) => requestSort(id as keyof Song)}
              />
              <TableBody>
                {playlist ? (
                  sortedItems?.map((item, idx) => {
                    return (
                      <>
                        <TableRow key={item.id}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {item.title}
                          </TableCell>
                          <TableCell>
                            {item.background_url ? (
                              <img alt={item.title} src={item.background_url} />
                            ) : (
                              <p>Sem imagem</p>
                            )}
                          </TableCell>
                          <TableCell>
                            {new Date(item.created_at).toLocaleDateString(
                              'pt-BR'
                            )}
                          </TableCell>
                          <TableCell className="actions" align="center">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                text="Editar"
                                size="sm"
                                onClick={() => handleOpenEditForm(idx)}
                              />
                              <IconButton
                                className="iconButton"
                                onClick={() => handleDeleteSong(item.id)}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse in={expanded === idx} unmountOnExit>
                              <div className="editHeader">
                                <h2>Editar</h2>
                                <CloseOutlined
                                  onClick={() => handleOpenEditForm(idx)}
                                />
                              </div>
                              <PlaylistContentForm
                                callback={(values) =>
                                  handleEditSong(item.id, values)
                                }
                                initialValues={item}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <UploadContentModal
              open={isModalOpen}
              toggleOpen={() => setIsModalOpen((state) => !state)}
              handleCreateSong={handleCreateSong}
            />
          </PlaylistContentSection>
        </Section>
      </PlaylistContentContainer>
    </Fade>
  );
}

export default PlaylistContent;
