import api from '../../../services/api';
import { Content, Course } from '../ts/course.interface';

export const getAll = async (): Promise<Course[]> =>
  await api.get('/course/all?').then((res) => res.data);

export const getCourse = async (courseId: string): Promise<Course> =>
  await api.get(`/course/${courseId}`);

export const getContent = async (
  type: string,
  courseId: string
): Promise<Content[]> =>
  await api.get('/course/content', {
    params: {
      format: type,
      course_id: courseId
    }
  });

export const update = async (
  id: string,
  courseData: FormData
): Promise<Course> => await api.put(`/course/${id}`, courseData);

export const uploadContent = async (contentData: FormData): Promise<Content> =>
  await api.post('/course/content', contentData);

export const updateContent = async (
  contentId: string,
  contentData: FormData
): Promise<string> =>
  await api.put(`/course/content/${contentId}`, contentData);

export const deleteContent = async (id: string): Promise<string> =>
  await api.delete(`course/content/${id}`);

export const create = async (courseData: FormData): Promise<Course> =>
  await api.post('/course', courseData);

export const remove = async (courseId: string): Promise<string> =>
  await api.delete(`/course/${courseId}`);
