import { useParams } from 'react-router';

const useWatchLiveHook = () => {
  const params = useParams();
  const { sessionId, token, id } = params as {
    sessionId: string;
    token: string;
    id: string;
  };
  return {
    sessionId,
    token,
    id
  };
};
export default useWatchLiveHook;
